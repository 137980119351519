.footer {
    // New Agon
    .icon-socials {
        display: inline-block;
        margin-right: 15px;
        height: 32px;
        width: 32px;
        transition-duration: 0.2s;
        &:hover {
            transform: translateY(-3px);
            transition-duration: 0.2s;
        }
        &.icon-facebook {
            background: url(../imgs/template/icons/facebook-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/facebook.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
        &.icon-twitter {
            background: url(../imgs/template/icons/twitter-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/twitter.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
        &.icon-instagram {
            background: url(../imgs/template/icons/instagram-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/instagram.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
        &.icon-linkedin {
            background: url(../imgs/template/icons/linkedin-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/linkedin.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
    }
    .width-20
    {
        width: 20%;
    }
    .width-25
    {
        width: 25%;
    }
    .width-23
    {
        width: 23%;
    }
    .width-16
    {
        width: 16%;
    }
    
    .menu-footer {
        display: inline-block;
        li {
            list-style: none;
            margin-bottom: 8px;
            a {
                color: $color-gray-900;
                font-size: $font-md;
                line-height: 24px;
                text-decoration: none;
                display: block;
                background: url(../imgs/template/icons/arrow-gray.svg) no-repeat 0px 6px;
                padding: 0px 0px 0px 15px;
                transition-duration: 0.2s;
                &:hover {
                    color: $color-brand-1;
                    transition-duration: 0.2s;
                    padding-left: 18px;
                }
            }
        }
    }
    .footer-bottom {
        border-top: 1px solid $color-gray-200;
        padding: 30px 0px;
        color: $color-gray-500;
        font-size: $font-sm;
        a {
            text-decoration: none;
        }
    }
    // End new Agon
}
#scrollUp {
	width: 42px;
    height: 42px;
    color: $color-white;
    right: 30px;
    bottom: 30px;
    border-radius: 30px;
    text-align: center;
    overflow: hidden;
    z-index: 999 !important;
    border: 0;
    background-color: #fff;
    transition-duration: 0.2s;
    background-color: $color-gray-900;
    i {
        display: block;
        line-height: 46px !important;
        font-size: 20px;
        color: #fff;
    }
    &:hover {
        transform: translateY(-3px);
        transition-duration: 0.2s;
    }
}
.menu-bottom
{
    li
    {
        display: inline-block;
        a
        {
            display: block;
            padding:0px 15px;
        }
        &:last-child
        {
            a
            {
                padding-right: 0px;
            }
        }
    }
}
.footer-1
{
    background-color: $color-gray-50;
    margin: 0px 0px 50px 0px;
    padding: 105px 0px 75px 0px;
}
.footer-2-top
{
    a
    {
        vertical-align: top;
        display: inline-block;
        padding-right: 10px;
    }
    .font-xs
    {
        padding: 32px 12px 0px 12px;
    }
}
.footer-2-bottom
{
    display: flex;
    margin-bottom: 20px;
    .head-left-footer
    {
        min-width: 95px;
        vertical-align: top;
    }
    .tags-footer
    {
        width: 100%;
        padding-top: 2px;
        a
        {
            color: $color-gray-900;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            display: inline-block;
            vertical-align: top;
            padding: 0px 30px 0px 0px;
            position: relative;
            &::before
            {
                content: "";
                position: absolute;
                top: 1px;
                right: 15px;
                height: 16px;
                width: 1px;
                background-color: $color-gray-500;
            }
            &:last-child
            {
                padding-right: 0px;
                &::before
                {
                    display: none;
                }
            }
        }
    }
}