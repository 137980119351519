/***
 Theme Name: Ecom
 Description: Multipurpose Marketplace HTML Template + RTL
 Version: 3.0
 License: GNU General Public License v2 or later
 License URI: http://www.gnu.org/licenses/gpl-2.0.html
==============================
TABLE CONTENT
 Import Google Fonts
 Include Third Party CSS Library
 GENERAL
 HEADER
 Offcanvas Sidebar
 Search form
 Main header
 Mega menu
 Header layouts
 Page layouts
 Pagination
 Breadcrumb
 CATEGORY PAGES
 SINGLE POST
 Author box
 comment
 Post share social
 WP STYPE
 Custom amine
***/
@import url(https://fonts.bunny.net/css?family=dm-sans:400,500,700|kanit:700);
/*import vendors*/
@import url(../css/vendors/normalize.css);
@import url(../css/vendors/bootstrap.min.css);
@import url(../css/vendors/uicons-regular-rounded.css);
@import url(../css/plugins/swiper-bundle.min.css);
@import url(../css/plugins/select2.min.css);
@import url(../css/plugins/slick.css);
@import url(../css/plugins/animate.min.css);
/*RESET*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

main {
  display: block;
  clear: both;
}

thead {
  font-weight: 600;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

input,
select,
button,
textarea {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

li.hr span {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
  margin: 20px 0;
  display: block;
}

/*--- Common classNamees---------------------*/
::selection {
  background: #425A8B; /* WebKit/Blink Browsers */
  color: #fff;
}

::-moz-selection {
  background: #425A8B; /* Gecko Browsers */
  color: #fff;
}

::placeholder {
  color: #B2C2E1;
  opacity: 1;
}

:-ms-input-placeholder,
::-webkit-input-placeholder {
  color: #B2C2E1;
  opacity: 1;
}

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

.height-100vh {
  height: 100vh !important;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  box-shadow: none;
}

.border-radius {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-20 {
  border-radius: 20px;
}

.hover-up {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.hover-up:hover {
  transform: translateY(-2px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

a {
  text-decoration: none;
  color: #0BA9ED;
}

a:hover {
  color: #0BA9ED;
}

*:hover {
  transition-duration: 0.2s;
}

.img-responsive {
  max-width: 100%;
}

body {
  font-family: "DM Sans", sans-serif;
}

.font-xs-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: bold;
}

.font-sm-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: bold !important;
}

.font-md-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: bold !important;
}

.font-lg-bold {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
}

.font-xs {
  font-family: "DM Sans", sans-serif;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400;
}

.font-sm {
  font-family: "DM Sans", sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400;
}

.font-sm-lh32 {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}

.font-md {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.font-lg {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.font-xl {
  font-family: "DM Sans", sans-serif;
  font-size: 28px;
  line-height: 32px;
}

.font-xxl {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
}

.font-2xl {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

.font-3xl {
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
}

.font-base {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.font-base-2 {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.font-desc {
  font-family: "DM Sans", sans-serif;
  font-size: 19px;
  line-height: 27px;
  font-weight: 400;
}

.bg-1 {
  background-color: #EAE4E9;
}

.bg-2 {
  background-color: #FFF3EA;
}

.bg-3 {
  background-color: #FFF2F3;
}

.bg-4 {
  background-color: #FFF1F6 !important;
}

.bg-5 {
  background-color: #DBECE5;
}

.bg-6 {
  background-color: #E8FCFF !important;
}

.bg-7 {
  background-color: #F0EFEB;
}

.bg-8 {
  background-color: #DFE7FD;
}

.bg-9 {
  background-color: #D1ECFD !important;
}

.bg-10 {
  background-color: #DDD3FA !important;
}

.bg-11 {
  background-color: #D4F7FF;
}

.bg-11-2 {
  background-color: #DCF4E0;
}

.bg-11-3 {
  background-color: #F7E5D5;
}

.bg-12 {
  background-color: #D9D9D9;
}

.bg-13 {
  background-color: #FFF4F6;
}

.bg-14 {
  background-color: #E0F2EE;
}

.bg-15 {
  background-color: #5C74A6;
}

.bg-16 {
  background-color: #C8DCE8;
}

.bg-17 {
  background-color: #C4E3D6;
}

.bg-18 {
  background-color: #A4EEE1;
}

.bg-19 {
  background-color: #CBE9FF;
}

.bg-20 {
  background-color: #FFD5C8;
}

.bg-21 {
  background-color: #CAEFDF;
}

.bg-22 {
  background-color: #B8F2FF;
}

.bg-26 {
  background-color: #FFF5DB;
}

.bg-27 {
  background-color: #F5ECDD !important;
}

.bg-28 {
  background-color: #BAD0E7 !important;
}

.bg-29 {
  background-color: #FFDEBF !important;
}

.bg-30 {
  background-color: #D6EFF2 !important;
}

.bg-31 {
  background-color: #F8FAFF !important;
}

.bg-32 {
  background-color: #F9F9F9 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

h1 {
  font-family: "DM Sans", sans-serif;
  font-size: 64px;
  line-height: 83px;
  font-weight: bold;
}

h2 {
  font-family: "DM Sans", sans-serif;
  font-size: 45px;
  line-height: 58px;
  font-weight: bold;
}

h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  line-height: 41px;
  font-weight: bold;
}

h4 {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  line-height: 31px;
  font-weight: bold;
}

h5 {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
}

h6 {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}

.bgrd-4 {
  border-radius: 4px;
}

.bg-brand-1 {
  background-color: #0BA9ED !important;
}

.bg-brand-2 {
  background-color: #FD9636 !important;
}

.bg-color-success {
  background-color: #5BC694;
}

.bg-color-danger {
  background-color: #F53658;
}

.bg-color-info {
  background-color: #27B3D2;
}

.bg-gray-50 {
  background-color: #F0F3F8;
}

.bd-gray-200 {
  border-color: #CAD6EC !important;
}

.box-count {
  text-align: center;
  margin: 0px 0px 20px 0px;
}
.box-count .deals-countdown {
  border-radius: 34px;
  background-color: #425A8B;
  display: flex;
  padding: 4px 12px;
  width: 84%;
  margin: auto;
  max-width: 230px;
}
.box-count .deals-countdown .countdown-section {
  display: inline-block;
  text-align: center;
  width: 25%;
  line-height: 12px;
  position: relative;
}
.box-count .deals-countdown .countdown-section::before {
  content: ":";
  position: absolute;
  top: 3px;
  right: -1px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}
.box-count .deals-countdown .countdown-section span {
  display: block;
  color: #ffffff;
}
.box-count .deals-countdown .countdown-section .countdown-period {
  text-transform: capitalize;
}
.box-count .deals-countdown .countdown-section:last-child::before {
  display: none;
}
.box-count.box-count-square {
  margin-bottom: 5px;
}
.box-count.box-count-square .deals-countdown {
  background-color: #ffffff;
  width: 100%;
  margin: auto;
  text-align: center;
  display: inline-block;
  max-width: 250px;
}
.box-count.box-count-square .deals-countdown .countdown-section {
  display: inline-block;
  text-align: center;
  line-height: 12px;
  position: relative;
  margin: 0px 7px;
  width: auto;
}
.box-count.box-count-square .deals-countdown .countdown-section::before {
  content: ":";
  position: absolute;
  top: 12px;
  right: -9px;
  font-weight: bold;
  font-size: 16px;
  color: #425A8B;
}
.box-count.box-count-square .deals-countdown .countdown-section .countdown-period {
  color: #8C9EC5;
}
.box-count.box-count-square .deals-countdown .countdown-amount {
  background-color: #FD9636;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px 0px;
  width: 42px;
  display: inline-block;
  margin-bottom: 5px;
}
.box-count.hide-period {
  margin-bottom: 0px;
  position: relative;
  bottom: -5px;
  display: inline-block;
  vertical-align: middle;
}
.box-count.hide-period .deals-countdown {
  padding: 4px 2px;
}
.box-count.hide-period .countdown-section .countdown-period {
  display: none;
}

.box-all-hurry {
  display: inline-block;
  position: relative;
  bottom: -20px;
}

.box-text-hurryup {
  vertical-align: middle;
  line-height: 16px;
  padding: 7px 0px 0px 0px;
}
.box-text-hurryup .font-md-bold {
  line-height: 20px;
}
.box-text-hurryup .font-xs {
  line-height: 14px;
}

.lh-14 {
  line-height: 14px !important;
}

.lh-16 {
  line-height: 16px !important;
}

.divide {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #D5DFE4;
  margin: 10px 0px 20px 0px;
}

.box-progress {
  display: inline-block;
  width: 100%;
  padding: 20px 0px;
}
.box-progress .progress-bar {
  display: inline-block;
  width: 100%;
  background-color: #D5DFE4;
  height: 9px;
  border-radius: 24px;
  overflow: visible;
}
.box-progress .progress-bar .progress-bar-inner {
  background-color: #FD9636;
  border-radius: 24px;
  width: 50%;
  height: 9px;
  position: relative;
}
.box-progress .progress-bar .progress-bar-inner::before {
  content: "";
  height: 15px;
  border-radius: 50%;
  background-color: #FD9636;
  width: 15px;
  position: absolute;
  top: -3px;
  right: -3px;
}
.box-progress.box-progress-small .progress-bar {
  height: 6px;
}
.box-progress.box-progress-small .progress-bar .progress-bar-inner {
  height: 6px;
}
.box-progress.box-progress-small .progress-bar .progress-bar-inner::before {
  height: 11px;
  width: 11px;
  top: -3px;
}

.width-100 {
  width: 100%;
}

.section-box {
  display: inline-block;
  width: 100%;
}

.font-bold {
  font-weight: bold !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-18 {
  font-size: 18px;
  line-height: 23px;
  color: #0BA9ED;
  font-weight: 400;
}

.font-30 {
  font-size: 30px;
  line-height: 39px;
  color: #0E224D;
  font-weight: bold;
}

.font-32 {
  font-size: 32px;
  line-height: 31px;
  color: #0E224D;
  font-weight: bold;
}

.font-33 {
  font-size: 30px;
  line-height: 35px;
  color: #0E224D;
  font-weight: bold;
}

.font-59 {
  font-size: 59px;
  line-height: 64px;
  color: #0E224D;
  font-weight: bold;
}

.font-55 {
  font-size: 55px;
  line-height: 62px;
  color: #0E224D;
  font-weight: bold;
}

.font-16 {
  font-size: 16px !important;
  line-height: 23px !important;
  color: #425A8B !important;
}

.font-68 {
  font-size: 68px;
  line-height: 70px;
}

.font-11 {
  font-size: 11px !important;
  line-height: 18px !important;
}

.font-17 {
  font-size: 17px;
  line-height: 22px;
}

.font-46 {
  font-size: 46px;
  line-height: 60px;
}

.font-23 {
  font-size: 23px;
  line-height: 26px;
}

.h-500 {
  height: 500px !important;
}

.color-brand-1 {
  color: #0BA9ED !important;
}

.color-brand-2 {
  color: #FD9636 !important;
}

.color-brand-3 {
  color: #425A8B !important;
}

.color-blue {
  color: #3AA1FF !important;
}

.color-success {
  color: #5BC694;
}

.color-danger {
  color: #F53658;
}

.color-warning {
  color: #FFB067;
}

.color-gray-500 {
  color: #8C9EC5;
}

.color-gray-700 {
  color: #6B83B6;
}

.color-gray-900 {
  color: #425A8B;
}

.color-gray-1000 {
  color: #0E224D;
}

.color-gray-1100 {
  color: #253D4E;
}

.color-white {
  color: #ffffff !important;
}

.color-green {
  color: #00FF8A !important;
}

.color-border-1 {
  color: #D5DFE4;
}

.background-gray-100 {
  background-color: #DDE4F0 !important;
}

.background-gray-50 {
  background-color: #F0F3F8 !important;
}

.w-auto {
  width: auto !important;
}

.mw-80 {
  max-width: 80%;
}

.border-bottom {
  border-color: #DDE4F0 !important;
}

.pagination {
  margin-bottom: 20px;
}

.opacity-100 {
  opacity: 1 !important;
}

.transform-none {
  transform: none !important;
}

a,
button,
img,
input,
span,
h4 {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@keyframes slideleft {
  10% {
    opacity: 0;
    transform: scale(0);
    right: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 0;
    transform: scale(0);
    right: 100%;
  }
}
[data-loader=spinner] {
  width: 35px;
  height: 35px;
  display: inline-block;
  -webkit-animation: spinner 1.2s infinite ease-in-out;
  -o-animation: spinner 1.2s infinite ease-in-out;
  animation: spinner 1.2s infinite ease-in-out;
  background: url(../imgs/theme/favicon.svg);
  box-shadow: 0 0 10px #fff;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(151, 119, 250, 0.8);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}
@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@keyframes jump {
  0% {
    transform: translate3d(0, 20%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.jump {
  transform-origin: 0;
  animation: jump 0.5s linear alternate infinite;
}

/*TYPOGRAPHY*/
body {
  color: #425A8B;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.font-heading,
.display-1,
.display-2,
.heading-lg,
.heading-sm-1 {
  font-family: "DM Sans", sans-serif;
  color: #425A8B;
  font-weight: 700;
  line-height: 1.2;
}

.page-content {
  line-height: 1.25;
}
.page-content p {
  margin-bottom: 10px;
}
.page-content h5 {
  margin-bottom: 15px;
}

a.text-brand-3 {
  color: #425A8B;
}
a.text-brand-3:hover {
  color: #0BA9ED;
}

.btn {
  border-radius: 4px;
  padding: 10px 22px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}
.btn.btn-arrow {
  font-size: 12px;
  line-height: 32px;
  color: #0BA9ED;
  font-weight: bold;
  padding-left: 0px;
  padding-right: 15px;
  text-transform: uppercase;
  background: url(../imgs/template/icons/arrow.svg) no-repeat right 0px top 20px;
}
.btn.btn-brand-2 {
  background-color: #FD9636;
  color: #ffffff;
  font-weight: bold;
}
.btn.btn-brand-2:hover {
  background-color: #425A8B;
}
.btn.btn-brand-3 {
  background-color: #425A8B;
  color: #ffffff;
  font-weight: bold;
}
.btn.btn-brand-3:hover {
  background-color: #FD9636;
}
.btn.btn-info {
  background-color: #27B3D2;
  color: #ffffff;
  font-weight: bold;
}
.btn.btn-info:hover {
  background-color: #FD9636;
  border-color: #FD9636;
}
.btn.btn-arrow-right {
  background-image: url(../imgs/template/icons/arrow-white.svg);
  background-position: center right 18px;
  background-repeat: no-repeat;
  padding-right: 34px;
}
.btn.btn-link {
  color: #425A8B;
  font-size: 14px;
  line-height: 18px;
}
.btn.btn-link:hover {
  color: #FD9636;
}
.btn.btn-link-brand-2 {
  color: #FD9636;
  font-size: 12px;
  line-height: 32px;
  font-weight: bold;
  padding-left: 0px;
  text-transform: uppercase;
}
.btn.btn-link-brand-2:hover {
  color: #425A8B;
}
.btn.btn-arrow-brand-2 {
  background-image: url(../imgs/template/icons/arrow-brand-2.svg);
  background-position: center right 18px;
  background-repeat: no-repeat;
  padding-right: 34px;
}
.btn.btn-arrow-brand-2:hover {
  background-image: url(../imgs/template/icons/arrow-gray.svg);
  background-position: center right 18px;
  background-repeat: no-repeat;
}
.btn.btn-arrow-2 {
  background-image: url(../imgs/template/icons/arrow-2.svg);
  background-position: top 3px right 10px;
  background-repeat: no-repeat;
  padding: 0px 40px 0px 0px;
  font-size: 17px;
  line-height: 19px;
  color: #FD9636;
  font-weight: bold;
}
.btn.btn-arrow-2:hover {
  color: #425A8B;
  background-image: url(../imgs/template/icons/arrow-2-hover.svg);
}
.btn.btn-arrow-2-reverse {
  color: #425A8B;
  background-image: url(../imgs/template/icons/arrow-2-hover.svg);
}
.btn.btn-arrow-2-reverse:hover {
  background-image: url(../imgs/template/icons/arrow-2.svg);
  color: #FD9636;
}
.btn.btn-arrow-small {
  background-size: 5px;
  background-position: top 6px right 18px;
  line-height: 18px;
  padding-right: 30px;
}
.btn.btn-cart {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #425A8B;
  border: 1px solid #8C9EC5;
  width: 100%;
  text-align: center;
  padding: 7px 22px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.btn.btn-cart:hover {
  border: 1px solid #425A8B;
  background-color: #425A8B;
  color: #ffffff;
}
.btn.btn-buy {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #8C9EC5;
  width: 100%;
  text-align: center;
  padding: 7px 22px;
  background-color: #425A8B;
}
.btn.btn-buy:hover {
  border: 1px solid #425A8B;
  background-color: #ffffff;
  color: #425A8B;
}
.btn.btn-trend {
  border: 1px solid #D5DFE4;
  height: 30px;
  width: 30px;
  background: #ffffff url(../imgs/template/icons/trend.svg) no-repeat center;
  padding: 0px;
  opacity: 0;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity, transform;
}
.btn.btn-trend:hover {
  background: #ffffff url(../imgs/template/icons/trend-hover.svg) no-repeat center;
}
.btn.btn-wishlist {
  border: 1px solid #D5DFE4;
  height: 30px;
  width: 30px;
  background: #ffffff url(../imgs/template/icons/wishlist.svg) no-repeat center;
  padding: 0px;
  opacity: 0;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity, transform;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.btn.btn-wishlist:hover {
  background: #ffffff url(../imgs/template/icons/wishlist-hover.svg) no-repeat center;
}
.btn.btn-compare {
  border: 1px solid #D5DFE4;
  height: 30px;
  width: 30px;
  background: #ffffff url(../imgs/template/icons/compare.svg) no-repeat center;
  padding: 0px;
  opacity: 0;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity, transform;
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}
.btn.btn-compare:hover {
  background: #ffffff url(../imgs/template/icons/compare-hover.svg) no-repeat center;
}
.btn.btn-quickview {
  border: 1px solid #D5DFE4;
  height: 30px;
  width: 30px;
  background: #ffffff url(../imgs/template/icons/view.svg) no-repeat center;
  padding: 0px;
  opacity: 0;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity, transform;
  -webkit-transition-delay: 0.18s;
  transition-delay: 0.18s;
}
.btn.btn-quickview:hover {
  background: #ffffff url(../imgs/template/icons/view-hover.svg) no-repeat center;
}
.btn.btn-gray-abs {
  position: absolute;
  bottom: -20px;
  right: -15px;
  background-color: #D5DFE4;
  font-size: 14px;
  line-height: 18px;
  color: #425A8B;
  border-radius: 0px;
  border-top-left-radius: 4px;
  padding: 4px 10px;
}
.btn.btn-gray-abs:hover {
  color: #FD9636;
}
.btn.btn-gray {
  background-color: #D5DFE4;
  font-size: 12px;
  line-height: 18px;
  color: #425A8B;
  border-radius: 0px;
  border-radius: 4px;
  padding: 2px 10px;
}
.btn.btn-gray:hover {
  color: #FD9636;
}
.btn.btn-out-stock {
  background-color: #FFF3EA;
  font-size: 12px;
  line-height: 18px;
  color: #F53658;
  border-radius: 0px;
  border-radius: 4px;
  padding: 2px 10px;
}
.btn.btn-out-stock:hover {
  color: #FD9636;
}
.btn.btn-open {
  height: 112px;
  width: 49px;
  background: #ffffff url(../imgs/template/open.svg) no-repeat center 68px;
  padding: 10px;
  position: fixed;
  top: 0px;
  left: 0px;
}
.btn.btn-view-all {
  background: url(../imgs/template/icons/arrow-line.svg) no-repeat right 0px center;
  padding-right: 26px;
  color: #425A8B;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.btn.btn-view-all:hover {
  color: #FD9636;
}
.btn.btn-link-orange {
  text-decoration: underline;
  color: #FD9636;
}
.btn.btn-category {
  background-color: #FD9636;
  background-image: url(../imgs/page/homepage4/open-close.svg);
  background-repeat: no-repeat;
  background-position: 17px 9px;
  display: inline-block;
  padding: 10px 20px 10px 50px;
  color: #ffffff;
  min-width: 223px;
  text-align: left;
}
.btn.btn-border {
  border-radius: 8px;
  border: 1px solid #B2C2E1;
  padding: 8px 16px;
  background: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #425A8B;
  margin-bottom: 10px;
}
.btn.btn-border:hover {
  background-color: #B2C2E1;
  color: #0E224D;
}
.btn.btn-filter {
  background-image: url(../imgs/page/shop/filter.svg);
  background-repeat: no-repeat;
  background-position: 15px 12px;
  background-color: #EBF0F3;
  padding: 8px 10px 8px 37px;
  border-radius: 4px;
}
.btn.btn-add-wishlist {
  background-image: url(../imgs/template/wishlist.svg);
  background-position: 0px -2px;
  background-repeat: no-repeat;
  padding: 0px 0px 0px 35px;
}
.btn.btn-add-compare {
  background-image: url(../imgs/template/compare.svg);
  background-position: 0px -2px;
  background-repeat: no-repeat;
  padding: 0px 0px 0px 35px;
}
.btn.btn-delete {
  background-image: url(../imgs/page/wishlist/delete.svg);
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px 0px 0px 0px;
  height: 34px;
  width: 34px;
}
.btn.arrow-back {
  background-image: url(../imgs/page/cart/back.svg);
  background-position: left 20px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.btn.arrow-back:hover {
  background-image: url(../imgs/page/cart/back-hover.svg);
}
.btn.arrow-next {
  background-image: url(../imgs/page/checkout/arrow-next.svg);
  background-position: right 20px center;
  background-repeat: no-repeat;
  padding-right: 45px;
}
.btn.arrow-next:hover {
  background-image: url(../imgs/page/cart/arrow-next-hover.svg);
}
.btn.update-cart {
  background-image: url(../imgs/page/cart/reload.svg);
  background-position: left 20px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.btn.update-cart:hover {
  background-image: url(../imgs/page/cart/reload-hover.svg);
}
.btn.btn-amazon {
  padding: 5px 10px;
  border: 1px solid #DDE4F0;
}
.btn.btn-amazon img {
  max-height: 42px;
}
.btn.btn-gpay {
  padding: 5px 10px;
  border: 1px solid #DDE4F0;
}
.btn.btn-gpay img {
  max-height: 42px;
}
.btn.btn-paypal {
  padding: 5px 10px;
  border: 1px solid #DDE4F0;
}
.btn.btn-paypal img {
  max-height: 42px;
}
.btn.btn-tags {
  background-color: #ffffff;
  border: 1px solid #8C9EC5;
  padding: 7px 20px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #425A8B;
  font-weight: bold;
}
.btn.btn-tags:hover {
  color: #ffffff;
  background-color: #425A8B;
}
.btn.btn-login {
  border: 1px solid #DDE4F0;
  border-radius: 4px;
  padding: 14px 22px;
  background-color: #ffffff;
  width: 100%;
}
.btn.btn-login img {
  vertical-align: middle;
  margin-left: 5px;
}
.btn.btn-shop-now {
  background-image: url(../imgs/page/homepage5/shop-now.svg);
  background-position: left 14px center;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.btn.btn-close {
  padding: 0px;
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 12;
  right: 0px;
  top: -7px;
  background: url(../imgs/page/homepage9/close.svg) no-repeat center;
}
.btn.btn-success {
  background-color: #5BC694;
  border-color: #5BC694;
}
.btn.btn-success:hover {
  background-color: #425A8B;
  border-color: #425A8B;
}
.btn.btn-link-brand-3 {
  color: #425A8B;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
}

.dropdown-language {
  display: inline-block;
}
.dropdown-language .dropdown-toggle::after {
  display: none;
}
.dropdown-language .btn {
  padding: 0px;
}

.btn-tooltip {
  position: relative;
}
.btn-tooltip:after {
  bottom: 0;
  right: 34px;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-size: 11px;
  padding: 7px 10px;
  color: #425A8B;
  background-color: #D5DFE4;
  content: attr(aria-label);
  line-height: 1.3;
  box-shadow: 0;
  transition: 0;
  opacity: 0;
  visibility: hidden;
  transform: 0;
  transition-duration: 0.2s;
}
.btn-tooltip:before {
  content: "";
  position: absolute;
  left: -8px;
  bottom: 0;
  transition-delay: 0.1s;
  border: 7px solid transparent;
  border-left-color: transparent;
  border-left-color: #D5DFE4;
  z-index: 9;
  margin-bottom: 0;
  transition: 0;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.2s;
}
.btn-tooltip:hover:after {
  opacity: 1;
  visibility: visible;
  transform: 0;
  transition-duration: 0.2s;
}
.btn-tooltip:hover:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
  transition-duration: 0.2s;
}

.tag-dot {
  border-radius: 4px;
  padding: 5px 5px 5px 20px;
  display: inline-block;
  background: #D5DFE4 url(../imgs/template/icons/circle.svg) no-repeat 10px 11px;
  color: #425A8B;
}

.cb-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 2px solid #CAD6EC;
  border-radius: 4px;
}

.cb-container input:checked ~ .checkmark {
  border: 2px solid #FD9636;
}

.cb-container .text-small {
  color: #8C9EC5;
}

.cb-container input:checked ~ .text-small {
  color: #0E224D;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cb-container input:checked ~ .checkmark:after {
  display: block;
}

.cb-container input:checked ~ .text-lbl {
  color: #425A8B;
}

.cb-container .checkmark:after {
  left: -1px;
  top: -1px;
  width: 16px;
  height: 16px;
  background: #FD9636 url(../imgs/page/shop/tick.svg) no-repeat center;
}

.cb-container-2 {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 4px;
  cursor: pointer;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cb-container-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #425A8B;
  border-radius: 0px;
}

.cb-container-2 input:checked ~ .checkmark {
  border: 1px solid #425A8B;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cb-container-2 input:checked ~ .checkmark:after {
  display: block;
}

.cb-container-2 input:checked ~ .text-lbl {
  color: #425A8B;
}

.cb-container-2 .checkmark:after {
  left: 0px;
  top: 1px;
  width: 16px;
  height: 16px;
  background: url(../imgs/page/product/checked.svg) no-repeat center;
}

/*COMPONENTS -> FORM*/
.form-control {
  border: 1px solid #DDE4F0 !important;
}

.form-control::-webkit-date-and-time-value {
  color: #6B83B6;
  font-size: 14px;
  line-height: 18px;
}

.form-control::-moz-placeholder {
  color: #B2C2E1;
  font-size: 14px;
  line-height: 18px;
}

.form-control::placeholder {
  color: #B2C2E1;
  font-size: 14px;
  line-height: 18px;
}

.form-control:disabled, .form-control[readonly] {
  color: #6B83B6;
  font-size: 14px;
  line-height: 18px;
}

.box-swiper {
  position: relative;
  width: 100%;
}
.box-swiper .swiper-container {
  position: relative;
  height: 100%;
}
.box-swiper .swiper-container .item-logo {
  border: 1px solid #8C9EC5;
  padding: 39px 0px 36px 0px;
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.04);
}
.box-swiper .swiper-container .item-logo img {
  max-width: 100%;
}
.box-swiper .swiper-container .item-logo:hover {
  border: 1px solid #5971A3;
  box-shadow: 0px 47px 65px rgba(21, 28, 38, 0.1);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "";
}

.swiper-button-next {
  background: url(../imgs/slider/swiper/next.svg) no-repeat 0px 0px;
}
.swiper-button-next:hover {
  background: url(../imgs/slider/swiper/next.svg) no-repeat 0px 0px;
}

.swiper-button-prev {
  background: url(../imgs/slider/swiper/prev.svg) no-repeat 0px 0px;
}
.swiper-button-prev:hover {
  background: url(../imgs/slider/swiper/prev.svg) no-repeat 0px 0px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 31px;
  height: 31px;
  margin-top: 0px;
}

.swiper-button-next {
  left: auto;
  right: 0px;
  top: 20px;
}

.swiper-button-prev {
  left: auto;
  right: 40px;
  top: 20px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.swiper-pagination {
  text-align: center;
  width: 100%;
}
.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background: #FD9636;
  opacity: 0.2;
}
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #FD9636;
  width: 12px;
  height: 12px;
}

.swiper-pagination-bullet {
  margin: 10px;
}

/*Style of the location of the div that wraps the custom pager*/
.swiper-pagination-custom {
  bottom: 40px;
  padding-left: 70px;
}

.swiper-pagination-customs {
  background: url(../imgs/slider/swiper/dot.svg) no-repeat 0px 0px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  margin-left: 4px;
  margin-right: 4px;
}

/*Customize the style representation when pager is activated*/
.swiper-pagination-customs-active,
.swiper-pagination-customs:hover {
  background: url(../imgs/slider/swiper/dot-active.svg) no-repeat 0px 0px;
  width: 12px;
  height: 12px;
  margin-bottom: -1px;
  margin-left: 3px;
  margin-right: 3px;
}

.box-button-slider {
  position: absolute;
  top: 10px;
  right: 0px;
}

.swiper-group-1 .swiper-pagination {
  bottom: 50px;
  left: 55px;
}
.swiper-group-1.swiper-home-3 .swiper-pagination {
  left: 0px;
}
.swiper-group-1.home-9 .swiper-pagination {
  bottom: 20px;
  left: 25px;
}

/* This line can be removed it was just for display on CodePen: */
.slider-labels {
  margin-top: 10px;
}

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  /* This className is applied to the lower origin when
     its values is > 50%. */
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base, .noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -6px;
  top: -6px;
  background-color: #345DBB;
}

/* Styling;
 */
.noUi-background {
  background: #D6D7D9;
}

.noUi-connect {
  background: #345DBB;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin {
  border-radius: 2px;
}

.noUi-target {
  border-radius: 2px;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.noUi-handle:active {
  border: 8px solid #345DBB;
  border: 8px solid rgba(53, 93, 187, 0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px;
}

/* Disabled state;
 */
[disabled].noUi-connect, [disabled] .noUi-connect {
  background: #FD9636;
}

[disabled].noUi-origin, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.ui-slider-handle {
  display: none;
}

.ui-widget.ui-widget-content {
  border: 0px solid #EBEBEB;
}

.noUi-target {
  border-radius: 12px;
}

.noUi-horizontal {
  height: 6px;
}

.noUi-background {
  background: #DDE4F0;
}

.noUi-origin {
  border-radius: 12px;
}

.noUi-connect {
  background: #FD9636;
  transition: background 450ms;
  height: 6px;
  margin-top: 0px;
}

.noUi-horizontal .noUi-handle {
  background-color: #FD9636;
  border: 1px solid #FD9636;
  box-shadow: 0px 47px 65px rgba(21, 28, 38, 0.1);
  left: -6px;
  top: -6px;
}

.noUi-handle:active {
  border-width: 1px;
  left: -6px;
  top: -6px;
}

.list-col-5 {
  display: table;
  table-layout: fixed;
  width: calc(100% + 20px);
  margin: 0px -10px;
}
.list-col-5 li {
  float: left;
  width: 20%;
  padding: 0px 10px;
}
.list-col-5 li .item-list {
  border: 1px solid #D5DFE4;
  border-radius: 4px;
  display: flex;
  padding: 15px;
  height: 100%;
}
.list-col-5 li .item-list:hover {
  background-color: #F4F7FC;
  border: 1px solid #8C9EC5;
}
.list-col-5 li .item-list .icon-left {
  padding-right: 10px;
  min-width: 50px;
  max-width: 50px;
  padding-top: 5px;
}
.list-col-5 li .item-list .info-right {
  vertical-align: top;
}

.card-grid-style-1 {
  display: inline-block;
  width: 100%;
}
.card-grid-style-1 .image-box {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}
.card-grid-style-1 .image-box img {
  border-radius: 4px;
}
.card-grid-style-1 .tag-dot {
  border-radius: 4px;
  padding: 5px 5px 5px 20px;
  display: inline-block;
  background: #D5DFE4 url(../imgs/template/icons/circle.svg) no-repeat 10px 11px;
  color: #425A8B;
}
.card-grid-style-1 a.color-gray-1100 {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.card-grid-style-2 {
  border: 1px solid #D5DFE4;
  padding: 15px;
  display: flex;
  margin-bottom: 24px;
  border-radius: 4px;
  width: calc(100% - 1px);
  min-height: 166px;
  transition-duration: 0.2s;
  overflow: hidden;
  position: relative;
}
.card-grid-style-2:hover {
  border: 1px solid #8C9EC5;
  transition-duration: 0.2s;
}
.card-grid-style-2.mh-auto {
  min-height: auto;
}
.card-grid-style-2.card-none-border {
  border: 0px;
  padding: 0px;
}
.card-grid-style-2 .image-box {
  min-width: 135px;
  max-width: 135px;
  position: relative;
  padding-right: 15px;
  text-align: center;
}
.card-grid-style-2 .image-box.mw-84 {
  max-width: 99px;
  min-width: 99px;
}
.card-grid-style-2 .image-box.mw-84 img {
  border-radius: 4px;
}
.card-grid-style-2 .image-box .label {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 2;
  border-radius: 30px;
  padding: 4px 10px;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
}
.card-grid-style-2 .info-right {
  width: 100%;
  position: relative;
}
.card-grid-style-2 .info-right a:hover {
  color: #425A8B !important;
}
.card-grid-style-2 .price-info {
  margin-top: 10px;
}
.card-grid-style-2 .price-info .price-line {
  text-decoration: line-through;
  font-size: 16px;
  line-height: 24px;
}
.card-grid-style-2 .price-info .price-main {
  margin-right: 10px;
}
.card-grid-style-2.card-grid-none-border {
  border: 0px;
  padding: 0px 5px 0px 0px;
  min-height: 0px;
  border-radius: 0px;
}
.card-grid-style-2.card-grid-none-border .image-box .label {
  left: 10px;
  top: 0px;
}
.card-grid-style-2.card-grid-none-border .image-box {
  min-width: 125px;
  max-width: 125px;
  padding-right: 10px;
}
.card-grid-style-2.card-grid-none-border .image-box img {
  max-width: 90%;
}

.card-grid-style-2-small {
  padding-bottom: 20px;
}
.card-grid-style-2-small .image-box {
  max-width: 100px;
  min-width: 100px;
}

.card-grid-style-3 {
  margin-bottom: 30px;
  transition-duration: 0.2s;
}
.card-grid-style-3:hover {
  transform: translateY(-2px);
  transition-duration: 0.2s;
}
.card-grid-style-3:hover .card-grid-inner {
  border-color: #8C9EC5;
  transition-duration: 0.2s;
}
.card-grid-style-3 .card-grid-inner {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #D5DFE4;
  padding: 15px;
  position: relative;
  transition-duration: 0.2s;
}
.card-grid-style-3 .card-grid-inner .tools {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 30px;
  z-index: 12;
  transition-duration: 0.2s;
  transition: all 0.3s ease 0s;
}
.card-grid-style-3 .card-grid-inner .image-box {
  position: relative;
  text-align: center;
  width: 100%;
  display: inline-block;
  height: 165px;
  overflow: hidden;
  line-height: 165px;
  margin-bottom: 10px;
}
.card-grid-style-3 .card-grid-inner .image-box img {
  max-height: 100%;
  max-width: 95%;
  vertical-align: middle;
}
.card-grid-style-3 .card-grid-inner .image-box .label {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  border-radius: 30px;
  padding: 4px 10px;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
}
.card-grid-style-3 .card-grid-inner .info-right a:hover {
  color: #425A8B !important;
}
.card-grid-style-3 .card-grid-inner .info-right a.btn:hover {
  color: #fff !important;
}
.card-grid-style-3 .card-grid-inner .info-right .rating {
  margin-top: 5px;
}
.card-grid-style-3 .card-grid-inner .price-info {
  margin-top: 10px;
}
.card-grid-style-3 .card-grid-inner .price-info .price-line {
  text-decoration: line-through;
  font-size: 16px;
  line-height: 24px;
}
.card-grid-style-3 .card-grid-inner .price-info .price-main {
  margin-right: 10px;
}
.card-grid-style-3 .card-grid-inner .list-features {
  padding-left: 15px;
  margin: 20px 0px 0px 0px;
}
.card-grid-style-3 .card-grid-inner .list-features li {
  font-size: 10px;
  line-height: 18px;
  list-style-type: disc;
  padding: 0px;
}
.card-grid-style-3.card-grid-none-border .card-grid-inner {
  border-color: #ffffff;
}
.card-grid-style-3.card-grid-none-border .card-grid-inner .box-add-cart {
  min-height: 36px;
}
.card-grid-style-3.card-grid-none-border .card-grid-inner .box-add-cart .btn-cart {
  display: none;
}
.card-grid-style-3.card-grid-none-border .card-grid-inner:hover {
  border-color: #8C9EC5;
}
.card-grid-style-3.card-grid-none-border .card-grid-inner:hover .box-add-cart .btn-cart {
  display: block;
}
.card-grid-style-3.hover-hide-show-cart .box-progress-small {
  display: block;
  padding: 19px 0px 0px 0px;
}
.card-grid-style-3.hover-hide-show-cart .box-add-cart {
  display: none;
}
.card-grid-style-3.hover-hide-show-cart:hover .box-progress-small {
  display: none;
}
.card-grid-style-3.hover-hide-show-cart:hover .box-add-cart {
  display: block;
}

.card-grid-style-4 {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #D5DFE4;
  position: relative;
  padding: 10px;
  margin-bottom: 30px;
}
.card-grid-style-4 .card-grid-inner {
  display: flex;
  margin: 0px -5px;
  flex-wrap: wrap;
}
.card-grid-style-4 .card-grid-inner .image-box {
  flex: 0 0 auto;
  width: 50%;
  padding: 0px 5px;
}
.card-grid-style-4 .card-grid-inner .info-right {
  flex: 0 0 auto;
  width: 50%;
  padding: 2px 5px 0px 5px;
}
.card-grid-style-4 .box-link {
  line-height: 10px;
}

.list-products-5 {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;
  width: calc(100% + 20px);
}
.list-products-5 .card-grid-style-3 {
  padding: 10px;
  margin-bottom: 0px;
  flex: 0 0 auto;
  width: 20%;
}
.list-products-5.list-products-3 .card-grid-style-3 {
  width: 21.666%;
}
.list-products-5.list-products-3 .hurry-up {
  width: 35%;
}

.hurry-up {
  height: calc(100% - 30px);
}
.hurry-up .card-grid-inner {
  height: 100%;
  padding-top: 0;
}
.hurry-up .card-grid-inner .image-box {
  height: 370px;
  line-height: 360px;
  margin-bottom: 10px;
}
.hurry-up .card-grid-inner .image-box .box-swiper .swiper-container {
  padding-bottom: 10px;
}
.hurry-up .card-grid-inner .image-box .swiper-pagination-custom {
  bottom: 10px;
  padding-left: 0px;
}
.hurry-up .card-grid-inner .price-info {
  margin-top: 20px;
}
.hurry-up .card-grid-inner .list-features {
  margin-top: 0px;
}
.hurry-up .card-grid-inner .price-line {
  font-size: 20px;
  line-height: 24px;
}
.hurry-up .label {
  background: url(../imgs/template/label.svg) no-repeat 0px 0px;
  border-radius: 0px;
  top: 0px;
  left: 0px;
  height: 120px;
  width: 120px;
  position: absolute;
  z-index: 12;
}
.hurry-up .label span {
  transform: rotate(-45deg);
  display: inline-block;
  position: absolute;
  top: 33px;
  left: -1px;
}

.hurry-up-2 .label {
  background: url(../imgs/page/homepage4/hurry-up-2.svg) no-repeat 0px 0px;
}
.hurry-up-2 .label span {
  top: 30px;
  left: -3px;
}

.top-deals {
  margin-bottom: 30px;
}
.top-deals .label {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100px;
  width: 100px;
  background: url(../imgs/page/homepage2/topdeals.svg) no-repeat 0px 0px;
  z-index: 12;
  border-radius: 0;
}
.top-deals .label span {
  transform: rotate(-45deg);
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 7px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
}
.top-deals .card-grid-inner {
  padding: 20px 30px 20px 10px;
  border: 3px solid #425A8B;
}
.top-deals .card-grid-inner:hover {
  border: 3px solid #425A8B;
}
.top-deals .card-grid-inner .image-box {
  height: 210px;
  line-height: 190px;
}
.top-deals .card-grid-inner .image-box .swiper-container {
  padding-bottom: 40px;
}
.top-deals .card-grid-inner .box-count.box-count-square .deals-countdown .countdown-amount {
  width: 33px;
}
.top-deals .card-grid-inner .box-count .deals-countdown {
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
}
.top-deals .card-grid-inner .box-count.box-count-square .deals-countdown .countdown-section {
  margin: 0px 5px 0px 4px;
}
.top-deals .card-grid-inner .box-count.box-count-square .deals-countdown .countdown-section::before {
  right: -7px;
}
.top-deals .box-top-deals {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.top-deals .box-top-deals .top-deals-left {
  flex: 0 0 auto;
  width: 40%;
  max-width: 100%;
  padding: 0px 10px;
}
.top-deals .box-top-deals .swiper-pagination-custom {
  bottom: 0px;
  padding-left: 0px;
}
.top-deals .box-top-deals .top-deals-right {
  flex: 0 0 auto;
  width: 60%;
  max-width: 100%;
  padding: 0px 10px;
}
.top-deals .box-top-deals .top-deals-right .price-info .price-line {
  font-size: 20px;
  font-weight: 400;
}
.top-deals .box-top-deals .top-deals-right .box-progress {
  padding-bottom: 0px;
}
.top-deals .box-top-deals .top-deals-right .box-progress .progress-bar .progress-bar-inner,
.top-deals .box-top-deals .top-deals-right .box-progress .progress-bar {
  height: 7px;
}
.top-deals .box-top-deals .top-deals-right .box-progress .progress-bar .progress-bar-inner:before {
  height: 13px;
  width: 13px;
}

.grid-banner-height {
  background: url(../imgs/page/homepage2/bg-xiaomi.svg) no-repeat;
  padding: 35px 20px;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  border-radius: 4px;
  position: relative;
}
.grid-banner-height::before {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 50%;
  background: url(../imgs/page/homepage2/xiaomi.png) no-repeat left top;
  background-size: cover;
}

.card-vendor {
  border-radius: 4px;
  border: 1px solid #D5DFE4;
  padding: 20px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 30px;
}
.card-vendor:hover {
  border-color: #8C9EC5;
}
.card-vendor .btn-gray:hover {
  background-color: #425A8B;
  color: #ffffff;
}
.card-vendor .card-top-vendor {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #D5DFE4;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.card-vendor .card-top-vendor .card-top-vendor-left {
  width: 30%;
  max-width: 115px;
  min-width: 115px;
}
.card-vendor .card-top-vendor .card-top-vendor-left img {
  max-height: 40px;
}
.card-vendor .card-top-vendor .card-top-vendor-right {
  width: 80%;
  text-align: right;
}
.card-vendor .card-bottom-vendor {
  position: relative;
}

.location {
  background: url(../imgs/page/vendor/location.svg) no-repeat 0px 0px;
  padding: 0px 0px 0px 32px;
  color: #8C9EC5;
  font-size: 16px;
  line-height: 24px;
}

.phone {
  background: url(../imgs/page/vendor/phone.svg) no-repeat 0px 0px;
  padding: 0px 0px 1px 32px;
  color: #8C9EC5;
  font-size: 16px;
  line-height: 24px;
}

.card-staff {
  margin-bottom: 35px;
  width: 100%;
  display: inline-block;
}
.card-staff .image-staff {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #D5DFE4;
}
.card-staff .image-staff img {
  display: block;
  width: 100%;
}
.card-staff .info-staff {
  width: 100%;
  display: inline-block;
}
.card-staff .info-staff h4 {
  margin-bottom: 5px;
}

.card-grid-inner:hover .btn.btn-trend, .card-grid-inner:hover .btn.btn-wishlist, .card-grid-inner:hover .btn.btn-wishlist, .card-grid-inner:hover .btn.btn-compare, .card-grid-inner:hover .btn.btn-quickview {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  pointer-events: auto;
}

.swiper-slide .card-grid-style-2:last-child.border-bottom {
  border: 0 !important;
}

.card-category {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.card-category .card-info {
  text-align: center;
}
.card-category .card-image {
  margin-bottom: 10px;
}

.card-circle {
  text-align: center;
}
.card-circle .card-image {
  text-align: center;
  margin-bottom: 10px;
}
.card-circle .card-image .inner-image {
  width: 154px;
  height: 154px;
  display: inline-block;
  border-radius: 50%;
  background-color: #F0F3F8;
}
.card-circle .card-image .inner-image img {
  max-width: 80%;
  display: inline-block;
  vertical-align: middle;
}
.card-circle .card-info a {
  color: #425A8B;
}

.list-products-5 .card-style-full-image {
  margin-bottom: 30px;
}
.list-products-5 .card-style-full-image .card-grid-inner {
  padding: 0px;
  border: 0px;
}
.list-products-5 .card-style-full-image .card-grid-inner .info-right {
  padding: 0px;
}
.list-products-5 .card-style-full-image .card-grid-inner .image-box {
  height: auto;
}
.list-products-5 .card-style-full-image .card-grid-inner .image-box img {
  width: 100%;
  max-width: 100%;
}
.list-products-5 .card-style-full-image .card-grid-inner .image-box .label {
  top: 15px;
  left: 15px;
}

.card-product-small {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.card-product-small .card-image {
  background-color: #F0F3F8;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}
.card-product-small .card-info .box-prices {
  display: flex;
  margin-top: 5px;
}
.card-product-small .card-info .box-prices * {
  width: 50%;
  font-size: 12px;
  line-height: 12px;
}
.card-product-small .card-info .box-prices .price-bold {
  font-weight: bold;
}
.card-product-small .card-info .box-prices .price-line {
  text-decoration: line-through;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.loader {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  color: #425A8B;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 3.5em;
}

.swiper-pagination {
  text-align: left;
}
.swiper-pagination .swiper-pagination-customs {
  background: #D9D9D9;
  border-radius: 50%;
}
.swiper-pagination .swiper-pagination-customs:hover {
  background: #FD9636;
}
.swiper-pagination .swiper-pagination-customs-active {
  background: #FD9636;
}
.swiper-pagination.swiper-pagination-2 {
  top: auto;
  height: auto;
  z-index: 123;
  line-height: 30px;
  text-align: center;
}

.box-newsletter {
  padding: 90px 0px;
  background: #0E224D url(../imgs/template/bg-newsletter.svg) no-repeat right 10px bottom 10px;
}
.box-newsletter .box-form-newsletter {
  position: relative;
}
.box-newsletter .box-form-newsletter .input-newsletter {
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  border: 0px;
  height: 50px;
  padding: 5px 20px;
}
.box-newsletter .box-form-newsletter .btn-brand-2 {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 50px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/*page loading*/
.preloader {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}
.preloader img {
  max-width: 250px;
}

@keyframes rotate {
  0%, 49.999%, 100% {
    transform: none;
  }
  50%, 99.999% {
    transform: rotate(90deg);
  }
}
@keyframes shift-left {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(-75%);
  }
}
@keyframes shift-right {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(75%);
  }
}
.preloader-dots {
  --uib-size: 40px;
  --uib-speed: .9s;
  --uib-color: #425A8B;
  position: relative;
  height: calc(var(--uib-size) / 2);
  width: var(--uib-size);
  filter: url("#uib-jelly-ooze");
  animation: rotate calc(var(--uib-speed) * 2) linear infinite;
}
.preloader-dots::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 25%;
  width: 50%;
  height: 100%;
  background: var(--uib-color);
  border-radius: 100%;
  animation: shift-left var(--uib-speed) ease infinite;
}
.preloader-dots::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 25%;
  width: 50%;
  height: 100%;
  background: var(--uib-color);
  border-radius: 100%;
  animation: shift-right var(--uib-speed) ease infinite;
}

/*select 2 style*/
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #425A8B;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #425A8B transparent transparent transparent;
  border-width: 5px 5px 0 5px;
}

.select2-search--dropdown {
  padding: 0px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #CAD6EC;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 8px;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #D5DFE4;
  color: #425A8B;
}

.modal-header .btn-close {
  margin: -0.5rem 0rem -0.5rem auto;
}

.banner-hero {
  padding: 24px 0px 20px 0px;
  position: relative;
}
.banner-hero .box-swiper {
  height: 100%;
}
.banner-hero.banner-1 {
  background-color: #ffffff;
}
.banner-hero.banner-1 .banner-big {
  height: 500px;
  border-radius: 8px;
  padding: 60px 60px 25px 60px;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain !important;
}
.banner-hero.banner-1 .banner-big.banner-big-2 {
  padding: 110px 60px 25px 60px;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 320px;
}
.banner-hero.banner-1 .banner-big.banner-big-3 {
  padding: 50px 40px 25px 40px;
  background-image: url(../imgs/page/homepage4/bg-banner.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover !important;
}
.banner-hero.banner-1 .banner-small {
  height: 238px;
  border-radius: 8px;
  padding: 20px 20px 20px 20px;
  margin-bottom: 24px;
  position: relative;
}
.banner-hero.banner-1 .banner-small.banner-small-1 {
  background-image: url(../imgs/page/homepage1/banner-small-1.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 60% auto;
}
.banner-hero.banner-1 .banner-small.banner-small-2 {
  background-image: url(../imgs/page/homepage1/banner-small-2.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 70% auto;
}
.banner-hero.banner-1 .banner-small.banner-laptop {
  background-image: url(../imgs/page/homepage2/laptop.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto;
}
.banner-hero.banner-1 .banner-small.banner-laptop::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  height: 125px;
  width: 100%;
  background-image: url(../imgs/page/homepage2/circle-top.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  z-index: 1;
  border-top-left-radius: 8px;
}
.banner-hero.banner-1 .banner-small.banner-laptop * {
  z-index: 2;
  position: relative;
}
.banner-hero.banner-1 .banner-small.banner-player {
  background-image: url(../imgs/page/homepage2/player.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
}
.banner-hero.banner-1 .banner-small.banner-apple {
  background-image: url(../imgs/page/homepage2/apple-day.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
}
.banner-hero.banner-1 .banner-small.banner-samsung {
  background-image: url(../imgs/page/homepage2/samsung.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
}
.banner-hero.banner-8 .banner-big {
  height: 518px;
  border-radius: 8px;
  padding: 10px 20px 10px 50px;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain !important;
  background-image: url(../imgs/page/homepage8/bg-banner.png);
}

@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes hero-thumb-animation-2 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes hero-thumb-sm-animation {
  0% {
    -webkit-transform: translateY(-20px) translateX(50px);
    -moz-transform: translateY(-20px) translateX(50px);
    -ms-transform: translateY(-20px) translateX(50px);
    transform: translateY(-20px) translateX(50px);
  }
  100% {
    -webkit-transform: translateY(-20px) translateX(0px);
    -moz-transform: translateY(-20px) translateX(0px);
    -ms-transform: translateY(-20px) translateX(0px);
    transform: translateY(-20px) translateX(0px);
  }
}
@keyframes hero-thumb-sm-2-animation {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.shape-1 {
  -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
  -moz-animation: hero-thumb-animation 2s linear infinite alternate;
  -o-animation: hero-thumb-animation 2s linear infinite alternate;
  animation: hero-thumb-animation 2s linear infinite alternate;
}

.shape-1-2 {
  -webkit-animation: hero-thumb-animation-2 2s linear infinite alternate;
  -moz-animation: hero-thumb-animation-2 2s linear infinite alternate;
  -o-animation: hero-thumb-animation-2 2s linear infinite alternate;
  animation: hero-thumb-animation-2 2s linear infinite alternate;
}

.shape-2 {
  -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  animation: hero-thumb-sm-animation 4s linear infinite alternate;
}

.shape-3 {
  -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}

.banner-right {
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  height: 670px;
  background: url(../imgs/page/homepage1/bg-topsell.png) no-repeat 0px 0px;
  background-size: cover;
  padding: 50px 10px;
}
.banner-right .text-no {
  background-color: #0BA9ED;
  border-radius: 13px;
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
  display: inline-block;
  padding: 0px 16px;
}
.banner-right .text-main {
  font-size: 32px;
  line-height: 31px;
  color: #0E224D;
  font-weight: bold;
}
.banner-right .text-desc {
  font-size: 22px;
  line-height: 32px;
  color: #425A8B;
}

.banner-homepage3 {
  background-color: #D1ECFD;
  background-image: url(../imgs/page/homepage3/bg-circle.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-bottom: 0px;
}
.banner-homepage3 .container-banner {
  max-width: 1400px;
  margin: auto;
}
.banner-homepage3 .box-swiper .swiper-container {
  padding-bottom: 0px;
}
.banner-homepage3 .swiper-pagination-custom {
  bottom: 40px;
  padding-left: 0px;
}
.banner-homepage3 .swiper-pagination .swiper-pagination-customs {
  background-color: #ffffff;
}
.banner-homepage3 .swiper-pagination .swiper-pagination-customs-active, .banner-homepage3 .swiper-pagination .swiper-pagination-customs:hover {
  background-color: #FD9636;
}

.header {
  padding: 0px 0;
  float: left;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #D5DFE4;
}
.header .container {
  max-width: 92%;
}
.header .main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header .main-header .header-left {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0px;
}
.header .main-header .header-left .header-logo {
  max-width: 150px;
  width: 100%;
}
.header .main-header .header-left .header-logo a {
  display: block;
}
.header .main-header .header-left .header-logo a img {
  min-height: 37px;
}
.header .main-header .header-left .header-nav {
  display: inline-block;
  width: 45%;
  text-align: center;
}
.header .main-header .header-left .header-search {
  width: 35%;
}
.header .main-header .header-left .header-search .box-header-search {
  border-radius: 8px;
  border: 1px solid #D5DFE4;
  padding: 5px;
}
.header .main-header .header-left .header-search .box-header-search .form-search {
  display: flex;
}
.header .main-header .header-left .header-search .box-header-search .box-keysearch {
  width: 100%;
}
.header .main-header .header-left .header-search .box-header-search .box-keysearch input {
  border: 0px !important;
}
.header .main-header .header-left .header-search .box-header-search .box-category {
  position: relative;
  margin-right: 15px;
  min-width: 120px;
}
.header .main-header .header-left .header-search .box-header-search .box-category::before {
  content: "";
  height: 14px;
  width: 1px;
  background-color: #EBF0F3;
  position: absolute;
  top: 8px;
  right: -14px;
}
.header .main-header .header-left .header-search .box-header-search .box-category select {
  height: 100%;
  width: 100%;
  border: 0px;
  padding: 0px 20px 0px 5px;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../imgs/template/arrow-down.svg);
  background-position: right center;
  background-repeat: no-repeat;
}
.header .main-header .header-left .header-shop {
  width: 35%;
  text-align: right;
}
.header .main-header .header-left .header-shop .icon-list {
  display: inline-block;
  padding: 0px 15px 0px 30px;
  background-repeat: no-repeat;
  background-position: 0px -1px;
  position: relative;
  font-size: 16px;
  color: #425A8B;
  line-height: 24px;
  cursor: pointer;
}
.header .main-header .header-left .header-shop .icon-list:hover {
  color: #0BA9ED;
}
.header .main-header .header-left .header-shop .icon-list:last-child {
  padding-right: 0px;
}
.header .main-header .header-left .header-shop .icon-list img {
  float: left;
  margin: 3px 6px 0px 0px;
}
.header .main-header .header-left .header-shop .icon-account {
  background-image: url(../imgs/template/account.svg);
}
.header .main-header .header-left .header-shop .icon-wishlist {
  background-image: url(../imgs/template/wishlist.svg);
  background-position: 0px 2px;
  padding-left: 35px;
}
.header .main-header .header-left .header-shop .icon-cart {
  background-image: url(../imgs/template/cart.svg);
  background-position: 0px 2px;
  padding-left: 35px;
}
.header .main-header .header-left .header-shop .icon-compare {
  background-image: url(../imgs/template/compare.svg);
  background-position: 0px 2px;
}
.header .main-header .header-left .header-shop .number-item {
  background-color: #FD9636;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  left: 14px;
  width: 20px;
  height: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  display: inline-block;
}
.header .main-header .header-right {
  max-width: 225px;
  width: 100%;
}
.header .main-header .header-right .block-signin {
  text-align: right;
}
.header .nav-main-menu {
  width: auto;
  padding: 0px;
  float: none;
}
.header .burger-icon {
  display: none;
}
.header .main-menu {
  display: inline-block;
  padding-top: 3px;
}
.header .main-menu li {
  float: left;
  position: relative;
  text-align: left;
}
.header .main-menu li.has-children > a::after {
  content: "\f151";
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1 !important;
  font-size: 14px;
  opacity: 1;
  margin-left: 0px;
  position: relative;
  top: 2px;
}
.header .main-menu li.hr {
  padding: 0px 22px;
}
.header .main-menu li.hr span {
  background-color: #DDE4F0;
  height: 1px;
  width: 100%;
  display: block;
  margin: 5px 0;
}
.header .main-menu li a {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #425A8B;
  line-height: 24px;
  display: block;
  padding: 13px 12px 13px 12px;
  text-decoration: none;
  position: relative;
}
.header .main-menu li a:hover {
  color: #FD9636;
}
.header .main-menu li a:hover i {
  opacity: 1;
  transition: 0.2s;
}
.header .main-menu li a i {
  font-size: 12px;
  margin-right: 8px;
  opacity: 0.38;
  transition: 0.2s;
}
.header .main-menu li ul {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  position: absolute;
  top: calc(100% + 9px);
  left: 0px;
  z-index: 999999;
  min-width: 220px;
  border-radius: 0 0 4px 4px;
  padding: 8px 0px 8px 0;
  box-shadow: 0;
  background-color: #ffffff;
  border: 1px solid #D5DFE4;
}
.header .main-menu li ul li {
  width: 100%;
}
.header .main-menu li ul li a {
  font-size: 14px;
  color: #425A8B;
  position: relative;
  padding: 6px 22px 6px 22px;
  transition: 0.2s;
}
.header .main-menu li ul li a:hover {
  padding-left: 25px;
  transition: 0.2s;
}
.header .main-menu li ul li a.closer {
  padding: 8px 22px 8px 22px;
}
.header .main-menu li ul li ul {
  top: 0px;
  left: 100%;
}
.header .main-menu li:hover > ul {
  opacity: 1;
  visibility: visible;
}
.header .main-menu li:hover > ul li {
  width: 100%;
}
.header .text-link-bd-btom {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #425A8B;
  display: inline-block;
  position: relative;
  padding-left: 13px;
}
.header .text-link-bd-btom:hover {
  color: #425A8B;
}
.header .text-link-bd-btom::before {
  content: "";
  background: #425A8B;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
  animation: shadow-pulse 1s infinite;
}
.header .block-signin .btn-shadow {
  box-shadow: 0px 47px 65px rgba(21, 28, 38, 0.1);
}
.header.stick {
  padding: 0px 0px;
}
.header.header-2 {
  background-color: #DBECE5;
}

.sticky-bar.stick {
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid #DDE4F0;
  background: #fff;
}
.sticky-bar.stick.sticky-white-bg {
  background-color: #fff;
}
.sticky-bar.stick.sticky-blue-bg {
  background-color: #3286e0;
}

.user-account {
  display: flex;
  align-items: center;
}
.user-account img {
  max-width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-account .user-name {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.perfect-scroll {
  height: 100vh;
  width: 300px;
  position: relative;
}

.body-overlay-1 {
  background: transparent;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  cursor: crosshair;
  z-index: 1002;
}

.mobile-menu-active .body-overlay-1 {
  opacity: 1;
  visibility: visible;
}

.header-top {
  background-color: #425A8B;
  color: #ffffff;
  padding: 10px 0px;
}
.header-top span {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.header-top a {
  color: #425A8B;
}
.header-top .line-right {
  position: relative;
  display: inline-block;
  padding-right: 35px;
}
.header-top .line-right::before {
  content: "";
  position: absolute;
  top: 5px;
  right: 15px;
  width: 1px;
  height: 18px;
  border-right: 1px dashed #425A8B;
}
.header-top.header-top-green {
  background-color: #425A8B;
}
.header-top.header-top-green a {
  color: #DDE4F0;
}
.header-top.header-top-green .line-right::before {
  border-right: 1px dashed #DDE4F0;
}

.header-style-2 {
  background-color: #425A8B;
}
.header-style-2.sticky-bar.stick {
  background-color: #425A8B;
  border-bottom: 1px solid #008c99;
}
.header-style-2 .burger-icon.burger-icon-white > span::before,
.header-style-2 .burger-icon.burger-icon-white > span::after {
  background-color: #CAD6EC;
}
.header-style-2 .main-menu > li > a {
  color: #ffffff;
}
.header-style-2 .main-menu > li > a:hover {
  color: #8C9EC5;
}
.header-style-2 .btn-default {
  background-color: #425A8B;
  color: #ffffff;
  border: 1px solid #ffffff;
  background-image: url(../imgs/theme/icons/arrow-small-right-w.svg);
  background-repeat: no-repeat;
  background-position: center right 25px;
  padding-right: 56px;
}

.header-style-3 {
  background-color: #FFF3EA;
}
.header-style-3.sticky-bar.stick {
  background-color: #FFF3EA;
}

.header-style-4 {
  background-color: #E8FCFF;
}
.header-style-4.sticky-bar.stick {
  background-color: #E8FCFF;
}

.header-style-5 {
  z-index: 2;
}
.header-style-5 .burger-icon.burger-icon-white > span::before,
.header-style-5 .burger-icon.burger-icon-white > span::after {
  background-color: #CAD6EC;
}
.header-style-5 .main-menu > li > a {
  color: #ffffff;
}
.header-style-5 .main-menu > li > a:hover {
  color: #8C9EC5;
}
.header-style-5.sticky-bar.stick {
  background-color: #425A8B;
  border-bottom: 1px solid #008c99;
}
.header-style-5 .btn-default {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  background-image: url(../imgs/theme/icons/arrow-small-right-w.svg);
  background-repeat: no-repeat;
  background-position: center right 25px;
  padding-right: 56px;
}

@media (min-width: 1400px) {
  .header-container .container {
    max-width: 1360px;
  }
}
.header-container .main-header .header-left .header-nav {
  width: 35%;
}
.header-container .main-header .header-left .header-shop {
  width: 45%;
}

.header-bottom {
  border-top: 1px solid #D5DFE4;
  padding: 10px 0px 10px 0px;
}
.header-bottom .dropdown-toggle::after {
  display: none;
}
.header-bottom .container {
  display: flex;
}
.header-bottom .dropdown {
  padding: 0px;
}
.header-bottom .header-nav {
  width: 100%;
  padding-left: 20px;
}
.header-bottom .header-nav .main-menu {
  padding-top: 0px;
}
.header-bottom .header-nav .main-menu li a {
  padding: 9px 13px 5px 18px;
  font-size: 14px;
}
.header-bottom .header-nav .main-menu li.has-children > a::after {
  content: "";
  background: url(../imgs/page/homepage4/angle-down.svg) no-repeat 0px 0px;
  height: 10px;
  width: 11px;
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -4px;
}
.header-bottom .discount {
  display: inline-block;
  padding: 9px 0px 9px 42px;
  background-image: url(../imgs/page/homepage4/discount.svg);
  background-position: left center;
  background-repeat: no-repeat;
  min-width: 160px;
  max-height: 53px;
}
.header-bottom .dropdown-right {
  background-image: url(../imgs/page/homepage4/arrow-down.svg);
  background-position: right center;
  background-repeat: no-repeat;
  padding: 0px 20px 0px 0px;
  display: block;
  margin-left: 0px;
}
.header-bottom .dropdown-menu {
  width: 100%;
  padding: 3px 0px;
}
.header-bottom .dropdown-menu .dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-menu {
  border: 1px solid #D5DFE4;
  border-radius: 4px;
}

.menu-topbar-right .dropdown-menu {
  border: 1px solid #D5DFE4;
  border-radius: 0 0 4px 4px;
}
.menu-topbar-right .dropdown-menu[data-bs-popper] {
  margin-top: 10px;
}

.dropdown-item {
  font-size: 14px;
  color: #425A8B;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
  color: #425A8B;
  text-decoration: none;
  background-color: #D5DFE4;
}

.dropdown-language .dropdown-item img {
  margin-right: 5px;
}

.box-dropdown-cart {
  position: relative;
}

.dropdown-cart {
  display: none;
  visibility: hidden;
  position: absolute;
  right: -70px;
  top: calc(100% + 22px);
  z-index: 99;
  width: 320px;
  background-color: #fff;
  padding: 22px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0 0 4px 4px;
  border: 1px solid #D5DFE4;
}
.dropdown-cart.dropdown-open {
  display: block;
  visibility: visible;
}

.dropdown-account {
  width: 200px;
  left: 0;
  display: none;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 22px);
  z-index: 99;
  width: 220px;
  background-color: #fff;
  padding: 22px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0 0 4px 4px;
  border: 1px solid #D5DFE4;
}
.dropdown-account.dropdown-open {
  display: block;
  visibility: visible;
}
.dropdown-account ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 20px;
}
.dropdown-account ul li:last-child {
  margin: 0;
}
.dropdown-account ul li a {
  color: #425A8B;
}
.dropdown-account ul li a:hover {
  color: #0BA9ED;
}

.item-cart {
  display: flex;
  text-align: left;
}
.item-cart .cart-image {
  min-width: 80px;
  max-width: 80px;
  margin-right: 15px;
}
.item-cart .cart-image img {
  width: 100%;
}
.item-cart .color-brand-3:hover {
  color: #0BA9ED !important;
}

.mobile-header-wrapper-style {
  position: fixed;
  top: 0;
  width: 360px;
  min-height: 100vh;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 1003;
}
.mobile-header-wrapper-style.sidebar-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner {
  padding: 0px 0px 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-logo {
  position: relative;
  border-bottom: 1px solid #D5DFE4;
  padding-bottom: 15px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  background-color: #ffffff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
  border-bottom: 0;
  margin-bottom: 15px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a {
  display: block;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
  width: 100px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
  padding: 30px 30px 30px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu {
  max-width: 300px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
  padding: 13px 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand {
  right: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  text-align: center;
  font-size: 12px;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 38px;
  top: 5px;
  color: #425A8B;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand i {
  font-size: 18px;
  font-weight: 300;
  opacity: 0.5;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand {
  background: rgba(255, 255, 255, 0.2);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand i::before {
  content: "\f113";
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: #425A8B;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 10px 0 0 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 10px 0;
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children .menu-expand {
  top: 0px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li:hover a {
  padding-left: 13px;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  font-size: 14px;
  display: block;
  font-weight: 500;
  color: #425A8B;
  padding-left: 10px;
  position: relative;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
  margin-top: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children.active {
  padding-bottom: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.hr {
  padding: 0 0 0 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.hr span {
  background-color: #DDE4F0;
  height: 1px;
  width: 100%;
  display: block;
  margin: 5px 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #425A8B;
  padding-left: 3px;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .categories-dropdown-wrap ul li a {
  padding: 5px 15px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #425A8B;
  margin: 17px 0 30px 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 14px;
  display: block;
  font-weight: 500;
  color: #425A8B;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a:hover {
  color: #425A8B;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a i {
  font-size: 14px;
  color: #425A8B;
  margin-right: 8px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 0 0 0;
  width: 100%;
  z-index: 11;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: #425A8B;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: #425A8B;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .site-copyright {
  font-size: 13px;
  color: #425A8B;
}

.burger-icon {
  position: absolute;
  width: 24px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 15px;
  right: 0px;
  z-index: 1004;
}
.burger-icon > span {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
}
.burger-icon > span.burger-icon-top {
  top: 2px;
}
.burger-icon > span.burger-icon-bottom {
  bottom: 2px;
}
.burger-icon > span.burger-icon-mid {
  top: 9px;
}
.burger-icon.burger-close {
  position: fixed;
  top: 32px;
  right: 30px;
}
.burger-icon.burger-close > span.burger-icon-top {
  display: none;
  opacity: 0;
}
.burger-icon.burger-close > span.burger-icon-mid {
  top: 8px;
  transform: rotate(45deg);
}
.burger-icon.burger-close > span.burger-icon-bottom {
  bottom: 10px;
  transform: rotate(-45deg);
}

.burger-icon > span::before,
.burger-icon > span::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #333;
}

.burger-icon.burger-icon-white > span::before,
.burger-icon.burger-icon-white > span::after {
  background-color: #8C9EC5;
}

.mobile-search form {
  background-color: #EAE4E9;
  border-radius: 8px;
  height: 44px;
  padding: 0px 15px 0px 44px;
  position: relative;
}
.mobile-search form input {
  border: 0;
  background-color: transparent;
  width: 100%;
  padding: 12px 10px;
}
.mobile-search form i {
  position: absolute;
  left: 18px;
  top: 14px;
  font-size: 18px;
  color: #5971A3;
}

.mobile-social-icon h6 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 30px;
}
.mobile-social-icon a {
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  height: 30px;
  width: 30px;
  display: inline-flex;
  background: #efeaff;
  border-radius: 30px;
  line-height: 1;
  align-content: center;
  justify-content: center;
}
.mobile-social-icon a img {
  max-width: 18px;
}
.mobile-social-icon a img:hover {
  opacity: 0.8;
}
.mobile-social-icon a:hover {
  transform: translateY(-2px);
  transition-duration: 0.5s;
  margin-top: -2px;
}
.mobile-social-icon a:last-child {
  margin-right: 0;
}

.mobile-account {
  margin: 20px 0 25px 0;
  padding: 30px 0 20px 0;
  border-top: thin solid #DDE4F0;
  border-bottom: thin solid #DDE4F0;
}
.mobile-account ul.mobile-menu {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
.mobile-account h6 {
  font-weight: 600;
  font-size: 18px;
}
.mobile-account ul li {
  padding: 8px 0;
}
.mobile-account ul li a {
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 400;
  position: relative;
  display: inline-block;
  color: #425A8B;
  transition-duration: 0.2s;
}
.mobile-account ul li:hover a {
  padding-left: 3px;
  transition-duration: 0.2s;
  color: #425A8B;
}

.topbar {
  padding: 10px 70px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #D5DFE4;
  z-index: 1002;
  position: relative;
  background: #ffffff;
}
.topbar .container-topbar {
  display: flex;
}
.topbar .container-topbar .menu-topbar-left,
.topbar .container-topbar .menu-topbar-right,
.topbar .container-topbar .info-topbar {
  width: 33.3%;
}
.topbar .container-topbar .menu-topbar-right {
  text-align: right;
}

.top-gray-1000 {
  background-color: #0E224D;
}
.top-gray-1000 .nav-small li a {
  color: #ffffff;
}
.top-gray-1000 .color-brand-3 {
  color: #ffffff !important;
}
.top-gray-1000 .dropdown-right {
  background-image: url(../imgs/page/homepage4/arrow-down.svg);
}

.top-brand-2 {
  background-color: #FD9636;
}
.top-brand-2 .nav-small li a {
  color: #ffffff;
}
.top-brand-2 .color-brand-3 {
  color: #ffffff !important;
}
.top-brand-2 .color-success {
  color: #ffffff !important;
}
.top-brand-2 .dropdown-right {
  background-image: url(../imgs/page/homepage5/arrow-white.svg);
}

.top-brand-1 {
  background-color: #0BA9ED;
}
.top-brand-1 .nav-small li a {
  color: #ffffff;
}
.top-brand-1 .color-brand-3 {
  color: #ffffff !important;
}
.top-brand-1 .color-success {
  color: #ffffff !important;
}
.top-brand-1 .dropdown-right {
  background-image: url(../imgs/page/homepage5/arrow-white.svg);
}

.dropdown-right {
  background-image: url(../imgs/template/arrow-down.svg);
  background-position: right center;
  background-repeat: no-repeat;
  padding: 0px 15px 0px 0px;
  display: inline-block;
  margin-left: 10px;
}

.nav-small li {
  position: relative;
  display: inline-block;
}
.nav-small li a {
  display: block;
  padding: 0px 30px 0px 0px;
  color: #425A8B;
}
.nav-small li a:hover {
  color: #FD9636;
}
.nav-small li a::before {
  content: "";
  position: absolute;
  top: 5px;
  right: 15px;
  width: 1px;
  height: 13px;
  background-color: #D5DFE4;
}
.nav-small li:last-child a::before {
  display: none;
}

.sidebar-left {
  position: absolute;
  top: 0px;
  background-color: #ffffff;
  left: 0px;
  width: 50px;
  height: 100%;
  border-right: 1px solid #D5DFE4;
  z-index: 1001;
  padding-top: 0px;
  padding-bottom: 25px;
}
.sidebar-left.stick .btn.btn-open {
  height: 68px;
  background: #fff url(../imgs/template/open.svg) no-repeat center center;
}
.sidebar-left .menu-icons {
  padding: 142px 0px 0px 0px;
}
.sidebar-left .menu-icons li a {
  display: block;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.sidebar-left .menu-icons li a img {
  vertical-align: middle;
}

.menu-texts {
  position: absolute;
  top: 112px;
  left: 0px;
  background-color: #ffffff;
  min-width: 320px;
  border-right: 1px solid #D5DFE4;
  border-bottom: 1px solid #D5DFE4;
}
.menu-texts li a {
  display: block;
  padding: 0px 20px 0px 0px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #425A8B;
  text-decoration: none;
}
.menu-texts li a:hover, .menu-texts li a.active {
  color: #0BA9ED;
}
.menu-texts li a:hover .img-link, .menu-texts li a.active .img-link {
  position: relative;
}
.menu-texts li a:hover .img-link::before, .menu-texts li a.active .img-link::before {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #425A8B;
  position: absolute;
  top: 0px;
  right: 0px;
}
.menu-texts li a img {
  vertical-align: middle;
}
.menu-texts li a .img-link {
  height: 50px;
  width: 50px;
  display: inline-block;
  border-right: 1px solid #D5DFE4;
  text-align: center;
  margin-right: 15px;
}
.menu-texts li .sub-menu {
  display: none;
}
.menu-texts li:hover .sub-menu, .menu-texts li.submenu-open .sub-menu {
  display: block;
  position: absolute;
  top: 0px;
  left: 100%;
  min-width: 250px;
  height: auto;
  background-color: #ffffff;
  border-left: 1px solid #D5DFE4;
  border-right: 1px solid #D5DFE4;
  border-bottom: 1px solid #D5DFE4;
  padding-top: 8px;
  padding-bottom: 10px;
  min-height: 100%;
}
.menu-texts li:hover .sub-menu li a, .menu-texts li.submenu-open .sub-menu li a {
  font-weight: 400;
  color: #718BC2;
  padding-left: 25px;
  line-height: 38px;
}
.menu-texts li:hover .sub-menu li a:hover, .menu-texts li.submenu-open .sub-menu li a:hover {
  color: #0BA9ED;
}
.menu-texts > li.has-children > a {
  padding-right: 30px;
  background: url(../imgs/template/icons/arrow-gray.svg) no-repeat right 20px center;
}
.menu-texts > li.has-children > a:hover, .menu-texts > li.has-children > a.active {
  color: #0BA9ED;
  background: url(../imgs/template/icons/arrow-gray-hover.svg) no-repeat right 20px center;
}
.menu-texts.menu-close {
  min-width: auto;
  border: 0;
}
.menu-texts.menu-close li.has-children a {
  padding: 0px;
  background-image: none;
}
.menu-texts.menu-close li a {
  padding: 0px;
}
.menu-texts.menu-close li a .text-link {
  display: none;
}
.menu-texts.menu-close li a .img-link {
  margin-right: 0px;
}

.select2-container {
  max-width: unset;
  min-width: 130px;
}

.sidebar-title {
  border-bottom: thin solid #425A8B;
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.search-form form {
  position: relative;
}
.search-form form input {
  border: 1px solid #ececec;
  border-radius: 10px;
  height: 64px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  width: 100%;
}
.search-form form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 20px;
  height: 100%;
  padding: 0 24px;
  background-color: transparent;
  color: #242424;
}

.widget-categories {
  background-color: #FFF3EA !important;
}
.widget-categories li {
  padding: 8px 0;
}
.widget-categories li + li {
  border-top: 1px solid #425A8B;
}
.widget-categories li a {
  color: #425A8B;
  font-size: 16px;
}
.widget-categories li span.count {
  background-color: #DDD3FA;
  color: #425A8B;
  border-radius: 3px;
  display: block;
  padding: 3px 5px;
  min-width: 26px;
  text-align: center;
}
.widget-categories li:hover span.count {
  background-color: #425A8B;
  color: #ffffff;
}

.post-list-small-item {
  transition-duration: 0.2s;
}
.post-list-small-item:hover {
  transition-duration: 0.2s;
  transform: translateY(-3px);
}
.post-list-small-item figure img {
  max-width: 85px;
  border-radius: 5px;
}
.post-list-small-item .post-meta {
  font-size: 13px;
}
.post-list-small-item .post-meta img {
  max-width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.post-list-small-item h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.post-list-small-item:not(:last-child) {
  margin-bottom: 15px;
}

.footer .icon-socials {
  display: inline-block;
  margin-right: 15px;
  height: 32px;
  width: 32px;
  transition-duration: 0.2s;
}
.footer .icon-socials:hover {
  transform: translateY(-3px);
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-facebook {
  background: url(../imgs/template/icons/facebook-gray.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-facebook:hover {
  background: url(../imgs/template/icons/facebook.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-twitter {
  background: url(../imgs/template/icons/twitter-gray.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-twitter:hover {
  background: url(../imgs/template/icons/twitter.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-instagram {
  background: url(../imgs/template/icons/instagram-gray.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-instagram:hover {
  background: url(../imgs/template/icons/instagram.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-linkedin {
  background: url(../imgs/template/icons/linkedin-gray.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-linkedin:hover {
  background: url(../imgs/template/icons/linkedin.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .width-20 {
  width: 20%;
}
.footer .width-25 {
  width: 25%;
}
.footer .width-23 {
  width: 23%;
}
.footer .width-16 {
  width: 16%;
}
.footer .menu-footer {
  display: inline-block;
}
.footer .menu-footer li {
  list-style: none;
  margin-bottom: 8px;
}
.footer .menu-footer li a {
  color: #425A8B;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: block;
  background: url(../imgs/template/icons/arrow-gray.svg) no-repeat 0px 6px;
  padding: 0px 0px 0px 15px;
  transition-duration: 0.2s;
}
.footer .menu-footer li a:hover {
  color: #0BA9ED;
  transition-duration: 0.2s;
  padding-left: 18px;
}
.footer .footer-bottom {
  border-top: 1px solid #CAD6EC;
  padding: 30px 0px;
  color: #8C9EC5;
  font-size: 14px;
}
.footer .footer-bottom a {
  text-decoration: none;
}

#scrollUp {
  width: 42px;
  height: 42px;
  color: #ffffff;
  right: 30px;
  bottom: 30px;
  border-radius: 30px;
  text-align: center;
  overflow: hidden;
  z-index: 999 !important;
  border: 0;
  background-color: #fff;
  transition-duration: 0.2s;
  background-color: #425A8B;
}
#scrollUp i {
  display: block;
  line-height: 46px !important;
  font-size: 20px;
  color: #fff;
}
#scrollUp:hover {
  transform: translateY(-3px);
  transition-duration: 0.2s;
}

.menu-bottom li {
  display: inline-block;
}
.menu-bottom li a {
  display: block;
  padding: 0px 15px;
}
.menu-bottom li:last-child a {
  padding-right: 0px;
}

.footer-1 {
  background-color: #F0F3F8;
  margin: 0px 0px 50px 0px;
  padding: 105px 0px 75px 0px;
}

.footer-2-top a {
  vertical-align: top;
  display: inline-block;
  padding-right: 10px;
}
.footer-2-top .font-xs {
  padding: 32px 12px 0px 12px;
}

.footer-2-bottom {
  display: flex;
  margin-bottom: 20px;
}
.footer-2-bottom .head-left-footer {
  min-width: 95px;
  vertical-align: top;
}
.footer-2-bottom .tags-footer {
  width: 100%;
  padding-top: 2px;
}
.footer-2-bottom .tags-footer a {
  color: #425A8B;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
  padding: 0px 30px 0px 0px;
  position: relative;
}
.footer-2-bottom .tags-footer a::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 15px;
  height: 16px;
  width: 1px;
  background-color: #8C9EC5;
}
.footer-2-bottom .tags-footer a:last-child {
  padding-right: 0px;
}
.footer-2-bottom .tags-footer a:last-child::before {
  display: none;
}

/*LAYOUT -> SPACING**/
.section-padding-30 {
  padding: 30px 0;
}

.section-padding-50 {
  padding: 50px 0;
}

.section-padding-60 {
  padding: 60px 0;
}

.section-padding {
  padding: 25px 0;
}

.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pt-260 {
  padding-top: 260px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.plr-5-percent {
  padding: 0 5%;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-155 {
  margin-top: 155px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mt-165 {
  margin-top: 165px !important;
}

.mt-170 {
  margin-top: 170px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mt-185 {
  margin-top: 185px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mt-195 {
  margin-top: 195px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-155 {
  margin-bottom: 155px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.mb-165 {
  margin-bottom: 165px !important;
}

.mb-170 {
  margin-bottom: 170px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.mb-185 {
  margin-bottom: 185px !important;
}

.mb-190 {
  margin-bottom: 190px !important;
}

.mb-195 {
  margin-bottom: 195px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.ml-125 {
  margin-left: 125px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.ml-135 {
  margin-left: 135px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.ml-145 {
  margin-left: 145px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.ml-155 {
  margin-left: 155px !important;
}

.ml-160 {
  margin-left: 160px !important;
}

.ml-165 {
  margin-left: 165px !important;
}

.ml-170 {
  margin-left: 170px !important;
}

.ml-175 {
  margin-left: 175px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.ml-185 {
  margin-left: 185px !important;
}

.ml-190 {
  margin-left: 190px !important;
}

.ml-195 {
  margin-left: 195px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-125 {
  margin-right: 125px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.mr-135 {
  margin-right: 135px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mr-145 {
  margin-right: 145px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-155 {
  margin-right: 155px !important;
}

.mr-160 {
  margin-right: 160px !important;
}

.mr-165 {
  margin-right: 165px !important;
}

.mr-170 {
  margin-right: 170px !important;
}

.mr-175 {
  margin-right: 175px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.mr-185 {
  margin-right: 185px !important;
}

.mr-190 {
  margin-right: 190px !important;
}

.mr-195 {
  margin-right: 195px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

.list-brands {
  display: flex;
  width: 100%;
  border: 1px solid #D5DFE4;
  padding: 16px;
  border-radius: 4px;
  margin: 10px 0px 0px 0px;
}
.list-brands a {
  display: inline-block;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 100%;
}
.list-brands a img {
  opacity: 0.3;
  max-height: 15px;
}
.list-brands a:hover img {
  opacity: 1;
}

.head-main {
  display: inline-block;
  width: 100%;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #D5DFE4;
  margin-bottom: 30px;
  position: relative;
}

.nav-tabs {
  border-bottom: 0px;
  padding-top: 40px;
  display: inline-block;
  text-align: right;
  width: 100%;
  vertical-align: bottom;
}
.nav-tabs li {
  display: inline-block;
  padding: 0px 10px 0px 10px;
}
.nav-tabs li a {
  display: inline-block;
  width: 100%;
  padding: 0px 10px;
  font-size: 16px;
  line-height: 24px;
  color: #8C9EC5;
}
.nav-tabs li a:hover {
  color: #425A8B;
  font-weight: 400;
}
.nav-tabs li a.active {
  color: #425A8B;
  font-weight: bold;
}
.nav-tabs li:last-child {
  padding-right: 0px;
}
.nav-tabs li:last-child a {
  padding-right: 0px;
}

.list-links-disc {
  display: inline-block;
  width: 100%;
  padding: 15px 0px 10px 0px;
}
.list-links-disc li {
  margin: 0px 0px 6px 0px;
  list-style: none;
  transition: 0.2s;
}
.list-links-disc li:hover {
  padding-left: 2px;
  transition: 0.2s;
}
.list-links-disc li a {
  display: block;
  padding: 0px 0px 0px 10px;
  background: url(../imgs/template/icons/arrow-small.svg) no-repeat left center;
  color: #6B83B6;
}
.list-links-disc li a:hover {
  color: #0BA9ED;
  background: url(../imgs/template/icons/arrow-small-hover.svg) no-repeat left center;
}

.image-gallery {
  display: table;
  table-layout: fixed;
  margin: 0px -10px 20px -10px;
  width: calc(100% + 20px);
}
.image-gallery .image-big {
  float: left;
  width: 62.5%;
  padding: 0px 10px;
}
.image-gallery .image-small {
  float: left;
  width: 37.5%;
  padding: 0px 10px;
}

.banner-2 {
  background-color: #DFE7FD;
  padding: 40px 30px;
  height: 470px;
  margin-bottom: 25px;
}

.bg-xbox {
  background-image: url(../imgs/page/homepage1/xbox.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 260px;
  border-radius: 4px;
}

.banner-img-left {
  background-color: #D1ECFD;
  padding: 20px 20px 0px 220px;
  height: 225px;
}

.bg-controller {
  background-image: url(../imgs/page/homepage1/controller.png);
  background-repeat: no-repeat;
  background-position: bottom left 10px;
  background-size: auto 80%;
  border-radius: 4px;
}

.bg-metaverse {
  background-color: #FFF3EA;
  padding: 50px 30px 0px 30px;
  height: 225px;
  background-image: url(../imgs/page/homepage1/metaverse.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: auto 80%;
  border-radius: 4px;
}

.bg-electronic {
  background-color: #E8FCFF;
  padding: 30px 30px 0px 30px;
  text-align: center;
  height: 225px;
  background-image: url(../imgs/page/homepage1/electronic.png);
  background-repeat: no-repeat;
  background-position: bottom 5px center;
  background-size: auto 45%;
  border-radius: 4px;
}

.bg-phone {
  background-color: #DDD3FA;
  padding: 30px 200px 30px 30px;
  height: 225px;
  background-image: url(../imgs/page/homepage1/phone.png);
  background-repeat: no-repeat;
  background-position: bottom right 10px;
  background-size: auto 75%;
  border-radius: 4px;
}

.select2-dropdown {
  border: 1px solid #ececec;
  border-radius: 0 0 4px 4px;
  padding: 15px;
  min-width: 220px;
  top: 8px;
}

.block-drone {
  background-image: url(../imgs/page/homepage1/drone.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 25px 200px 25px 35px;
  background-size: auto 150px;
  height: 250px;
}

.block-samsung {
  background-image: url(../imgs/page/homepage1/samsung.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 25px 200px 25px 35px;
  background-size: auto 220px;
  height: 250px;
}

.block-iphone {
  background-image: url(../imgs/page/homepage1/iphone-12.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 25px 35px 25px 35px;
  background-size: auto 160px;
  height: 250px;
}

.label-green {
  background-color: #5BC694;
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.list-disc {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.btn.btn-gray-1000 {
  background-color: #0E224D;
  color: #ffffff;
}
.btn.btn-link-white {
  text-decoration: underline;
  color: #ffffff;
}

.lh-65 {
  line-height: 65px;
}

.saleoff {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 93px;
  width: 106px;
  background: url(../imgs/page/homepage2/saleoff.svg) no-repeat 0px 0px;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  padding: 25px 0px 0px 15px;
  border-radius: 8px 0 0 0;
}

.list-none-border {
  padding: 15px 0px;
  border: 0px !important;
  margin: -10px 0px 0px 0px;
}
.list-none-border.list-brands a img {
  opacity: 0.3;
  max-height: 20px;
  width: 100%;
  transition: 0.2s;
}
.list-none-border.list-brands a:hover img {
  opacity: 0.8;
  transition: 0.2s;
}

.list-9-col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.list-9-col li {
  flex: 0 0 auto;
  width: 11.11%;
  padding: 0px 10px;
  text-align: center;
  margin-bottom: 20px;
}
.list-9-col li .box-category {
  border-radius: 4px;
  border: 1px solid #D5DFE4;
  padding: 5px 5px 10px 5px;
}
.list-9-col li .box-category:hover {
  border: 1px solid #8C9EC5;
}
.list-9-col li .box-category .image {
  height: 80px;
  line-height: 80px;
  margin-bottom: 5px;
}
.list-9-col li .box-category .image img {
  max-height: 90%;
  vertical-align: middle;
}
.list-9-col li .box-category .text-info a {
  line-height: 18px;
  display: block;
}

.font-24 {
  font-size: 24px;
  line-height: 24px;
}

.lbl-new-arrival {
  background-color: #A4EEE1;
  border-radius: 4px;
  display: inline-block;
  padding: 4px 7px;
}

.box-bdrd-4 {
  border-radius: 4px;
  padding: 30px 20px;
}

.bg-headphone {
  background-image: url(../imgs/page/homepage2/headphone.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 85%;
  padding-bottom: 100px;
  min-height: 315px;
}

.bg-watch {
  background-image: url(../imgs/page/homepage2/watch.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 58%;
  padding-bottom: 100px;
  min-height: 315px;
}

.bg-ipad {
  background-image: url(../imgs/page/homepage2/ipad.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 80% auto;
  padding-bottom: 100px;
  min-height: 315px;
}

.box-slider-item .head {
  border-bottom: 1px solid #DDE4F0;
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.swiper-button-prev-style-2, .swiper-button-prev-style-2:hover {
  top: -62px;
  right: 20px;
  width: 17px;
  background: url(../imgs/page/homepage2/prev.svg) no-repeat top right;
}

.swiper-button-next-style-2, .swiper-button-next-style-2:hover {
  top: -62px;
  width: 17px;
  background: url(../imgs/page/homepage2/next.svg) no-repeat top right;
}

.text-underline {
  text-decoration: underline;
}

.box-slide-bg-1 {
  background: url(../imgs/page/homepage3/banner.png) no-repeat bottom right;
  background-size: 70% auto;
  height: 100%;
  display: inline-block;
  width: 100%;
}

.block-water {
  background-image: url(../imgs/page/homepage3/water.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 55% auto;
  padding-bottom: 40px;
  min-height: 240px;
}

.block-kitchen {
  background-image: url(../imgs/page/homepage3/kitchen.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 85% auto;
  padding-bottom: 40px;
  min-height: 240px;
}

.block-electric {
  background-image: url(../imgs/page/homepage3/electric.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 85% auto;
  padding-bottom: 40px;
  min-height: 240px;
}

.box-icon-flash {
  padding: 0px 0px 0px 55px;
  background-image: url(../imgs/page/homepage3/flash.svg);
  background-repeat: no-repeat;
  background-position: left -13px top 5px;
}

.box-all-hurry-round {
  background-color: #ffffff;
  border-radius: 50px;
  padding: 10px 40px 10px 30px;
  bottom: 0px;
}
.box-all-hurry-round .box-count.box-count-square .deals-countdown {
  background-color: transparent;
}
.box-all-hurry-round .box-count.hide-period {
  bottom: -1px;
}
.box-all-hurry-round .box-text-hurryup {
  padding: 2px 0px 2px 50px;
  background-image: url(../imgs/page/homepage3/clock.svg);
  background-repeat: no-repeat;
  background-position: left 0px top 1px;
}
.box-all-hurry-round .box-count.box-count-square .deals-countdown .countdown-amount {
  margin-bottom: 0px;
}
.box-all-hurry-round .box-count.box-count-square .deals-countdown .countdown-amount {
  width: 35px;
}

.banner-ads {
  background: url(../imgs/page/homepage3/bg-ads.png) no-repeat 0px 0px;
  background-size: cover;
  padding: 50px 10px;
  position: relative;
}
.banner-ads::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  width: 400px;
  background: url(../imgs/page/homepage3/bg-left-ads.png) no-repeat bottom right;
  background-size: contain;
}
.banner-ads::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 350px;
  background: url(../imgs/page/homepage3/bg-right-ads.png) no-repeat bottom left;
  background-size: contain;
}

.card-ads-1 .card-grid-inner {
  background: url(../imgs/page/homepage3/bg-kitchen.png) no-repeat 0px 0px;
  padding: 20px 0px 0px 0px;
  border-radius: 4px;
  overflow: hidden;
  background-size: cover;
}
.card-ads-1 .card-grid-inner .image-box {
  margin-bottom: 0px;
  height: auto;
  line-height: 100%;
  margin-top: 30px;
  overflow: visible;
}
.card-ads-1 .card-grid-inner .image-box img {
  max-width: 100%;
}

.card-ads-2 .card-grid-inner {
  background: url(../imgs/page/homepage3/bg-kitchen2.png) no-repeat bottom right;
  background-size: cover;
  padding: 20px 0px 0px 0px;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
}
.card-ads-2 .card-grid-inner .image-box {
  margin-bottom: 0px;
  height: auto;
  line-height: 100%;
  margin-top: 15px;
  overflow: visible;
  text-align: left;
}
.card-ads-2 .card-grid-inner .image-box img {
  max-width: 100%;
  max-height: 260px;
}
.card-ads-2 .box-count.box-count-square .deals-countdown {
  background-color: transparent;
  border-radius: 0px;
}
.card-ads-2 .box-count.box-count-square .deals-countdown .countdown-amount {
  width: 35px;
  background-color: #425A8B;
}
.card-ads-2 .box-count.box-count-square .deals-countdown .countdown-section {
  margin-left: 0px;
  margin-right: 14px;
}
.card-ads-2 .box-count.hide-period .deals-countdown {
  padding-left: 0px;
}

.block-ads-1 {
  background: url(../imgs/page/homepage3/bg-ads-2.png) no-repeat 0px 0px;
  padding: 50px 40px;
}

.block-ads-2 {
  background: url(../imgs/page/homepage3/bg-ads-3.png) no-repeat 0px 0px;
  padding: 50px 40px;
}

.box-button-slider-normal {
  text-align: right;
}
.box-button-slider-normal .swiper-button-next, .box-button-slider-normal .swiper-button-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  display: inline-block;
}

.box-sidebar {
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.box-sidebar .head {
  background-color: #FD9636;
  padding: 15px 20px;
  margin-bottom: 10px;
}
.box-sidebar .head h4 {
  color: #ffffff;
}

.box-button-control {
  text-align: right;
  float: right;
  padding-top: 6px;
}
.box-button-control .swiper-button-next-style-2 {
  display: inline-block;
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  width: 17px;
  height: 17px;
  background: url(../imgs/page/homepage4/arrow-next-white.svg) no-repeat center right;
}
.box-button-control .swiper-button-prev-style-2 {
  top: auto;
  left: auto;
  right: auto;
  width: 17px;
  height: 17px;
  display: inline-block;
  position: relative;
  background: url(../imgs/page/homepage4/arrow-prev-white.svg) no-repeat center right;
}

.box-content .card-grid-style-3 .card-grid-inner .image-box {
  height: 133px;
  line-height: 135px;
}

.block-charge {
  background-image: url(../imgs/page/homepage4/quick-charge.png);
  background-repeat: no-repeat;
  background-position: top right;
  padding: 25px 200px 25px 35px;
  background-size: auto 100%;
  height: 250px;
}

.block-player {
  background-image: url(../imgs/page/homepage4/player.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 25px 200px 25px 35px;
  background-size: auto 90%;
  height: 250px;
}

.text-shadow {
  text-shadow: -2px -2px 0px #FFF500, 2px 2px 0px #FF6BC4;
}

.h-175 {
  height: 175px;
}

.mh-307 {
  min-height: 307px;
}

.box-promotions {
  border: 3px solid #FD9636;
  padding: 10px;
  height: 501px;
  overflow: hidden;
  border-radius: 4px;
}
.box-promotions img {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.dropdown .menu-texts.menu-close {
  min-width: 320px;
}
.dropdown .dropdown-menu {
  width: auto;
}
.dropdown .menu-texts.menu-close > li.has-children > a {
  background: url(../imgs/template/icons/arrow-gray.svg) no-repeat right 20px center;
}
.dropdown .sidebar-left {
  top: auto;
  height: auto;
  padding: 15px 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-color: #DDE4F0;
}
.dropdown .sidebar-left::before {
  content: "";
  height: auto;
  width: 1px;
  background-color: #DDE4F0;
  z-index: 12;
  right: -1px;
  top: 25px;
  bottom: 25px;
  position: absolute;
}
.dropdown .sidebar-left .menu-texts {
  position: relative;
  top: auto;
  left: auto;
}
.dropdown .sidebar-left .menu-texts li a {
  line-height: 45px;
}
.dropdown .sidebar-left .menu-texts li a .text-link {
  display: inline;
}
.dropdown .sidebar-left .menu-texts li a .img-link {
  border-right: 0px;
  height: 45px;
}
.dropdown .sidebar-left .menu-texts li a:hover .img-link::before {
  display: none;
}
.dropdown .sidebar-left .menu-texts li a.active .img-link::before {
  display: none;
}
.dropdown .sidebar-left .menu-texts li .sub-menu {
  bottom: -16px;
  top: -16px;
  padding: 15px 10px;
  border: 1px solid #DDE4F0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0px;
}
.dropdown .sidebar-left .menu-texts li .sub-menu li a:hover {
  color: #FD9636;
  font-weight: bold;
}

.dropdown .sidebar-left .menu-texts {
  border: 0px;
}

.lbl-newarrival {
  border-radius: 4px;
  background-color: #5BC694;
  display: inline-block;
  padding: 5px 10px;
}

.banner-slide {
  position: relative;
  background-size: cover !important;
  min-height: 550px;
}

.banner-desc {
  position: absolute;
  max-width: 767px;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
}

.list-no-border {
  position: relative;
}
.list-no-border li .item-list {
  border: 0px;
}
.list-no-border li .item-list:hover {
  border: 0px;
}

.border-none {
  border: 0px !important;
}

.ads1 {
  max-width: 914px;
  width: 100%;
}

.list-9-col li .box-category {
  padding: 5px 3px 10px 3px;
}

.list-style-brand-2 .btn-cart:hover {
  background-color: #FD9636;
  border-color: #FD9636;
}
.list-style-brand-2 .card-grid-style-3:hover .card-grid-inner {
  border-color: #FD9636;
}
.list-style-brand-2 .card-grid-style-3:hover a.color-gray-500, .list-style-brand-2 .card-grid-style-3:hover .rating span {
  color: #FD9636;
}

.card-hide-cart .box-btn-cart {
  display: none;
}

.box-newsletter.bg-brand-2 {
  background-image: url(../imgs/page/homepage5/bg-newsletter.png);
  background-position: left center;
  background-repeat: no-repeat;
}
.box-newsletter.bg-brand-2 .color-warning {
  color: #425A8B;
}
.box-newsletter.bg-brand-2 .box-form-newsletter .btn-brand-3 {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 50px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.bg-footer-homepage5 .footer-1 {
  background-color: #FFF5DB;
  margin-bottom: 0px;
}
.bg-footer-homepage5 .footer-2 {
  padding-top: 50px;
  background-color: #F0F3F8;
}

.sidebar-border .head {
  border-bottom: 0px;
  margin-bottom: 0px;
}
.sidebar-border .content-slider {
  border: 1px solid #FD9636;
  border-top: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 5px;
}
.sidebar-border .card-grid-style-2 .info-right .font-xs-bold {
  font-size: 14px;
  font-weight: 500;
}
.sidebar-border .card-grid-style-2 .info-right .price-info {
  margin-top: 5px;
}

.banner-home5 .swiper-pagination-custom {
  padding-left: 0px;
  text-align: center;
}

.banner-left {
  position: relative;
  width: 100%;
  height: 535px;
  background: url(../imgs/page/homepage6/banner1.png) no-repeat top center;
  background-size: cover;
  margin-bottom: 30px;
}

.banner-middle {
  position: relative;
  width: 100%;
  height: 535px;
  background-size: cover;
}

.banner-right-home6 {
  position: relative;
  width: 100%;
  height: 535px;
  background: url(../imgs/page/homepage6/banner3.png) no-repeat top center;
  background-size: cover;
  margin-bottom: 30px;
}

.box-adsolute-banner {
  position: absolute;
  bottom: 10px;
  left: 10px;
  min-width: 220px;
  padding: 15px;
  background-color: #ffffff;
}
.box-adsolute-banner .btn-link-brand-2 {
  text-decoration: underline;
  padding: 0px 0px;
  font-weight: 400;
  line-height: 18px;
}

.banner-middle .box-adsolute-banner {
  top: 20%;
  left: 40px;
  background-color: transparent;
}

.list-categories-5 {
  display: flex;
  margin: 0px -10px;
  flex-wrap: wrap;
}
.list-categories-5 li {
  padding: 0px 10px;
  flex: 0 0 auto;
  width: 20%;
}

.swiper-button-prev-style-4 {
  left: 40px;
  bottom: 10px;
  top: auto;
  background: url(../imgs/page/homepage6/prev.svg) no-repeat center;
  height: 56px;
  width: 56px;
}
.swiper-button-prev-style-4:hover {
  background: url(../imgs/page/homepage6/prev.svg) no-repeat center;
}

.swiper-button-next-style-4 {
  left: 98px;
  bottom: 10px;
  top: auto;
  background: url(../imgs/page/homepage6/next.svg) no-repeat center;
  height: 56px;
  width: 56px;
}
.swiper-button-next-style-4:hover {
  background: url(../imgs/page/homepage6/next.svg) no-repeat center;
}

.sale-tag {
  background-color: #FD9636;
  padding: 4px;
  color: #ffffff;
  display: inline-block;
}

.home6-style .card-grid-inner {
  padding: 0px;
}
.home6-style .card-grid-inner .image-box {
  background-color: #f7f7f7;
  padding: 20px;
  line-height: 218px;
  height: 250px;
}
.home6-style .card-grid-inner .image-box .label {
  top: 15px;
  left: 15px;
}
.home6-style .card-grid-inner .info-right {
  padding: 0px 20px 20px 20px;
}
.home6-style .price-info {
  display: block;
}
.home6-style .box-btn-cart {
  display: none;
}
.home6-style:hover .price-info {
  display: none;
}
.home6-style:hover .box-btn-cart {
  display: block;
}

.banner-bottom-1 {
  width: 100%;
  height: 265px;
  background: url(../imgs/page/homepage6/reading-table.png) no-repeat center;
  margin-bottom: 30px;
  display: inline-block;
  background-size: cover;
  position: relative;
}

.banner-bottom-2 {
  width: 100%;
  height: 265px;
  background: url(../imgs/page/homepage6/table-lamp.png) no-repeat center;
  margin-bottom: 30px;
  display: inline-block;
  background-size: cover;
  position: relative;
}

.banner-bottom-3 {
  width: 100%;
  height: 566px;
  background: url(../imgs/page/homepage6/top-rate.png) no-repeat center;
  margin-bottom: 30px;
  display: inline-block;
  background-size: cover;
  position: relative;
}

.banner-bottom-4 {
  width: 100%;
  height: 265px;
  background: url(../imgs/page/homepage6/sofa.png) no-repeat center;
  margin-bottom: 30px;
  display: inline-block;
  background-size: cover;
  position: relative;
}

.banner-bottom-5 {
  width: 100%;
  height: 265px;
  background: url(../imgs/page/homepage6/relax.png) no-repeat center;
  margin-bottom: 30px;
  display: inline-block;
  background-size: cover;
  position: relative;
}

.top-style-1 {
  position: absolute;
  top: 35%;
  left: 40px;
}

.top-style-2 {
  position: absolute;
  top: 20%;
  left: 40px;
}

.banner-ads-3 {
  background-color: #DBECE5;
  border-radius: 4px;
  padding: 25px 35px;
  background-image: url(../imgs/page/homepage6/chair.png);
  background-repeat: no-repeat;
  background-position: bottom right 5px;
  max-width: 886px;
  margin: auto;
}

.header-brand-2 {
  border-top: 0px;
}
.header-brand-2 .box-header-bottom {
  display: flex;
  width: 100%;
  background-color: #FD9636;
  border-radius: 4px;
  border-bottom: 2px solid #D36B0B;
  padding: 10px 20px 10px 0px;
}
.header-brand-2 .box-header-bottom .main-menu li a {
  color: #ffffff !important;
}
.header-brand-2 .box-header-bottom .main-menu li a:hover {
  color: #3AA1FF !important;
}
.header-brand-2 .box-header-bottom .main-menu li ul li a {
  color: #425A8B !important;
}
.header-brand-2 .box-header-bottom .header-nav .main-menu li.has-children > a::after {
  background: url(../imgs/page/homepage7/arrow-down.svg) no-repeat 0px 0px;
}
.header-brand-2 .box-header-bottom .btn-category {
  position: relative;
}
.header-brand-2 .box-header-bottom .btn-category:before {
  content: "";
  height: 20px;
  width: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 11px;
  right: -2px;
  opacity: 0.5;
}

.border-none {
  border: 0px !important;
}

.banner-right-7 {
  max-height: 500px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.banner-big-7 {
  max-height: 500px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.banner-big-7 img {
  width: 100%;
}

.swiper-home7 .swiper-pagination {
  text-align: left;
  padding-left: 0px;
  padding-right: 30px;
  left: 30px;
  bottom: 20px;
}

.swiper-pagination-3.swiper-pagination-custom {
  bottom: 0px;
  padding-left: 0px;
}

.swiper-group-9 .swiper-pagination-3.swiper-pagination .swiper-pagination-customs {
  background-color: #425A8B;
}
.swiper-group-9 .swiper-pagination-3.swiper-pagination .swiper-pagination-customs.swiper-pagination-customs-active {
  background-color: #FD9636;
}

.list-brand-2 .box-count .deals-countdown {
  background-color: #FD9636;
}
.list-brand-2 .card-grid-style-3 .card-grid-inner .info-right a.btn-cart:hover {
  background-color: #FD9636;
  border-color: #FD9636;
}

.box-menu-category {
  border-radius: 4px;
  background-color: #F0F3F8;
  padding: 21px 30px 19px 30px;
}
.box-menu-category .list-nav-arrow li {
  margin-bottom: 13px;
}

.block-sale-1 {
  background-color: #D1ECFD;
  border-radius: 4px;
  padding: 25px 15px 25px 25px;
}
.block-sale-1 h3 {
  font-size: 28px;
  line-height: 30px;
  margin-top: 10px;
  color: #0E224D;
}
.block-sale-1 .col-lg-4 {
  line-height: 130px;
}
.block-sale-1 .col-lg-4 img {
  max-height: 130px;
  display: inline-block;
  vertical-align: middle;
}

.box-ads-1 {
  margin: 0px -10px;
  display: flex;
  flex-wrap: wrap;
}
.box-ads-1 .ads-1 {
  width: 40%;
  padding: 0px 10px;
}
.box-ads-1 .ads-2 {
  width: 40%;
  padding: 0px 10px;
}
.box-ads-1 .ads-3 {
  width: 20%;
  padding: 0px 10px;
}

.box-ads-2 {
  max-width: 1100px;
  position: relative;
  width: 100%;
  padding: 0px 0px;
  text-align: center;
  margin: auto;
}
.box-ads-2 * {
  position: relative;
  z-index: 3;
}
.box-ads-2 img {
  z-index: 1;
  display: block;
}
.box-ads-2 .ads-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  z-index: 4;
}
.box-ads-2::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: url(../imgs/page/homepage7/bg-trans.png);
}

.home7-style .card-grid-inner .image-box {
  background-color: #ffffff;
}

.mw-newsletter {
  max-width: 500px;
  width: 100%;
}

.bg-newsletter-home7 {
  position: relative;
  background-image: none;
}
.bg-newsletter-home7 * {
  position: relative;
  z-index: 2;
}
.bg-newsletter-home7::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  background: url(../imgs/page/homepage7/newsletter-left.png) no-repeat bottom left;
  background-size: auto;
  z-index: 1;
}
.bg-newsletter-home7::after {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  background: url(../imgs/page/homepage7/newsletter-right.png) no-repeat bottom right;
  background-size: auto;
  z-index: 1;
}

.banner-8 .swiper-pagination-custom {
  bottom: 20px;
  padding-left: 50px;
}
.banner-8 .box-banner-big h1 {
  line-height: 63px;
}

.height-100 {
  height: 100% !important;
}

.block-sale-2 {
  background-color: #D1ECFD;
  border-radius: 4px;
  padding: 20px 20px 0px 20px;
  position: relative;
  overflow: hidden;
  height: 220px;
}
.block-sale-2 img {
  display: block;
}
.block-sale-2 .box-sale h4 {
  line-height: 26px;
}
.block-sale-2.circle-1 * {
  position: relative;
  z-index: 2;
}
.block-sale-2.circle-1::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  background: url(../imgs/page/homepage8/bg-sale1.png) no-repeat bottom right;
  z-index: 1;
}
.block-sale-2.circle-2 * {
  position: relative;
  z-index: 2;
}
.block-sale-2.circle-2::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  background: url(../imgs/page/homepage8/bg-sale2.png) no-repeat bottom right;
  z-index: 1;
}
.block-sale-2.circle-3 * {
  position: relative;
  z-index: 2;
}
.block-sale-2.circle-3::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  background: url(../imgs/page/homepage8/bg-sale3.png) no-repeat bottom right;
  z-index: 1;
}

.block-sale-3 {
  background-color: #BAD0E7;
  border-radius: 4px;
  padding: 20px 20px 0px 20px;
  position: relative;
  overflow: hidden;
  height: 260px;
}

.style-label-right .card-grid-style-2 > .label {
  left: auto;
  right: 15px;
  position: absolute;
  top: 10px;
  z-index: 2;
  border-radius: 30px;
  padding: 4px 10px;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  background-color: #FD9636;
}

.pick-by-ecom .top-deals .card-grid-inner .image-box .swiper-container {
  padding-bottom: 20px;
}

.pick-by-ecom {
  max-width: 1100px;
  margin: auto;
  position: relative;
}

.swiper-pagination-3 {
  text-align: center;
  bottom: -10px;
}
.swiper-pagination-3.swiper-pagination .swiper-pagination-customs {
  background-color: #ffffff;
}
.swiper-pagination-3.swiper-pagination .swiper-pagination-customs-active {
  background-color: #FD9636;
}

.swiper-button-next-ecom {
  position: absolute;
  top: 50%;
  right: -75px;
  margin-top: -28px;
  background: url(../imgs/page/homepage8/next.svg) no-repeat center;
  height: 56px;
  width: 56px;
}
.swiper-button-next-ecom:hover {
  background: url(../imgs/page/homepage8/next.svg) no-repeat center;
}

.swiper-button-prev-ecom {
  position: absolute;
  top: 50%;
  margin-top: -28px;
  left: -75px;
  background: url(../imgs/page/homepage8/prev.svg) no-repeat center;
  height: 56px;
  width: 56px;
}
.swiper-button-prev-ecom:hover {
  background: url(../imgs/page/homepage8/prev.svg) no-repeat center;
}

.box-ads-3 {
  overflow: hidden;
  min-height: 150px;
  max-height: 170px;
}
.box-ads-3::before {
  background: url(../imgs/page/homepage8/bg-trans.png);
}
.box-ads-3 img {
  height: 100%;
  max-width: none;
}

.bg-newsletter-home8 {
  background-image: url(../imgs/page/homepage8/bg-newsletter.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-bottom: 200px;
  padding-top: 60px;
}

.notify-text {
  display: inline;
  padding: 0px 0px 0px 20px;
  background: url(../imgs/page/homepage9/check.svg) no-repeat left center;
  display: inline-block;
}

.box-notify {
  background-color: #FD9636;
  padding: 10px 0px;
  position: relative;
  z-index: 1003;
}

.banner-home9 .swiper-pagination-custom {
  padding-left: 30px;
  bottom: 25px;
}
.banner-home9 .banner-big-home9 {
  border-radius: 4px;
  padding: 20px 30px 40px 30px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 300px;
}
.banner-home9 .banner-big-home9 .info-banner {
  position: relative;
  z-index: 2;
  width: 100%;
}
.banner-home9 .banner-big-home9 .box-img-banner {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}
.banner-home9 .banner-big-home9 .box-img-banner img {
  max-width: 400px;
}
.banner-home9 .banner-small {
  border-radius: 4px;
  padding: 25px 25px 0px 25px;
  min-height: 300px;
}
.banner-home9 .banner-small img {
  max-width: 90%;
}

.box-slider-product {
  border: 1px solid #D5DFE4;
  padding: 10px 20px 15px 20px;
  border-radius: 4px;
  margin-bottom: 30px;
}
.box-slider-product .head-slider {
  padding-bottom: 15px;
}
.box-slider-product .box-button-slider-2 {
  position: relative;
  text-align: right;
}
.box-slider-product .box-button-slider-2 .swiper-button-next-style-top {
  position: relative;
  right: auto;
  top: auto;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-size: cover;
}
.box-slider-product .box-button-slider-2 .swiper-button-prev-style-top {
  top: auto;
  position: relative;
  right: auto;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-size: cover;
}

.title-border-bottom {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid #D5DFE4;
  margin-bottom: 15px;
}
.title-border-bottom::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0px;
  height: 2px;
  width: 100px;
  background-color: #8C9EC5;
}

.bg-home9 {
  background-color: #F8FAFF;
}

.box-product-category {
  border-radius: 4px;
  padding: 20px 20px 0px 20px;
  display: inline-block;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #D5DFE4;
}
.box-product-category .box-menu-category {
  padding: 0px 20px 0px 0px;
}

.box-category-left {
  width: 25%;
  min-width: 270px;
  max-width: 270px;
}

.box-category-right {
  width: 100%;
}

.box-quickmenu {
  background-color: #F0F3F8;
  padding: 10px 0px;
  border-top: 0px solid #D5DFE4;
  border-bottom: 1px solid #D5DFE4;
}
.box-quickmenu .box-inner-quickmenu {
  max-width: 90%;
  margin: auto;
}
.box-quickmenu .box-inner-quickmenu ul li {
  display: inline-block;
  position: relative;
  padding: 0px 30px 0px 0px;
}
.box-quickmenu .box-inner-quickmenu ul li a {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #425A8B;
}
.box-quickmenu .box-inner-quickmenu ul li a:hover {
  color: #0BA9ED;
}
.box-quickmenu .box-inner-quickmenu ul li::before {
  content: "|";
  position: absolute;
  top: -1px;
  right: 15px;
  color: #425A8B;
  height: 20px;
  width: 1px;
}
.box-quickmenu .box-inner-quickmenu ul li:last-child::before {
  display: none;
}

.list-categories-style-1 {
  display: flex;
  width: 100%;
  margin: 0px -10px;
}

.item-cat {
  padding: 0px 10px;
  width: 100%;
  text-align: center;
}
.item-cat .box-image {
  margin-bottom: 10px;
}
.item-cat .box-image .inner-image {
  max-height: 134px;
  width: 100%;
  line-height: 114px;
  max-width: 134px;
  border-radius: 50%;
  background: url(../imgs/page/homepage10/circle.png) no-repeat center;
  background-size: contain;
  display: inline-block;
  min-height: 114px;
}
.item-cat .box-image .inner-image img {
  max-width: 90%;
  vertical-align: middle;
  display: inline-block;
}

.banner-home10 .swiper-pagination-custom {
  padding: 0px;
  text-align: center;
  bottom: 30px;
}
.banner-home10 .banner-big-home10 {
  display: flex;
  width: 100%;
  border-radius: 4px;
  padding: 20px 20px 70px 40px;
  min-height: 400px;
  position: relative;
  align-items: center;
}
.banner-home10 .banner-big-home10 .info-banner {
  width: 100%;
  position: relative;
  z-index: 2;
}
.banner-home10 .banner-big-home10 .info-banner .box-count-square {
  max-width: 250px;
  margin-left: -7px;
  margin-top: 15px;
}
.banner-home10 .banner-big-home10 .info-banner .box-count-square .deals-countdown {
  padding: 0px;
  text-align: left;
  border-radius: 0px;
  background-color: transparent;
}
.banner-home10 .banner-big-home10 .box-img-banner {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}
.banner-home10 .banner-big-home10 .box-img-banner img {
  max-width: 270px;
}
.banner-home10 .banner-big-home10 .box-img-banner-2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.banner-home10 .banner-big-home10 .box-img-banner-2 img {
  max-width: 370px;
}

.list-products-home10 .home7-style .image-box {
  background-color: #DBECE5;
}

.banner-small-home10 {
  position: relative;
  border-radius: 4px;
  padding: 50px 40px;
  min-height: 410px;
}
.banner-small-home10 * {
  position: relative;
  z-index: 2;
}
.banner-small-home10 h1 {
  line-height: 63px;
}
.banner-small-home10 .box-img-banner {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  height: 100%;
  align-items: end;
  display: flex;
}
.banner-small-home10 .box-img-banner img.img1 {
  max-width: 230px;
  max-height: 100%;
}
.banner-small-home10 .box-img-banner img.img2 {
  max-width: 100%;
  max-height: 100%;
}
.banner-small-home10 .box-img-banner img.img3 {
  max-width: 210px;
  max-height: 100%;
}

.box-newsletter-home10 {
  position: relative;
  background-image: none;
}
.box-newsletter-home10 * {
  position: relative;
  z-index: 2;
}
.box-newsletter-home10::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: url(../imgs/page/homepage10/bg-newsletter1.png) no-repeat bottom left;
  background-size: contain;
}
.box-newsletter-home10::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: url(../imgs/page/homepage10/bg-newsletter2.png) no-repeat bottom right;
  background-size: contain;
}

.box-ads-home10 {
  max-width: 800px;
  margin: auto;
  position: relative;
  overflow: hidden;
}
.box-ads-home10 img {
  max-width: 100%;
  height: auto;
}
.box-ads-home10 .info-ads-home10 {
  padding: 0px 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

@media (min-width: 768px) {
  .display-list .card-grid-style-3 .card-grid-inner {
    display: flex;
  }
  .display-list .card-grid-style-3 .card-grid-inner .image-box {
    width: 25%;
    min-width: 260px;
    max-width: 260px;
    margin-bottom: 40px;
  }
  .display-list .card-grid-style-3 .card-grid-inner .info-right {
    width: 100%;
  }
  .display-list .card-grid-style-3 .card-grid-inner .info-right .btn-cart {
    max-width: 260px;
  }
  .display-list .card-grid-style-3 .card-grid-inner .tools {
    bottom: 10px;
    left: 10px;
    top: auto;
    right: auto;
    width: 100%;
    max-width: 260px;
  }
  .display-list .card-grid-style-3 .card-grid-inner .tools .btn {
    margin-bottom: 0px !important;
    margin-right: 10px;
  }
}
.display-list .btn-tooltip {
  position: relative;
}
.display-list .btn-tooltip:after {
  color: #425A8B;
  background-color: #D5DFE4;
  top: -35px;
  left: 0;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-size: 11px;
  padding: 7px 10px;
  content: attr(aria-label);
  line-height: 1.3;
  box-shadow: 0;
  transition: 0;
  opacity: 0;
  visibility: hidden;
  transform: 0;
  transition-duration: 0.2s;
  bottom: unset;
  right: unset;
}
.display-list .btn-tooltip:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  border: 7px solid transparent;
  border-top-color: #D5DFE4;
  z-index: 9;
  margin-bottom: 0;
  transition: 0;
  opacity: 0;
  visibility: hidden;
  margin-left: -8px;
}
.display-list .btn-tooltip:hover:after {
  opacity: 1;
  visibility: visible;
  transform: 0;
  transition-duration: 0.2s;
}
.display-list .btn-tooltip:hover:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
}

.gallery-image {
  display: flex;
  width: 100%;
}
.gallery-image .image-left {
  min-width: 129px;
  max-width: 129px;
  width: 25%;
  padding-right: 15px;
}
.gallery-image .image-left ul {
  display: flex;
  flex-wrap: wrap;
}
.gallery-image .image-left ul li {
  border: 4px solid #DDE4F0;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
}
.gallery-image .image-left ul li:hover, .gallery-image .image-left ul li.active {
  background-color: #f5f5f5;
  border-color: #8C9EC5;
}
.gallery-image .image-left ul li:last-child {
  margin-bottom: 0px;
}
.gallery-image .image-left ul li img {
  max-height: 90%;
  height: 90%;
  max-width: 100%;
  width: auto;
  display: block;
  margin: auto;
}
.gallery-image .image-main {
  width: 100%;
  border: 4px solid #DDE4F0;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
}
.gallery-image .image-main img {
  width: 100%;
}

.box-quantity {
  display: flex;
  width: 100%;
}
.box-quantity .input-quantity {
  min-width: 99px;
  max-width: 99px;
  margin-right: 20px;
  position: relative;
}
.box-quantity .input-quantity input {
  border: 0px;
  border-bottom: 4px solid #DDE4F0;
  width: 100%;
  height: 36px;
  text-align: center;
}
.box-quantity .input-quantity .minus-cart {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 36px;
  width: 18px;
  cursor: pointer;
  background: url(../imgs/page/product/minus.svg) no-repeat 0px 7px;
}
.box-quantity .input-quantity .plus-cart {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 36px;
  width: 18px;
  cursor: pointer;
  background: url(../imgs/page/product/plus.svg) no-repeat 0px 7px;
}
.box-quantity .button-buy {
  width: 100%;
  display: flex;
}
.box-quantity .button-buy .btn {
  max-width: 200px;
  margin-right: 15px;
}
.box-quantity .button-buy-full .btn {
  max-width: 100%;
  margin-right: 0px;
}

.lbcb {
  display: block;
}
.lbcb input {
  float: left;
  margin: 3px 10px 0px 0px;
}

.line-througt {
  text-decoration: line-through;
}

.box-bought-together {
  display: flex;
  margin-bottom: 30px;
}
.box-bought-together .box-product-bought {
  display: flex;
  width: 100%;
}
.box-bought-together .box-product-bought .product-bought {
  width: 50%;
  border: 4px solid #DDE4F0;
  border-radius: 4px;
  padding: 20px;
  min-width: 216px;
  max-width: 216px;
  margin-right: 50px;
  position: relative;
}
.box-bought-together .box-product-bought .product-bought::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -49px;
  height: 40px;
  width: 40px;
  background: url(../imgs/page/product/plus-brand-3.svg) no-repeat center;
  margin-top: -20px;
}
.box-bought-together .box-product-bought .product-bought:first-child::before {
  display: none;
}
.box-bought-together .box-product-bought .product-bought:last-child {
  margin-right: 0px;
}
.box-bought-together .price-bought {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 30px;
}
.box-bought-together .price-bought .box-btn-add-cart {
  width: 100%;
  margin-top: 20px;
}
.box-bought-together .price-bought .box-btn-add-cart a {
  max-width: 216px;
}

.nav-tabs-product {
  text-align: left;
  margin-bottom: 10px;
}
.nav-tabs-product li {
  padding: 0px 30px 10px 0px;
}
.nav-tabs-product li a {
  padding: 0px;
  font-size: 24px;
  line-height: 31px;
  color: #8C9EC5;
  font-weight: bold;
}
.nav-tabs-product li a:hover {
  font-weight: bold;
}

a:hover span.btn-wishlist {
  background: #ffffff url(../imgs/template/icons/wishlist-hover.svg) no-repeat center;
}
a:hover span.btn-compare {
  background: #ffffff url(../imgs/template/icons/compare-hover.svg) no-repeat center;
}
a:hover span {
  color: #0BA9ED;
}

.list-dot {
  padding-left: 15px;
}
.list-dot li {
  list-style: disc;
  margin-bottom: 5px;
}

.list-colors {
  margin-top: 10px;
}
.list-colors li {
  display: inline-block;
  border: 1px solid #8C9EC5;
  padding: 5px;
  border-radius: 2px;
  margin-right: 4px;
  max-width: 45px;
  cursor: pointer;
}
.list-colors li:hover, .list-colors li.active {
  border-color: #FD9636;
}
.list-colors li img {
  display: block;
}
.list-colors li.disabled {
  border-style: dashed;
  border-color: #B2C2E1;
}
.list-colors li.disabled img {
  opacity: 0.3;
}

.list-styles {
  margin-top: 10px;
}
.list-styles li {
  display: inline-block;
  border: 1px solid #8C9EC5;
  padding: 5px 8px;
  border-radius: 2px;
  margin-right: 4px;
  cursor: pointer;
  margin-bottom: 8px;
}
.list-styles li.active, .list-styles li:hover {
  color: #FD9636;
  font-weight: bold;
  background-color: #F0F3F8;
  border-color: #FD9636;
}
.list-styles li.disabled {
  border-style: dashed;
  border-color: #B2C2E1;
  color: #8C9EC5;
}
.list-styles li.disabled:hover {
  color: #8C9EC5;
  font-weight: 400;
  background-color: #ffffff;
  border-color: #B2C2E1;
}

.list-sizes {
  margin-top: 10px;
}
.list-sizes li {
  display: inline-block;
  border: 1px solid #8C9EC5;
  padding: 5px 8px;
  border-radius: 2px;
  margin-right: 4px;
  font-size: 16px;
  line-height: 16px;
  color: #425A8B;
  cursor: pointer;
  margin-bottom: 8px;
}
.list-sizes li.active, .list-sizes li:hover {
  color: #FD9636;
  font-weight: bold;
  background-color: #F0F3F8;
  border-color: #FD9636;
}
.list-sizes li.disabled {
  border-style: dashed;
  border-color: #B2C2E1;
  color: #8C9EC5;
}
.list-sizes li.disabled:hover {
  color: #8C9EC5;
  font-weight: 400;
  background-color: #ffffff;
  border-color: #B2C2E1;
}

.label {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  border-radius: 30px;
  padding: 4px 10px;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  background-color: #FD9636;
}

.galleries {
  padding: 0px 0px 0px 105px;
  position: relative;
  width: 100%;
}

.detail-gallery {
  width: 100%;
  border: 4px solid #DDE4F0;
  border-radius: 4px;
}

.slider-nav-thumbnails {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 94px;
}

.slider-nav-thumbnails .slick-slide .item-thumb, .slider-nav-thumbnails-2 .slick-slide .item-thumb {
  height: 109px;
  border: 1px solid #DDE4F0;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
  line-height: 108px;
  cursor: pointer;
}
.slider-nav-thumbnails .slick-slide .item-thumb.slick-current, .slider-nav-thumbnails .slick-slide .item-thumb:hover, .slider-nav-thumbnails-2 .slick-slide .item-thumb.slick-current, .slider-nav-thumbnails-2 .slick-slide .item-thumb:hover {
  border-color: #FD9636;
}

.slider-nav-thumbnails .slick-slide .item-thumb img, .slider-nav-thumbnails-2 .slick-slide .item-thumb img {
  display: inline-block;
  height: auto;
  max-width: 90%;
  vertical-align: middle;
  max-height: 90%;
}

.slider-nav-thumbnails .slick-prev, .slider-nav-thumbnails-2 .slick-prev {
  display: none !important;
}

.slider-nav-thumbnails .slick-next, .slider-nav-thumbnails-2 .slick-next {
  display: none !important;
}

.slick-initialized .slick-slide {
  text-align: center;
}

.product-image-slider.slick-slider .slick-track figure {
  height: 610px;
  display: flex;
  align-items: center;
}

.product-image-slider-2.slick-slider .slick-track figure {
  height: 460px;
  display: flex;
  align-items: center;
  padding: 20px 0px;
}

.product-image-slider .slick-slide img, .product-image-slider-2 .slick-slide img {
  /* display: inline-block;
  vertical-align: middle;*/
  max-width: 99%;
  display: block;
  margin: auto;
  max-height: 100%;
}

.detail-gallery {
  position: relative;
}

#ModalQuickview .btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 123;
}

.galleries-2 {
  width: 100%;
  position: relative;
}

.zoomContainer {
  z-index: 9999;
}

.slider-nav-thumbnails-2 {
  margin-top: 20px;
}
.slider-nav-thumbnails-2 .slick-slide {
  padding: 0px 5px;
}

.link {
  color: #8C9EC5;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.display-text-short {
  display: inline-block;
  width: 100%;
  height: 125px;
  overflow: hidden;
}
.display-text-short p {
  margin-bottom: 15px;
}

.title-question {
  font-size: 18px;
  color: #425A8B;
  line-height: 28px;
}

.product-rate {
  background-image: url(../imgs/page/product/rating-stars.png);
  background-position: 0 -12px;
  background-repeat: repeat-x;
  height: 12px;
  width: 60px;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}

.product-rating {
  height: 12px;
  background-repeat: repeat-x;
  background-image: url(../imgs/page/product/rating-stars.png);
  background-position: 0 0;
}

.progress span {
  line-height: 16px;
  background: #ffffff;
  padding-right: 10px;
  width: 45px;
}

.progress-bar {
  background-color: #0BA9ED;
}

.progress + .progress {
  margin-top: 1rem;
}

.comments-area h6, .vendor-logo h6 {
  font-size: 16px;
  color: #425A8B;
  font-weight: 700;
  line-height: 1.2;
}

.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

.comments-area .comment-list .single-comment {
  margin: 0 0 15px 0;
  border: 1px solid #DDE4F0;
  border-radius: 4px;
  padding: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.comments-area .comment-list .single-comment:not(:last-child) {
  border-bottom: 1px solid #ececec;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.comments-area .thumb {
  margin-right: 20px;
}

.comments-area .comment-list .single-comment img {
  min-width: 80px;
  max-width: 80px;
}

.comments-area .thumb img {
  width: 70px;
  border-radius: 50%;
}

.text-brand {
  color: #425A8B !important;
  line-height: 1.2;
}

.comments-area .comment-list .single-comment .reply {
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #0BA9ED;
}

.comments-area .comment-list .single-comment:hover .reply {
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.comments-area .comment-list .single-comment .reply:hover {
  color: #FD9636;
}

.product-description .tab-content .contact-infor {
  font-size: 15px;
  color: #425A8B;
  list-style: none;
  padding: 0px;
}

.contact-infor li {
  color: #425A8B;
}

.contact-infor li:not(:last-child) {
  margin-bottom: 10px;
}

.contact-infor li img {
  margin-right: 8px;
  max-width: 16px;
  float: left;
}

.box-border-product {
  border-radius: 8px;
  border: 1px solid #8C9EC5;
  padding: 15px;
}
.box-border-product .box-quantity {
  width: 30%;
  min-width: 100px;
  max-width: 100px;
}
.box-border-product .box-quantity .input-quantity {
  margin-right: 0px;
}
.box-border-product .buy-product {
  align-items: center;
}
.box-border-product .text-quantity {
  width: 100%;
}

.box-progress-product .progress {
  background-color: #D5DFE4;
  height: 8px;
  border-radius: 4px;
}
.box-progress-product .progress .progress-bar {
  background-color: #425A8B;
  height: 8px;
}

.banner-right-product {
  height: 320px !important;
  padding-top: 20px;
}
.banner-right-product .font-md-bold {
  line-height: 20px !important;
}

.box-featured-product {
  background-color: #F0F3F8;
  border-radius: 4px;
  padding: 25px 25px 10px 25px;
  margin-bottom: 25px;
}

.item-featured {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.item-featured .featured-icon {
  min-width: 40px;
  max-width: 40px;
  margin-right: 15px;
}

.h-100 {
  height: 100%;
}

.vertical-align-middle {
  vertical-align: middle;
}

.box-wishlist {
  max-width: 95%;
  margin: auto;
}

.head-wishlist {
  margin-bottom: 10px;
}
.head-wishlist .item-wishlist {
  padding: 5px 15px;
  background-color: #F0F3F8;
  border-radius: 4px;
}

.content-wishlist {
  margin-bottom: 70px;
}
.content-wishlist .item-wishlist {
  border: 1px solid #DDE4F0;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.item-wishlist {
  display: flex;
  width: 100%;
  align-items: center;
}
.item-wishlist .wishlist-cb {
  width: 4%;
  max-width: 25px;
}
.item-wishlist .wishlist-cb .cb-layout {
  display: inline-block;
  margin: 3px 0px 0px 0px;
}
.item-wishlist .wishlist-product {
  width: 52%;
}
.item-wishlist .wishlist-price {
  width: 12%;
  text-align: center;
}
.item-wishlist .wishlist-status {
  width: 12%;
  text-align: center;
}
.item-wishlist .wishlist-status .btn.btn-gray {
  padding: 4px 10px;
}
.item-wishlist .wishlist-action {
  width: 15%;
  text-align: center;
}
.item-wishlist .wishlist-action .btn-cart {
  max-width: 126px;
  padding: 6px 15px;
}
.item-wishlist .wishlist-remove {
  width: 8%;
  text-align: center;
}

.product-wishlist {
  display: flex;
  width: 100%;
}
.product-wishlist .product-image {
  min-width: 130px;
  max-width: 130px;
  padding: 5px;
  text-align: center;
  margin-right: 15px;
  align-items: center;
  align-content: center;
  display: flex;
}
.product-wishlist .product-info {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding-right: 15px;
}
.product-wishlist .product-info a {
  display: block;
  margin-bottom: 5px;
}
.product-wishlist .product-info .rating {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.box-cart-left {
  border: 1px solid #DDE4F0;
  border-radius: 4px;
  padding: 20px;
}

.select-style1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../imgs/page/cart/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 5px center;
}

.summary-cart {
  border: 1px solid #DDE4F0;
  padding: 10px;
  margin-bottom: 30px;
}

.box-payment {
  display: flex;
  margin: 0px -10px;
}
.box-payment .btn {
  width: 33.3%;
  margin: 0px 5px;
  align-items: center;
  display: flex;
}

.box-border {
  border: 1px solid #DDE4F0;
  padding: 20px;
  border-radius: 4px;
}

.border-bottom-4 {
  border-bottom: 4px solid #DDE4F0;
  position: relative;
}

.text-or {
  display: inline-block;
  bottom: -18px;
  position: relative;
  background: #ffffff;
  padding: 5px;
}

.arrow-back-1 {
  background: url(../imgs/page/checkout/arrow-left.svg) no-repeat left top 12px;
  padding: 7px 0px 9px 25px;
  display: inline-block;
  color: #0BA9ED !important;
}
.arrow-back-1:hover {
  color: #425A8B !important;
}

.checkboxOffer {
  float: left;
  margin: 3px 10px 0px 0px;
}

.listCheckout .item-wishlist {
  border-radius: 4px;
  border: 1px solid #DDE4F0;
  padding: 15px;
  margin-bottom: 10px;
}
.listCheckout .item-wishlist .wishlist-product {
  width: 76%;
}

.box-compare-products {
  display: flex;
  color: #425A8B;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}
.box-compare-products table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #DDE4F0;
  border-radius: 4px;
}
.box-compare-products table tr td {
  vertical-align: middle;
  text-align: center;
  border: 1px solid #DDE4F0;
  padding: 10px 10px;
  min-width: 200px;
}
.box-compare-products table tr td:first-child {
  min-width: 150px;
  max-width: 150px;
}
.box-compare-products .font-xs {
  font-weight: 500;
}

.compare-col-1 {
  width: 15%;
  min-width: 148px;
  max-width: 148px;
}

.compare-col-2 {
  width: 40%;
}

.compare-col-3 {
  width: 40%;
}

.compare-col-4 {
  width: 40%;
}

.nav-center {
  text-align: center;
}
.nav-center nav {
  display: inline-block;
}

.min-height-135 {
  min-height: 115px;
}

@media (min-width: 1200px) {
  .display-list .card-grid-style-1 {
    display: flex;
    border: 1px solid #DDE4F0;
    border-radius: 5px;
    overflow: hidden;
  }
  .display-list .card-grid-style-1 .image-box {
    max-width: 465px;
    min-width: 445px;
    width: 35%;
    padding-right: 20px;
    margin-bottom: 0px;
  }
  .display-list .card-grid-style-1 .image-box img {
    display: block;
    border-radius: 0px;
  }
  .display-list .card-grid-style-1 .blog-info {
    width: 75%;
    padding: 10px;
  }
  .display-list-2 .card-grid-style-1 .image-box {
    max-width: 397px;
    min-width: 377px;
  }
}
.info-blog {
  max-width: 780px;
  margin: auto;
}

.image-feature {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.image-feature img {
  border-radius: 4px;
}

.image-single {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.content-text p {
  color: #0E224D;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}

.box-author {
  display: flex;
  align-items: center;
}
.box-author .img-author {
  width: auto;
  align-items: center;
  display: flex;
}
.box-author .img-author img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}

.facebook {
  background: url(../imgs/page/blog/fb.svg) no-repeat center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 7px;
}

.printest {
  background: url(../imgs/page/blog/printest.svg) no-repeat center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 7px;
}

.twitter {
  background: url(../imgs/page/blog/tw.svg) no-repeat center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 7px;
}

.instagram {
  background: url(../imgs/page/blog/ins.svg) no-repeat center;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 7px;
}

.share-link {
  display: flex;
  align-items: center;
}

.comment-box .comment-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}
.comment-box .comment-item .comment-content {
  width: 100%;
  padding-left: 90px;
  color: #0E224D;
}
.comment-box .comment-item .top-comment {
  display: flex;
  width: 100%;
  align-items: center;
}
.comment-box .comment-item .top-comment .image-author {
  min-width: 75px;
  max-width: 75px;
  margin-right: 15px;
}
.comment-box .comment-item .top-comment .image-author img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}
.comment-box .comment-item .top-comment .author-name {
  width: 100%;
}
.comment-box .comment-item .top-comment .author-name .info-author {
  width: 100%;
}
.comment-box .comment-item .top-comment .author-name .info-author span {
  display: inline-block;
  margin-right: 10px;
}
.comment-box .comment-item .top-comment .author-name .comment-content {
  width: 100%;
}
.comment-box .comment-item-small .top-comment {
  display: flex;
  width: 100%;
  align-items: center;
}
.comment-box .comment-item-small .top-comment .image-author {
  min-width: 40px;
  max-width: 40px;
  margin-right: 15px;
}
.comment-box .comment-item-small .top-comment .image-author img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.comment-box .comment-item-small .comment-content {
  padding-left: 55px;
}

.box-contact {
  position: relative;
}
.box-contact * {
  z-index: 2;
  position: relative;
}
.box-contact::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 4px;
  background-color: #DDE4F0;
  z-index: 1;
}

.map iframe {
  display: block;
  width: 100%;
}

.box-banner-vendor {
  margin-left: -10px;
  margin-right: -10px;
}
.box-banner-vendor .vendor-right {
  max-width: 293px;
  min-width: 293px;
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
}
.box-banner-vendor .vendor-right .box-featured-product {
  margin-bottom: 0px;
}
.box-banner-vendor .vendor-left {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.box-banner-vendor .vendor-left .banner-vendor > img {
  border-radius: 4px;
}

.box-info-vendor {
  padding: 15px 25px;
  position: relative;
}
.box-info-vendor .avarta {
  max-width: 150px;
  text-align: center;
  padding: 25px 15px 15px 15px;
  position: relative;
  top: -65px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #D5DFE4;
  max-height: 140px;
}
.box-info-vendor .avarta .btn-buy {
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
}
.box-info-vendor .info-vendor {
  padding: 0px 20px;
  width: 30%;
}
.box-info-vendor .vendor-contact {
  width: 60%;
}

.border-vendor {
  margin-top: -60px !important;
}

.form-register .btn-buy {
  padding: 14px 22px;
}
.form-register .form-control {
  padding: 14px 20px;
}
.form-register .form-group {
  margin-bottom: 22px;
}

.checkagree {
  float: left;
  margin: 2px 10px 0px 0px;
  border: 1px solid #DDE4F0;
}

.nav-tabs-account {
  text-align: left;
}
.nav-tabs-account li a {
  font-weight: bold;
}
.nav-tabs-account li a:hover {
  font-weight: bold;
}
.nav-tabs-account li:first-child {
  padding-left: 0px;
}
.nav-tabs-account li:first-child a {
  padding-left: 0px;
}

.list-notifications .item-notification {
  border: 1px solid #DDE4F0;
  display: flex;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  align-items: center;
}
.list-notifications .item-notification .image-notification {
  min-width: 130px;
  max-width: 130px;
}
.list-notifications .item-notification .info-notification {
  padding-left: 20px;
  width: 100%;
  padding-right: 20px;
}
.list-notifications .item-notification .button-notification {
  width: 10%;
  min-width: 150px;
  max-width: 150px;
  text-align: center;
}

.list-orders .item-orders {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.list-orders .item-orders:last-child {
  margin-bottom: 0px;
}
.list-orders .item-orders .image-orders {
  min-width: 130px;
  max-width: 130px;
  border: 1px solid #8C9EC5;
  text-align: center;
  padding: 8px;
}
.list-orders .item-orders .image-orders img {
  max-width: 100%;
}
.list-orders .item-orders .info-orders {
  padding-left: 20px;
  width: 50%;
  padding-right: 20px;
}
.list-orders .item-orders .quantity-orders {
  width: 25%;
}
.list-orders .item-orders .price-orders {
  width: 10%;
  min-width: 150px;
  max-width: 150px;
  text-align: center;
}

.body-orders {
  padding: 20px;
}

.box-orders {
  border: 1px solid #D5DFE4;
  border-radius: 4px;
  margin-bottom: 30px;
}
.box-orders .head-orders {
  display: flex;
  border-bottom: 1px solid #D5DFE4;
  padding: 20px;
  width: 100%;
  align-items: center;
}
.box-orders .head-orders .head-left {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}
.box-orders .head-orders .head-right {
  width: 20%;
  text-align: right;
}

.label-delivery {
  display: inline-block;
  padding: 7px 16px;
  border-radius: 30px;
  color: #ffffff;
  font-size: 13px;
  line-height: 12px;
  background-color: #FD9636;
}

.label-delivered {
  background-color: #5BC694;
}

.label-cancel {
  background-color: #F53658;
}

.h54 {
  height: 54px;
  line-height: 34px;
}

.form-tracking .form-control {
  height: 54px;
}
.form-tracking .btn-buy {
  height: 54px;
  line-height: 34px;
}
.form-tracking .box-input {
  width: 100%;
  margin-right: 15px;
}
.form-tracking .box-button {
  width: 10%;
  min-width: 185px;
  max-width: 185px;
}

.list-features {
  margin: 20px 0px 20px 0px;
}
.list-features ul {
  padding: 0px 0px 0px 15px;
  margin: 0px;
}
.list-features ul li {
  list-style: disc;
  color: #8C9EC5;
  font-size: 16px;
  line-height: 32px;
}

.list-steps {
  display: flex;
  width: 100%;
  margin: 30px 0px;
  min-width: 768px;
}
.list-steps .item-step {
  width: 50%;
  position: relative;
}
.list-steps .item-step::before {
  height: 10px;
  content: "";
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 1px;
  top: 33px;
  margin-top: -5px;
  background-color: #425A8B;
}
.list-steps .item-step:last-child::before {
  display: none;
}
.list-steps .item-step .rounded-step {
  position: relative;
  z-index: 2;
}
.list-steps .item-step .rounded-step .icon-step {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #CAD6EC;
  margin-bottom: 20px;
}
.list-steps .item-step .rounded-step .icon-step.step-1 {
  background-image: url(../imgs/page/account/tick.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.list-steps .item-step .rounded-step .icon-step.step-1.active {
  background-image: url(../imgs/page/account/tick-active.svg);
  background-color: #5BC694;
}
.list-steps .item-step .rounded-step .icon-step.step-2 {
  background-image: url(../imgs/page/account/in-production.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.list-steps .item-step .rounded-step .icon-step.step-2.active {
  background-image: url(../imgs/page/account/in-production-active.svg);
  background-color: #5BC694;
}
.list-steps .item-step .rounded-step .icon-step.step-3 {
  background-image: url(../imgs/page/account/shipping.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.list-steps .item-step .rounded-step .icon-step.step-3.active {
  background-image: url(../imgs/page/account/shipping-active.svg);
  background-color: #5BC694;
}
.list-steps .item-step .rounded-step .icon-step.step-4 {
  background-image: url(../imgs/page/account/delivery-truck.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.list-steps .item-step .rounded-step .icon-step.step-4.active {
  background-image: url(../imgs/page/account/delivery-active.svg);
  background-color: #5BC694;
}
.list-steps .item-step .rounded-step .icon-step.step-5 {
  background-image: url(../imgs/page/account/delivered.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.list-steps .item-step .rounded-step .icon-step.step-5.active {
  background-image: url(../imgs/page/account/delivered-active.svg);
  background-color: #5BC694;
}

.map-account {
  width: 100%;
  margin: 30px 0px;
}
.map-account iframe {
  width: 100%;
  height: 340px;
}

#tab-setting .form-control {
  min-height: 44px;
}

.list-services {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px 0px -10px;
}
.list-services li {
  font-family: "DM Sans", sans-serif;
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: bold !important;
  color: #425A8B;
  display: inline-block;
  width: 100%;
  padding: 0px 10px 0px 37px;
  background: url(../imgs/page/about/checked.svg) no-repeat 10px 1px;
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: 15px;
}

.box-images-logo img {
  opacity: 1;
  max-height: 28px;
  margin-right: 30px;
  margin-bottom: 40px;
}

.border-1 {
  border-top: 1px solid #D5DFE4;
}

.breadcrumbs-div {
  padding: 16px 0px;
  margin-bottom: 30px;
  background-color: #F0F3F8;
}

.breadcrumb {
  margin: 0px;
}
.breadcrumb li a {
  display: inline-block;
  padding: 0px 30px 0px 0px;
  background: url(../imgs/page/shop/arrow-right.svg) no-repeat top 5px right 10px;
}
.breadcrumb li:last-child a {
  background-image: none;
}

.sidebar-border {
  border: 1px solid #DDE4F0;
  margin-bottom: 30px;
}
.sidebar-border .sidebar-head {
  border-bottom: 1px solid #DDE4F0;
  padding: 22px 23px 18px 23px;
}
.sidebar-border .sidebar-content {
  padding: 22px;
}

.list-nav-arrow li {
  margin: 0px 0px 15px 0px;
  transition-duration: 0.2s;
}
.list-nav-arrow li:hover {
  padding-left: 3px;
  transition-duration: 0.2s;
}
.list-nav-arrow li:hover .number {
  background-color: #FD9636;
  color: #fff;
  transition-duration: 0.2s;
}
.list-nav-arrow li a {
  font-size: 16px;
  line-height: 24px;
  color: #425A8B;
  display: block;
  padding: 0px 0px 0px 15px;
  background: url(../imgs/page/shop/arrow-menu.svg) no-repeat 0px 6px;
}
.list-nav-arrow li a:hover {
  color: #FD9636;
}
.list-nav-arrow li a .number {
  background-color: #F0F3F8;
  border-radius: 7px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #425A8B;
  padding: 3px 0px;
  float: right;
  min-width: 25px;
  text-align: center;
  transition-duration: 0.2s;
}

.list-checkbox {
  display: inline-block;
  width: 100%;
  padding: 15px 0px 10px 0px;
}
.list-checkbox li {
  position: relative;
  padding: 0px 30px 6px 0px;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
.list-checkbox li .number-item {
  position: absolute;
  top: 35%;
  right: 0px;
  transform: translateY(-50%);
  padding: 4px 5px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 7px;
  font-weight: 500;
  background: #F0F3F8;
  color: #425A8B;
}

.list-color {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;
}
.list-color li {
  flex: 0 0 auto;
  width: 20%;
  padding: 0px 10px;
  text-align: center;
  margin-bottom: 20px;
}
.list-color li a {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-bottom: 5px;
}
.list-color li a:hover, .list-color li a.active {
  background-image: url(../imgs/page/shop/check.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.list-color li a.color-red {
  background-color: #F53658;
}
.list-color li a.color-green {
  background-color: #5BC694;
}
.list-color li a.color-blue {
  background-color: #1AAEED;
}
.list-color li a.color-purple {
  background-color: #9C79FF;
}
.list-color li a.color-black {
  background-color: #0E224D;
}
.list-color li a.color-gray {
  background-color: #DDE4F0;
}
.list-color li a.color-pink {
  background-color: #FF94BC;
}
.list-color li a.color-brown {
  background-color: #AB5F3E;
}
.list-color li a.color-yellow {
  background-color: #F9D915;
}

.slide-shop .card-grid-style-2 {
  margin-bottom: 35px;
}
.slide-shop .card-grid-style-2.border-bottom {
  border-width: 0px !important;
}
.slide-shop .card-grid-style-2 .image-box .label {
  left: 2px;
  top: 2px;
}
.slide-shop .card-grid-style-2.card-grid-none-border .image-box {
  min-width: 84px;
  max-width: 84px;
  margin-right: 13px;
  border: 1px solid #ccc;
  padding-right: 0px;
  border-radius: 4px;
}

.view-type-list {
  display: inline-block;
  height: 34px;
  width: 34px;
  background: url(../imgs/page/shop/list.svg) no-repeat center;
  background-size: cover;
  border-radius: 4px;
  vertical-align: middle;
}
.view-type-list:hover, .view-type-list.active {
  background: url(../imgs/page/shop/list-hover.svg) no-repeat center;
}

.view-type-grid {
  display: inline-block;
  height: 34px;
  width: 34px;
  background: url(../imgs/page/shop/grid.svg) no-repeat center;
  background-size: cover;
  border-radius: 4px;
  vertical-align: middle;
}
.view-type-grid:hover, .view-type-grid.active {
  background: url(../imgs/page/shop/grid-hover.svg) no-repeat center;
}

.dropdown-sort {
  line-height: 21px;
  display: inline-block;
  vertical-align: middle;
}
.dropdown-sort .dropdown-toggle {
  padding: 5px 0px 10px 7px;
}
.dropdown-sort .dropdown-toggle:after {
  content: "\f151";
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1 !important;
  font-size: 14px;
  opacity: 1;
  margin-left: 2px;
  position: relative;
  top: 6px;
  border: 0px !important;
  color: rgba(66, 90, 139, 0.6117647059);
}

.border-1-right {
  position: relative;
  margin-right: 30px;
  line-height: 21px;
  display: inline-block;
  vertical-align: middle;
}
.border-1-right::before {
  content: "";
  height: 34px;
  width: 1px;
  background-color: #D5DFE4;
  position: absolute;
  top: 3px;
  right: -15px;
}
.border-1-right.span {
  padding: 5px 10px 10px 7px;
}
.border-1-right.span::before {
  content: "";
  height: 34px;
  width: 1px;
  background-color: #D5DFE4;
  position: absolute;
  top: 1px;
  right: -15px;
}

.box-btn .btn {
  padding: 0px;
}

.link-see-more {
  background: url(../imgs/page/shop/arrow-brand-2.svg) no-repeat top 6px right 0px;
  color: #FD9636;
  display: inline-block;
  padding: 0px 15px 0px 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.border-brand-2 {
  position: relative;
}
.border-brand-2::before {
  content: "";
  height: 3px;
  width: 68px;
  background-color: #FD9636;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.pagination li {
  padding: 2px;
}
.pagination li a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #425A8B;
  border-radius: 4px;
  border: 1px solid #D5DFE4;
  min-width: 36px;
  text-align: center;
}
.pagination li a:hover, .pagination li a.active {
  background-color: #FD9636;
  border-color: #FD9636;
  color: #ffffff;
}

.pagination li .page-prev {
  background: url(../imgs/page/homepage2/prev.svg) no-repeat center;
  min-height: 32px;
  padding: 5px 20px;
  border: 0px;
}
.pagination li .page-prev:hover {
  background-color: transparent;
}

.pagination li .page-next {
  background: url(../imgs/page/homepage2/next.svg) no-repeat center;
  min-height: 32px;
  padding: 5px 20px;
  border: 0px;
}
.pagination li .page-next:hover {
  background-color: transparent;
}

.shop-template .font-sm {
  font-weight: 500;
}

.modal-body .list-checkbox li .cb-container {
  margin-bottom: 2px;
  padding-left: 26px;
}

.align-top {
  vertical-align: top;
}

@media (min-width: 792px) {
  .col-w-1 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-w-2 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .col-w-1 {
    flex: 0 0 auto;
    width: 23%;
  }
  .col-w-2 {
    flex: 0 0 auto;
    width: 31%;
  }
}
.filters-icon {
  background-image: url(../imgs/page/shop/filter.svg);
  background-repeat: no-repeat;
  background-position: 13px 9px;
  padding: 0px 0px 0px 34px;
}

.banner-ads-top {
  border: 1px solid #DDE4F0;
  border-radius: 4px;
  overflow: hidden;
}
.banner-ads-top img {
  display: block;
}

.banner-top-gray-100 .banner-ads-top {
  background-color: #F9FAFB !important;
}

.slider-shop-2 .card-grid-style-2 {
  border: 1px solid #D5DFE4;
  margin-left: 1px;
}
.slider-shop-2 .card-grid-style-2:hover {
  border: 1px solid #FD9636;
}
.slider-shop-2 .swiper-button-next {
  right: -50px;
  top: 50%;
  margin-top: -30px;
}
.slider-shop-2 .swiper-button-prev {
  left: -50px;
  right: auto;
  top: 50%;
  margin-top: -30px;
}

.sidebar-ads .bg-electronic {
  height: auto;
  margin-bottom: 25px;
  padding-bottom: 105px;
  padding-top: 20px;
  background-size: auto 40%;
}

.big-deal {
  background-color: #27B3D2;
  border-radius: 30px;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  padding: 10px 16px;
}

.slider-shop-2 .card-grid-style-2 {
  margin-left: 1px;
}

@media (max-width: 1550px) {
  .banner-hero.banner-2 .block-2 {
    right: -10px;
  }
  .header .main-header .header-left .header-shop .icon-list {
    padding: 0px 18px 0px 18px;
    width: 25px;
    height: 25px;
  }
  .header-shop a span {
    display: none;
  }
  .header .main-header .header-left .header-shop {
    width: 16%;
  }
}
@media (max-width: 1399.98px) {
  .block-samsung {
    padding: 25px 100px 25px 35px;
    background-size: auto 170px;
  }
  .block-drone {
    padding: 25px 120px 25px 35px;
    background-size: auto 130px;
  }
  .block-iphone {
    background-size: auto 110px;
  }
  .image-gallery .font-33 {
    font-size: 20px;
    line-height: 27px;
  }
  .image-gallery .font-32 {
    font-size: 25px;
    line-height: 32px;
  }
  .bg-metaverse {
    background-size: auto 75%;
  }
  .list-col-5 li {
    padding: 0px 4px;
  }
  .item-list .font-lg-bold {
    font-size: 16px;
    line-height: 24px;
  }
  .card-grid-style-4 .card-grid-inner .info-right {
    width: 60%;
  }
  .card-grid-style-4 .card-grid-inner .image-box {
    width: 40%;
  }
  .banner-homepage3 {
    padding-left: 65px;
  }
  .display-list .card-grid-style-3 .card-grid-inner .image-box {
    min-width: 210px;
    max-width: 210px;
  }
  .box-info-vendor .vendor-contact {
    position: relative;
    top: -40px;
    width: 100%;
  }
  .box-info-vendor {
    flex-wrap: wrap;
  }
  .box-info-vendor .info-vendor {
    width: 70%;
  }
  .border-vendor {
    margin-top: -40px !important;
  }
  .banner-image {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .burger-icon {
    display: inline-block;
  }
  .topbar .container-topbar .menu-topbar-right {
    text-align: center;
    width: 100%;
  }
  .list-products-5 .card-grid-style-3 {
    width: 33.333%;
  }
  .list-products-5.list-products-3 .card-grid-style-3 {
    width: 33.333%;
  }
  .header .main-header .header-left .header-shop {
    width: 35%;
    padding-right: 35px;
    min-width: 189px;
  }
  .header .main-header .header-left .header-nav {
    width: 0%;
  }
  .header .main-header .header-left .header-search {
    width: 65%;
  }
  .header .burger-icon {
    display: block;
  }
  .box-count.box-count-square .deals-countdown {
    padding: 4px 0px;
  }
  .block-iphone {
    background-size: auto 150px;
  }
  .list-9-col li {
    width: 16.66%;
  }
  .top-deals .box-top-deals .top-deals-right,
  .top-deals .box-top-deals .top-deals-left {
    width: 100%;
  }
  .top-deals .card-grid-inner .image-box {
    height: auto;
    line-height: 100%;
  }
  .bg-headphone {
    padding-bottom: 30px;
  }
  .font-xl {
    font-size: 20px;
    line-height: 26px;
  }
  .font-33 {
    font-size: 25px;
  }
  .font-32 {
    font-size: 24px;
  }
  .box-wishlist {
    max-width: 100%;
  }
  .btn.btn-arrow-2 {
    background-position: top 3px right 0px;
    padding: 0px 20px 0px 0px;
    font-size: 14px;
    line-height: 16px;
    background-size: 14px;
  }
  .info-blog .btn.btn-arrow-2 {
    padding: 0px 18px 0px 0px;
    font-size: 12px !important;
    line-height: 16px !important;
    background-size: 12px;
  }
  .gallery-image .image-left {
    min-width: 99px;
    max-width: 99px;
    padding-right: 10px;
  }
  .pl-mb-0 {
    padding-left: 0px !important;
  }
  .list-services li {
    width: 100%;
  }
  .bg-newsletter-home7::before, .bg-newsletter-home7::after {
    background-size: auto 50%;
  }
  .btn-category:before {
    display: none;
  }
  .banner-home9 .banner-small {
    padding: 15px 10px 0px 10px;
  }
  .banner-home9 .banner-big-home9 .box-img-banner img {
    max-width: 200px;
  }
  .box-newsletter-home10::after {
    background-size: 250px;
  }
  .box-newsletter-home10::before {
    background-size: 250px;
  }
  .card-grid-style-2 {
    padding: 15px 7px;
  }
  .card-grid-style-2 .image-box {
    min-width: 105px;
    max-width: 105px;
    padding-right: 10px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner-2 img {
    max-width: 270px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner img {
    max-width: 210px;
  }
}
@media (max-width: 991.98px) {
  .footer .width-20 {
    width: 50%;
  }
  .footer .width-16 {
    width: 50%;
  }
  .list-products-5 .card-grid-style-3 {
    width: 50%;
  }
  .topbar {
    display: none;
  }
  .header .main-header .header-left .header-shop {
    padding-top: 5px;
  }
  .btn.btn-open {
    background-position: center 15px;
    height: 50px;
    position: absolute;
    background-color: transparent;
  }
  .sidebar-left {
    height: auto;
    min-height: 54px;
    top: 0px;
    border: 0;
    background-color: transparent;
  }
  .sidebar-left .menu-texts {
    border-top: 0;
  }
  .sidebar-left .menu-texts li a .img-link {
    border: 0;
  }
  .sidebar-left .menu-texts li.submenu-open .sub-menu {
    border-top: 1px solid #D5DFE4;
  }
  .dropdown .sidebar-left {
    background-color: #ffffff;
  }
  .banner-hero.banner-1 .banner-big {
    margin-bottom: 25px;
  }
  .list-products-5.list-products-3 .card-grid-style-3 {
    width: 50%;
  }
  .list-col-5 li {
    width: 50%;
    height: 110px;
    margin-bottom: 15px;
  }
  .bg-metaverse {
    padding: 20px 15px 0px 15px;
  }
  .bg-controller {
    background-position: center left 10px;
    background-size: auto 65%;
  }
  .banner-img-left {
    padding: 20px 20px 0px 170px;
    height: 225px;
  }
  .footer .width-25 {
    width: 100%;
  }
  .footer .width-23 {
    width: 50%;
  }
  .menu-texts {
    top: 53px;
  }
  .box-all-hurry {
    bottom: -10px;
    text-align: center;
  }
  .header.stick {
    padding: 5px 0px 0px 0px;
  }
  .banner-ads::before {
    width: 200px;
  }
  .banner-ads::after {
    width: 150px;
  }
  .bg-headphone {
    background-size: auto 95%;
  }
  .menu-texts {
    display: none;
  }
  .dropdown .menu-texts {
    display: block;
  }
  .nav-tabs {
    text-align: center;
  }
  .gallery-image {
    margin-bottom: 40px;
  }
  .single-product-3 {
    margin-bottom: 20px;
  }
  .content-wishlist .item-wishlist {
    flex-wrap: wrap;
  }
  .item-wishlist .wishlist-cb {
    width: 8%;
  }
  .item-wishlist .wishlist-product {
    width: 92%;
  }
  .item-wishlist .wishlist-price {
    width: 20%;
    margin-top: 15px;
  }
  .item-wishlist .wishlist-status {
    width: 27%;
    margin-top: 15px;
  }
  .item-wishlist .wishlist-action {
    width: 35%;
    margin-top: 15px;
  }
  .item-wishlist .wishlist-remove {
    width: 18%;
    margin-top: 15px;
  }
  .head-wishlist .item-wishlist .wishlist-price,
  .head-wishlist .item-wishlist .wishlist-status,
  .head-wishlist .item-wishlist .wishlist-action,
  .head-wishlist .item-wishlist .wishlist-remove {
    display: none;
  }
  .product-wishlist .product-info {
    padding-right: 0px;
  }
  .box-bought-together {
    flex-wrap: wrap;
  }
  .box-bought-together .box-product-bought .product-bought {
    min-width: auto;
    max-width: none;
    margin-bottom: 30px;
    padding: 10px 0px;
  }
  .box-bought-together .price-bought {
    padding-left: 0px;
  }
  .box-banner-vendor {
    flex-wrap: wrap;
  }
  .box-banner-vendor .vendor-left {
    width: 100%;
  }
  .box-banner-vendor .vendor-right {
    width: 100%;
    max-width: 100%;
  }
  .border-vendor {
    margin-top: 30px !important;
  }
  .list-orders .item-orders {
    flex-wrap: wrap;
  }
  .list-orders .item-orders .info-orders {
    width: 75%;
    padding-right: 0px;
  }
  .list-orders .item-orders .image-orders {
    min-width: auto;
    max-width: none;
    width: 25%;
  }
  .list-orders .item-orders .quantity-orders {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .list-orders .item-orders .price-orders {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .box-orders .head-orders {
    display: inline-block;
  }
  .box-orders .head-orders .head-right {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
  .list-orders .item-orders .quantity-orders {
    width: 50%;
  }
  .list-orders .item-orders .price-orders {
    width: 50%;
    text-align: right;
    min-width: auto;
    max-width: none;
  }
  .nav-tabs-account {
    text-align: left;
  }
  .list-services {
    margin-bottom: 30px;
  }
  .list-services li {
    width: 50%;
  }
  .box-newsletter.bg-brand-2 {
    background-image: none;
  }
  .list-categories-5 li {
    width: 33.33%;
  }
  .banner-bottom-3 {
    height: 766px;
  }
  .ads-info h4 {
    font-size: 14px;
    line-height: 20px;
  }
  .ads-info h3 {
    font-size: 16px;
    line-height: 24px;
  }
  .banner-home9 .banner-big-home9 .box-img-banner img {
    max-width: 300px;
  }
  .notify-text {
    margin-bottom: 10px;
  }
  .box-notify {
    padding: 10px 0px 0px 0px;
  }
  .nav-tabs.pr-100 {
    padding-right: 0px !important;
  }
}
@media (max-width: 767.98px) {
  .footer .width-20 {
    width: 100%;
  }
  .footer .width-16 {
    width: 100%;
  }
  .footer .width-23 {
    width: 100%;
  }
  .burger-icon {
    top: 17px;
  }
  .sticky-bar.stick .burger-icon {
    top: 17px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
    max-width: 140px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
    padding: 30px;
  }
  .nav.nav-right {
    margin-top: 30px;
  }
  .list-products-5.list-products-3 .hurry-up {
    width: 100%;
  }
  .list-products-5.list-products-3 .hurry-up .card-grid-inner {
    height: auto;
  }
  .image-gallery {
    margin-bottom: 0px;
  }
  .image-gallery .image-big {
    width: 100%;
    margin-bottom: 25px;
  }
  .image-gallery .image-small {
    width: 100%;
    margin-bottom: 25px;
  }
  h1 {
    font-size: 44px;
    line-height: 63px;
  }
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .box-newsletter {
    padding-bottom: 110px;
  }
  .box-promotions {
    height: auto;
    max-height: 515px;
  }
  .banner-hero.banner-1 .banner-big.banner-big-3 {
    background-position: center top;
  }
  .font-68 {
    font-size: 40px;
    line-height: 42px;
  }
  .list-9-col li {
    width: 25%;
  }
  .card-grid-style-2 .image-box {
    min-width: 110px;
    max-width: 110px;
    padding-right: 10px;
  }
  .nav-tabs li a h4 {
    font-size: 20px;
  }
  .border-1-right {
    margin-right: 15px;
  }
  .border-1-right::before {
    display: none;
  }
  .dropdown .sidebar-left .menu-texts {
    display: block;
  }
  .dropdown .sidebar-left .menu-texts.menu-close {
    min-width: 320px;
  }
  .box-info-vendor .avarta .btn-buy {
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
    font-size: 10px !important;
    line-height: 14px !important;
  }
  .box-info-vendor .avarta {
    max-width: 115px;
    padding: 15px 5px 10px 5px;
    top: -40px;
    width: 35%;
  }
  .box-info-vendor .info-vendor {
    width: 65%;
  }
  .box-info-vendor .info-vendor {
    padding: 0px 0px 0px 20px;
  }
  .box-info-vendor .info-vendor h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .box-info-vendor .info-vendor .mr-20 {
    margin-right: 0px;
  }
  .box-info-vendor {
    padding: 5px 5px;
  }
  .box-info-vendor .vendor-contact {
    top: -20px;
  }
  .location,
  .phone {
    font-size: 14px;
    line-height: 22px;
  }
  .form-tracking .box-button {
    min-width: 120px;
    max-width: 120px;
  }
  .form-tracking .btn-buy {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box-orders .head-orders .head-left {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .box-orders .head-orders .head-left h5 {
    width: 100%;
    margin-right: 0px !important;
    margin-bottom: 15px;
  }
  .list-notifications .item-notification .button-notification {
    min-width: 110px;
    max-width: 110px;
    text-align: center;
  }
  .list-notifications .item-notification .button-notification .btn-buy {
    padding: 7px 10px;
  }
  .list-notifications .item-notification .info-notification {
    padding-left: 10px;
    padding-right: 10px;
  }
  .list-notifications .item-notification .info-notification h5 {
    font-size: 14px;
    line-height: 18px;
  }
  .list-notifications .item-notification .info-notification .font-md {
    font-size: 12px;
    line-height: 14px;
  }
  .box-account-template {
    padding-top: 35px;
  }
  .nav-tabs-account li a {
    padding-left: 0px;
    padding-right: 0px;
  }
  .banner-slide {
    min-height: 350px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner img {
    max-width: 180px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner-2 img {
    max-width: 220px;
  }
  .banner-bottom-3 {
    height: 666px;
  }
  .bg-newsletter-home7::after {
    display: none;
  }
  .banner-right-7 {
    max-height: none;
  }
  .box-ads-3 img {
    max-width: 230%;
  }
  .banner-hero.banner-8 .banner-big {
    padding: 50px 20px 50px 50px;
    height: auto !important;
  }
  .block-sale-3, .block-sale-2 {
    height: auto;
  }
  .banner-8 .box-banner-big h1 {
    line-height: 33px;
  }
  .box-product-category .d-flex {
    flex-wrap: wrap;
  }
  .box-category-left, .box-category-right {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .box-category-right {
    margin-top: 30px;
  }
  .box-ads-home10 {
    min-height: 150px;
  }
  .box-ads-home10 img {
    max-width: none;
    height: 100%;
    max-height: 150px;
  }
}
@media (max-width: 575.98px) {
  .header {
    padding: 1px 0;
  }
  .header.stick {
    padding: 0px 0;
  }
  .header-container {
    padding: 5px 0 0px 0;
  }
  .header-container.stick {
    padding: 5px 0 0px 0px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next-style-2,
  .swiper-button-prev-style-2 {
    display: block;
  }
  .header .main-header .header-left .header-shop {
    min-width: 189px;
  }
  .header .main-header .header-left .header-search {
    display: none;
  }
  .header .container {
    max-width: 100%;
  }
  .header .main-header .header-left .header-logo {
    max-width: 100%;
  }
  .header .main-header .header-left .header-shop {
    width: 55%;
  }
  .list-products-5.list-products-3 .card-grid-style-3 {
    width: 100%;
  }
  h1 {
    font-size: 34px;
    line-height: 53px;
  }
  h2 {
    font-size: 25px;
    line-height: 35px;
  }
  h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .sidebar-left {
    top: -2px;
  }
  .header-logo {
    margin-left: 36px;
  }
  .box-newsletter {
    padding-bottom: 150px;
  }
  .banner-hero.banner-1 .banner-big.banner-big-2 {
    padding: 100px 40px 55px 40px;
  }
  .nav-tabs.pr-100 {
    padding-right: 0px !important;
  }
  .breadcrumb {
    padding-left: 45px;
  }
  .product-wishlist .product-image {
    min-width: 90px;
    max-width: 90px;
    padding: 1px;
    margin-right: 10px;
  }
  .product-wishlist .product-info h5 {
    font-size: 15px;
    line-height: 20px;
  }
  .listCheckout .product-wishlist .product-info h5 {
    font-size: 12px;
    line-height: 16px;
  }
  .mb-mobile {
    margin-bottom: 15px;
  }
  .list-services li {
    width: 100%;
  }
  .box-images-logo img {
    max-height: 16px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .list-categories-5 li {
    width: 50%;
  }
  .banner-home9 .banner-big-home9 .box-img-banner img {
    max-width: 250px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner img {
    max-width: 180px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner-2 img {
    max-width: 250px;
  }
}
@media (max-width: 499.98px) {
  .list-col-5 li {
    width: 100%;
    height: auto;
  }
  .banner-2 {
    padding: 20px 15px;
  }
  .font-33 {
    font-size: 20px;
    line-height: 23px;
  }
  .font-18 {
    font-size: 16px;
    line-height: 20px;
  }
  .bg-phone {
    background-color: #DDD3FA;
    padding: 30px 30px 30px 30px;
  }
  .nav-tabs li {
    padding: 0px 0px 0px 0px;
  }
  .nav-tabs-product li {
    padding: 5px 0px 5px 0px;
    width: 100%;
    border: 1px solid #e7e7e7;
    margin-bottom: 10px;
  }
  .nav-tabs li a {
    padding: 0px 5px;
    font-size: 12px;
    line-height: 20px;
  }
  h3 {
    font-size: 22px;
    line-height: 31px;
  }
  .font-base {
    font-size: 14px;
    line-height: 18px;
  }
  .box-count.box-count-square .deals-countdown .countdown-section {
    margin: 0px 4px;
  }
  .box-count.box-count-square .deals-countdown .countdown-section::before {
    right: -6px;
  }
  .box-count .deals-countdown {
    padding-left: 0px;
    padding-right: 0px;
  }
  .card-grid-style-2 .image-box {
    min-width: 95px;
    max-width: 95px;
    padding-right: 10px;
  }
  .list-brands {
    padding: 10px 5px;
  }
  h1 {
    font-size: 29px;
    line-height: 45px;
  }
  h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .banner-hero.banner-1 .banner-big {
    padding: 60px 20px 25px 20px;
    background-position: center bottom;
  }
  .box-newsletter {
    padding-bottom: 190px;
  }
  .block-drone,
  .block-samsung {
    padding-right: 35px;
  }
  .header-bottom .dropdown-right {
    width: 0%;
    overflow: hidden;
    padding: 0px;
  }
  .btn.btn-category {
    padding: 10px 20px 10px 35px;
    min-width: 33px;
    width: 33px;
  }
  .banner-ads::before {
    width: 150px;
  }
  .banner-ads::after {
    width: 100px;
  }
  .font-46 {
    font-size: 35px;
    line-height: 49px;
  }
  .banner-hero.banner-1 .banner-small {
    padding: 10px;
  }
  .list-9-col li {
    width: 33.33%;
  }
  .dropdown .sidebar-left {
    min-width: 58px;
    border-style: solid;
    border-width: 1px;
    padding: 0px;
    width: 58px;
  }
  .dropdown .sidebar-left .menu-texts {
    display: block;
  }
  .dropdown .sidebar-left .menu-texts.menu-close {
    min-width: auto;
  }
  .dropdown .sidebar-left .menu-texts.menu-close li a .text-link {
    display: none;
  }
  .dropdown .sidebar-left .menu-texts.menu-close li .sub-menu {
    bottom: -1px;
    top: -1px;
  }
  .list-products-5 .card-grid-style-3 {
    width: 100%;
  }
  .box-bought-together .box-product-bought .product-bought {
    padding: 5px;
    margin-right: 30px;
  }
  .box-bought-together .box-product-bought .product-bought::before {
    left: -39px;
  }
  .gallery-image .image-left {
    min-width: 69px;
    max-width: 69px;
    width: 25%;
    padding-right: 10px;
  }
  .slider-nav-thumbnails {
    width: 100%;
    position: relative;
    margin-top: 15px;
  }
  .galleries {
    padding-left: 0;
  }
  .list-orders .item-orders .image-orders {
    min-width: auto;
    max-width: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .list-orders .item-orders .info-orders {
    width: 100%;
    padding-left: 0px;
  }
  .list-orders .item-orders .info-orders h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .list-orders .item-orders .quantity-orders {
    margin-top: 10px;
  }
  .list-orders .item-orders .price-orders {
    margin-top: 10px;
  }
  .list-notifications .item-notification {
    flex-wrap: wrap;
  }
  .list-notifications .item-notification .image-notification {
    min-width: auto;
    max-width: none;
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .list-notifications .item-notification .info-notification {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 15px;
  }
  .nav-tabs-account li a {
    font-size: 14px;
    line-height: 20px;
  }
  .nav-tabs li a h4 {
    font-size: 16px;
  }
  .banner-bottom-3 {
    height: 466px;
  }
  .dropdown .menu-texts.menu-close > li.has-children > a {
    background-image: none !important;
  }
  .banner-home9 .banner-big-home9 .box-img-banner img {
    max-width: 220px;
  }
  .banner-home10 .banner-big-home10 .info-banner .box-count-square .deals-countdown .countdown-section::before {
    left: -6px;
  }
}
@media (max-width: 449.98px) {
  .box-quantity {
    flex-wrap: wrap;
  }
  .button-buy {
    margin-top: 20px;
    width: 100%;
  }
  .item-wishlist .wishlist-price {
    width: 50%;
  }
  .item-wishlist .wishlist-status {
    width: 50%;
  }
  .item-wishlist .wishlist-action {
    width: 50%;
  }
  .item-wishlist .wishlist-remove {
    width: 50%;
  }
  .listCheckout .item-wishlist .wishlist-status {
    width: 20%;
  }
  .listCheckout .item-wishlist .wishlist-price {
    width: 30%;
  }
  .listCheckout .product-wishlist .product-image {
    min-width: 60px;
    max-width: 60px;
  }
  .listCheckout .item-wishlist {
    padding: 7px;
  }
  .box-border {
    padding: 10px;
  }
  .slider-nav-thumbnails {
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
  }
  .galleries {
    padding-left: 0px;
  }
  .slider-nav-thumbnails .slick-slide {
    padding: 0px 5px;
  }
  .slider-nav-thumbnails .slick-slide .item-thumb {
    margin-top: 15px;
  }
  .slider-nav-thumbnails .slick-slide .item-thumb {
    height: auto;
    line-height: 100%;
    padding: 5px;
  }
  .product-image-slider.slick-slider .slick-track figure {
    height: auto;
    padding: 20px 0px;
  }
  .box-orders .head-orders .head-left .font-md {
    margin-bottom: 10px;
    margin-right: 0px !important;
    display: inline-block;
    width: 100%;
  }
  .nav-tabs-account li {
    width: 100%;
    border: 1px solid #DDE4F0;
    margin-bottom: 10px;
    text-align: center;
  }
  .nav-tabs-account li a {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 20px;
  }
  .nav-tabs-account li a {
    padding: 10px 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner, .banner-home10 .banner-big-home10 .box-img-banner-2 {
    display: none;
  }
  .list-categories-5 li {
    width: 100%;
  }
  .ads-info h4 {
    font-size: 12px;
    line-height: 14px;
  }
  .ads-info h3 {
    font-size: 14px;
    line-height: 16px;
  }
  .banner-home9 .banner-big-home9 .box-img-banner img {
    max-width: 200px;
  }
  .banner-small-home10 .box-img-banner img.img1 {
    max-width: 170px;
  }
  .banner-small-home10 .box-img-banner img.img3 {
    max-width: 190px;
  }
  .banner-small-home10 {
    padding: 20px 20px;
  }
  .box-newsletter-home10::before {
    background-size: 180px;
  }
  .box-newsletter-home10::after {
    background-size: 180px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner img {
    max-width: 150px;
  }
  .banner-home10 .banner-big-home10 .box-img-banner-2 img {
    max-width: 250px;
  }
}
@media (max-width: 349.98px) {
  .list-9-col li {
    width: 50%;
  }
  .mobile-header-wrapper-style {
    width: 310px;
  }
  .perfect-scroll {
    width: 100%;
  }
  .box-quantity {
    flex-wrap: wrap;
  }
  .box-bought-together .box-product-bought {
    flex-wrap: wrap;
  }
  .box-bought-together .box-product-bought .product-bought {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 50px;
  }
  .box-bought-together .box-product-bought .product-bought::before {
    content: "";
    top: -50px;
    left: 0px;
    right: 0px;
    margin: auto;
    margin-top: 0px;
  }
  .btn.arrow-next {
    background-position: right 12px center;
    padding-right: 35px;
  }
  .arrow-back-1 {
    padding: 7px 0px 9px 15px;
    background-size: 12px;
  }
  .pagination li a {
    min-width: 30px;
  }
  .pagination li .page-prev {
    padding: 5px 15px;
  }
}
@media (min-width: 1400px) {
  .container.wide {
    max-width: 1544px;
  }
}
/*OTHER SCREEN*/
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .header-right {
    display: none;
  }
}
@media screen and (max-width: 1550px) {
  .icon-list span:first-child {
    display: none;
  }
}
.page-content p {
  margin-bottom: 15px;
}