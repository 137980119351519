.btn
{
    border-radius: 4px;
    padding: 10px 22px;
    font-family: $font-dm-sans;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    &.btn-arrow
    {
        font-size: $font-xxs;
        line-height: 32px;
        color: $color-brand-1;
        font-weight: bold;
        padding-left: 0px;
        padding-right: 15px;
        text-transform: uppercase;
        background: url(../imgs/template/icons/arrow.svg) no-repeat right 0px top 20px;
    }
    &.btn-brand-2
    {
        background-color: $color-brand-2;
        color: $color-white;
        font-weight: bold;
        &:hover
        {
            background-color: $color-brand-3;
        }
    }
    &.btn-brand-3
    {
        background-color: $color-brand-3;
        color: $color-white;
        font-weight: bold;
        &:hover
        {
            background-color: $color-brand-2;
        }
    }
    &.btn-info
    {
        background-color: $color-info;
        color: $color-white;
        font-weight: bold;
        &:hover
        {
            background-color: $color-brand-2;
            border-color: $color-brand-2;
        }
    }
    &.btn-arrow-right
    {
        background-image: url(../imgs/template/icons/arrow-white.svg);
        background-position: center right 18px;
        background-repeat: no-repeat;
        padding-right: 34px;
    }
    &.btn-link
    {
        color: $color-brand-3;
        font-size: 14px;
        line-height: 18px;
        &:hover
        {
            color: $color-brand-2;
        }
    }
    &.btn-link-brand-2
    {
        color: $color-brand-2;
        font-size: 12px;
        line-height: 32px;
        font-weight: bold;
        padding-left: 0px;
        text-transform: uppercase;
        &:hover
        {
            color: $color-brand-3;
        }
    }
    &.btn-arrow-brand-2
    {
        background-image: url(../imgs/template/icons/arrow-brand-2.svg);
        background-position: center right 18px;
        background-repeat: no-repeat;
        padding-right: 34px;
        &:hover
        {
            background-image: url(../imgs/template/icons/arrow-gray.svg);
            background-position: center right 18px;
            background-repeat: no-repeat;
        }
    }
    &.btn-arrow-2
    {
        background-image: url(../imgs/template/icons/arrow-2.svg);
        background-position: top 3px right 10px;
        background-repeat: no-repeat;
        padding: 0px 40px 0px 0px;
        font-size: 17px;
        line-height: 19px;
        color: $color-brand-2;
        font-weight: bold;
        &:hover
        {
            color: $color-brand-3;
            background-image: url(../imgs/template/icons/arrow-2-hover.svg);
        }
    }
    &.btn-arrow-2-reverse {
        color: $color-brand-3;
        background-image: url(../imgs/template/icons/arrow-2-hover.svg);
        &:hover {
            background-image: url(../imgs/template/icons/arrow-2.svg);
            color: $color-brand-2;
        }
    }
    &.btn-arrow-small
    {
        background-size: 5px;
        background-position: top 6px right 18px;
        line-height: 18px;
        padding-right: 30px;
    }
    &.btn-cart
    {
        font-size: $font-sm;
        line-height: 20px;
        font-weight: bold;
        color: $color-brand-3;
        border: 1px solid $color-gray-500;
        width: 100%;
        text-align: center;
        padding: 7px 22px;
        -webkit-transition: all .3s ease 0s;
			-o-transition: all .3s ease 0s;
			transition: all .3s ease 0s;
        &:hover
        {
            border: 1px solid $color-brand-3;
            background-color: $color-gray-900;
            color: $color-white;
        }
    }
    &.btn-buy
    {
        font-size: $font-sm;
        line-height: 20px;
        font-weight: bold;
        color: $color-white;
        border: 1px solid $color-gray-500;
        width: 100%;
        text-align: center;
        padding: 7px 22px;
        background-color: $color-gray-900;
        &:hover
        {
            border: 1px solid $color-brand-3;
            background-color: $background-white;
            color: $color-gray-900;
        }
    }
    &.btn-trend
    {
        border: 1px solid $color-border-1;
        height: 30px;
        width: 30px;
        background: $background-white url(../imgs/template/icons/trend.svg) no-repeat center;
        padding: 0px;
        opacity: 0;
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        -webkit-transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        will-change: opacity,transform;
        &:hover
        {
            background: $background-white url(../imgs/template/icons/trend-hover.svg) no-repeat center;
        }
    }
    &.btn-wishlist
    {
        border: 1px solid $color-border-1;
        height: 30px;
        width: 30px;
        background: $background-white url(../imgs/template/icons/wishlist.svg) no-repeat center;
        padding: 0px;
        opacity: 0;
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        -webkit-transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        will-change: opacity,transform;
        transition-duration: 0.2s;
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
        &:hover
        {
            background: $background-white url(../imgs/template/icons/wishlist-hover.svg) no-repeat center;
        }
    }
    &.btn-compare
    {
        border: 1px solid $color-border-1;
        height: 30px;
        width: 30px;
        background: $background-white url(../imgs/template/icons/compare.svg) no-repeat center;
        padding: 0px;
        opacity: 0;
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        -webkit-transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        will-change: opacity,transform;
        -webkit-transition-delay: 0.14s;
        transition-delay: 0.14s;
        &:hover
        {
            background: $background-white url(../imgs/template/icons/compare-hover.svg) no-repeat center;
        }
    }
    &.btn-quickview
    {
        border: 1px solid $color-border-1;
        height: 30px;
        width: 30px;
        background: $background-white url(../imgs/template/icons/view.svg) no-repeat center;
        padding: 0px;
        opacity: 0;
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
        -webkit-transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        transition: all 0.2s cubic-bezier(.28,.12,.22,1);
        will-change: opacity,transform;
        -webkit-transition-delay: 0.18s;
        transition-delay: 0.18s;
        &:hover
        {
            background: $background-white url(../imgs/template/icons/view-hover.svg) no-repeat center;
        }
    }
    &.btn-gray-abs
    {
        position: absolute;
        bottom: -20px;
        right: -15px;
        background-color: $color-border-1;
        font-size: 14px;
        line-height: 18px;
        color: $color-brand-3;
        border-radius: 0px;
        border-top-left-radius: 4px;
        padding: 4px 10px;
        &:hover
        {
            color: $color-brand-2;
        }
    }
    &.btn-gray
    {
        background-color: $color-border-1;
        font-size: 12px;
        line-height: 18px;
        color: $color-brand-3;
        border-radius: 0px;
        border-radius: 4px;
        padding: 2px 10px;
        &:hover
        {
            color: $color-brand-2;
        }
    }
    &.btn-out-stock {
        background-color: $background-bg-2;
        font-size: 12px;
        line-height: 18px;
        color: $color-danger;
        border-radius: 0px;
        border-radius: 4px;
        padding: 2px 10px;
        &:hover
        {
            color: $color-brand-2;
        }
    }
    &.btn-open
    {
        height: 112px;
        width: 49px;
        background: $background-white url(../imgs/template/open.svg) no-repeat center 68px;
        padding: 10px;
        position: fixed;
        top: 0px;
        left: 0px;
    }
    &.btn-view-all
    {
        background: url(../imgs/template/icons/arrow-line.svg) no-repeat right 0px center;
        padding-right: 26px;
        color: $color-brand-3;
        font-size: $font-md;
        line-height: 24px;
        font-weight: bold;
        &:hover
        {
            color: $color-brand-2;
        }
    }
    &.btn-link-orange
    {
        text-decoration: underline;
        color: $color-brand-2;
    }
    &.btn-category
    {
        background-color: $color-brand-2;
        background-image: url(../imgs/page/homepage4/open-close.svg);
        background-repeat: no-repeat;
        background-position: 17px 9px;
        display: inline-block;
        padding: 10px 20px 10px 50px;
        color: $color-white;
        min-width: 223px;
        text-align: left;
    }
    &.btn-border {
        border-radius: 8px;
        border: 1px solid $color-gray-300;
        padding: 8px 16px;
        background: $background-white;
        font-style: normal;
        font-weight: 500;
        font-size: $font-xxs;
        line-height: 18px;
        color: $color-brand-3;
        margin-bottom: 10px;
        &:hover {
            background-color: $color-gray-300;
            color: $color-gray-1000;
        }
    }
    &.btn-filter {
        background-image: url(../imgs/page/shop/filter.svg);
        background-repeat: no-repeat;
        background-position: 15px 12px;
        background-color: #EBF0F3;
        padding: 8px 10px 8px 37px;
        border-radius: 4px;
    }
    &.btn-add-wishlist {
        background-image: url(../imgs/template/wishlist.svg);
        background-position: 0px -2px;
        background-repeat: no-repeat;
        padding:0px 0px 0px 35px;

    }
    &.btn-add-compare {
        background-image: url(../imgs/template/compare.svg);
        background-position: 0px -2px;
        background-repeat: no-repeat;
        padding:0px 0px 0px 35px;

    }
    &.btn-delete {
        background-image: url(../imgs/page/wishlist/delete.svg);
        background-position: center;
        background-repeat: no-repeat;
        padding:0px 0px 0px 0px;
        height: 34px;
        width: 34px;
    }
    &.arrow-back {
        background-image: url(../imgs/page/cart/back.svg);
        background-position: left 20px center;
        background-repeat: no-repeat;
        padding-left: 45px;
        &:hover {
            background-image: url(../imgs/page/cart/back-hover.svg);
        }
    }
    &.arrow-next {
        background-image: url(../imgs/page/checkout/arrow-next.svg);
        background-position: right 20px center;
        background-repeat: no-repeat;
        padding-right: 45px;
        &:hover {
            background-image: url(../imgs/page/cart/arrow-next-hover.svg);
        }
    }
    &.update-cart {
        background-image: url(../imgs/page/cart/reload.svg);
        background-position: left 20px center;
        background-repeat: no-repeat;
        padding-left: 45px;
        &:hover {
            background-image: url(../imgs/page/cart/reload-hover.svg);
        }
    }
    &.btn-amazon {
        padding: 5px 10px;
        border: 1px solid $color-gray-100;
        img {
            max-height: 42px;
        }
    }
    &.btn-gpay {
        padding: 5px 10px;
        border: 1px solid $color-gray-100;
        img {
            max-height: 42px;
        }
    }
    &.btn-paypal {
        padding: 5px 10px;
        border: 1px solid $color-gray-100;
        img {
            max-height: 42px;
        }
    }
    &.btn-tags {
        background-color: $color-white;
        border: 1px solid $color-gray-500;
        padding: 7px 20px;
        border-radius: 4px;
        font-size: $font-sm;
        line-height: 20px;
        color: $color-brand-3;
        font-weight: bold;
        &:hover {
            color: $color-white;
            background-color: $color-brand-3;
        }
    }
    &.btn-login {
        border: 1px solid $color-gray-100;
        border-radius: 4px;
        padding: 14px 22px;
        background-color: $background-white;
        width: 100%;
        img {
            vertical-align: middle;
            margin-left: 5px;
        }
    }
    &.btn-shop-now {
        background-image: url(../imgs/page/homepage5/shop-now.svg);
        background-position: left 14px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
    &.btn-close {
        padding: 0px;
        height: 30px;
        width: 30px;
        position: absolute;
        z-index: 12;
        right: 0px;
        top: -7px;
        background: url(../imgs/page/homepage9/close.svg) no-repeat center;
    }
    &.btn-success {
        background-color: $color-success;
        border-color: $color-success;
        &:hover {
            background-color: $color-brand-3;
            border-color: $color-brand-3;
        }
    }
    &.btn-link-brand-3 {
        color: $color-brand-3;
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
    }
}
.dropdown-language
{
    display: inline-block;
    .dropdown-toggle::after
    {
        display: none;
    }
    .btn
    {
        padding: 0px;
    }
}
.btn-tooltip
{
    position: relative;
    &:after {
        bottom: 0;
        right: 34px;
        position: absolute;
        white-space: nowrap;
        border-radius: 5px;
        font-size: 11px;
        padding: 7px 10px;
        color: $color-brand-3;
        background-color: $color-border-1;
        content: attr(aria-label);
        line-height: 1.3;
        box-shadow: 0;
        transition: 0;
        opacity: 0;
        visibility: hidden;
        transform: 0;
        transition-duration: 0.2s;
    }
    &:before {
        content: "";
        position: absolute;
        left: -8px;
        bottom: 0;
        transition-delay: 0.1s;
        border: 7px solid transparent;
        border-left-color: transparent;
        border-left-color: $color-border-1;
        z-index: 9;
        margin-bottom: 0;
        transition: 0;
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.2s;
    }
    &:hover {
        &:after {
            opacity: 1;
            visibility: visible;
            transform: 0;
            transition-duration: 0.2s;
        }
        &:before {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(-8px);
            -ms-transform: translateY(-8px);
            transform: translateY(-8px);
            transition-duration: 0.2s;
        }
    }
}
.tag-dot {
    border-radius: 4px;
    padding: 5px 5px 5px 20px;
    display: inline-block;
    background: $color-border-1 url(../imgs/template/icons/circle.svg) no-repeat 10px 11px;
    color: $color-brand-3;
}