.dropdown .sidebar-left .menu-texts {
    border: 0px;
}
.lbl-newarrival {
    border-radius: 4px;
    background-color: $color-success;
    display: inline-block;
    padding: 5px 10px;
}
.banner-slide {
    position: relative;
    background-size: cover !important;
    min-height: 550px;
}
.banner-desc {
    position: absolute;
    max-width: 767px;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    margin: auto;
    text-align: center;
}
.list-no-border {
    position: relative;
    li {
        .item-list {
            border: 0px;
            &:hover {
                border: 0px;
            }
        }
    }
}
.border-none {
    border: 0px !important;
}
.ads1 {
    max-width: 914px;
    width: 100%;
}
.list-9-col li .box-category {
    padding: 5px 3px 10px 3px;
}
.list-style-brand-2 {
    .btn-cart {
        &:hover {
            background-color: $color-brand-2;
            border-color: $color-brand-2;
        }
    }
    .card-grid-style-3:hover .card-grid-inner {
        border-color: $color-brand-2;
    }
    .card-grid-style-3:hover {
        a.color-gray-500, .rating span {
            color: $color-brand-2;
        }
    }
}
.card-hide-cart {
    .box-btn-cart {
        display: none;
    }
}
.box-newsletter {
    &.bg-brand-2 {
        background-image: url(../imgs/page/homepage5/bg-newsletter.png);
        background-position: left center;
        background-repeat: no-repeat;
        .color-warning {
            color: $color-brand-3;
        }
        .box-form-newsletter .btn-brand-3 {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 50px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
}
.bg-footer-homepage5 {
    .footer-1 {
        background-color: $background-bg-26;
        margin-bottom: 0px;
    }
    .footer-2 {
        padding-top: 50px;
        background-color: $color-gray-50;
    }
}
.sidebar-border {
    .head {
        border-bottom: 0px;
        margin-bottom: 0px;
    }
    .content-slider {
        border: 1px solid $color-brand-2;
        border-top: 0px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-top: 5px;
    }
    .card-grid-style-2 {
        .info-right {
            .font-xs-bold {
                font-size: 14px;
                font-weight: 500;
            }
            .price-info {
                margin-top: 5px;
            }
        }
    }
}
.banner-home5 {
    .swiper-pagination-custom {
        padding-left: 0px;
        text-align: center;
    }
}