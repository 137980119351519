// New Agon
.banner-hero {
    padding: 24px 0px 20px 0px;
    position: relative;
    .box-swiper
    {
        height: 100%;
    }
    &.banner-1
    {
        background-color: $background-white;
        .banner-big
        {
            height: 500px;
            border-radius: 8px;
            padding: 60px 60px 25px 60px;
            background-repeat:  no-repeat;
            background-position:  right bottom;
            background-size: contain !important;
            &.banner-big-2
            {
                padding: 110px 60px 25px 60px;
                background-repeat:  no-repeat;
                background-position:  right bottom;
                background-size: 320px;
            }
            &.banner-big-3
            {
                padding: 50px 40px 25px 40px;
                background-image: url(../imgs/page/homepage4/bg-banner.png);
                background-repeat:  no-repeat;
                background-position:  right top;
                background-size: cover !important;
            }
        }
        .banner-small
        {
            height: 238px;
            border-radius: 8px;
            padding: 20px 20px 20px 20px;
            margin-bottom: 24px;
            position: relative;
            &.banner-small-1
            {
                background-image: url(../imgs/page/homepage1/banner-small-1.png);
                background-repeat:  no-repeat;
                background-position:  right bottom;
                background-size: 60% auto;
            }
            &.banner-small-2
            {
                background-image: url(../imgs/page/homepage1/banner-small-2.png);
                background-repeat:  no-repeat;
                background-position:  right bottom;
                background-size: 70% auto;
            }
            &.banner-laptop
            {
                background-image: url(../imgs/page/homepage2/laptop.png);
                background-repeat:  no-repeat;
                background-position:  right bottom;
                background-size: auto;
                &::before
                {
                    position: absolute;
                    content: "";
                    top: 0px;
                    left: 0px;
                    height: 125px;
                    width: 100%;
                    background-image: url(../imgs/page/homepage2/circle-top.png);
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: contain;
                    z-index: 1;
                    border-top-left-radius: 8px;
                }
                *
                {
                    z-index: 2;
                    position: relative;
                }
            }
            &.banner-player
            {
                background-image: url(../imgs/page/homepage2/player.png);
                background-repeat:  no-repeat;
                background-position:  right bottom;
                background-size: 100%;
            }
            &.banner-apple
            {
                background-image: url(../imgs/page/homepage2/apple-day.png);
                background-repeat:  no-repeat;
                background-position:  right bottom;
                background-size: 100%;
            }
            &.banner-samsung
            {
                background-image: url(../imgs/page/homepage2/samsung.png);
                background-repeat:  no-repeat;
                background-position:  right bottom;
                background-size: 100%;
            }
        }
    }
    &.banner-8 {
        .banner-big {
            height: 518px;
            border-radius: 8px;
            padding: 10px 20px 10px 50px;
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: contain !important;
            background-image: url(../imgs/page/homepage8/bg-banner.png);
        }
    }
}
@keyframes hero-thumb-animation {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes hero-thumb-animation-2 {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-20px);
    }
}
@keyframes hero-thumb-sm-animation {
    0% {
        -webkit-transform: translateY(-20px) translateX(50px);
        -moz-transform: translateY(-20px) translateX(50px);
        -ms-transform: translateY(-20px) translateX(50px);
        transform: translateY(-20px) translateX(50px);
    }
    100% {
        -webkit-transform: translateY(-20px) translateX(0px);
        -moz-transform: translateY(-20px) translateX(0px);
        -ms-transform: translateY(-20px) translateX(0px);
        transform: translateY(-20px) translateX(0px);
    }
}
@keyframes hero-thumb-sm-2-animation {
    0% {
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
    }
    100% {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
    }
}
.shape-1 {
    -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
    -moz-animation: hero-thumb-animation 2s linear infinite alternate;
    -o-animation: hero-thumb-animation 2s linear infinite alternate;
    animation: hero-thumb-animation 2s linear infinite alternate;
}
.shape-1-2 {
    -webkit-animation: hero-thumb-animation-2 2s linear infinite alternate;
    -moz-animation: hero-thumb-animation-2 2s linear infinite alternate;
    -o-animation: hero-thumb-animation-2 2s linear infinite alternate;
    animation: hero-thumb-animation-2 2s linear infinite alternate;
}
.shape-2 {
    -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-animation 4s linear infinite alternate;
}
.shape-3 {
    -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}
// End New Agon
.banner-right
{
    border-radius: 4px;
    display: inline-block;
    width: 100%;
    height: 670px;
    background: url(../imgs/page/homepage1/bg-topsell.png) no-repeat 0px 0px;
    background-size: cover;
    padding: 50px 10px;
    .text-no
    {
        background-color: $color-brand-1;
        border-radius: 13px;
        color: $color-white;
        font-size: $font-md;
        line-height: 23px;
        display: inline-block;
        padding: 0px 16px;
    }
    .text-main
    {
        font-size: 32px;
        line-height: 31px;
        color: $color-gray-1000;
        font-weight: bold;
    }
    .text-desc
    {
        font-size: 22px;
        line-height: 32px;
        color: $color-brand-3;
    }
}
.banner-homepage3
{
    background-color: $background-bg-9;
    background-image: url(../imgs/page/homepage3/bg-circle.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    padding-bottom: 0px;
    .container-banner
    {
        max-width: 1400px;
        margin: auto;
    }
    .box-swiper .swiper-container
    {
        padding-bottom: 0px;
    }
    .swiper-pagination-custom
    {
        bottom: 40px;
        padding-left: 0px;
    }
    .swiper-pagination .swiper-pagination-customs
    {
        background-color: $background-white;
    }
    .swiper-pagination .swiper-pagination-customs-active, .swiper-pagination .swiper-pagination-customs:hover
    {
        background-color: $color-brand-2;
    }
}