.box-payment {
    display: flex;
    margin: 0px -10px;
    .btn {
        width: 33.3%;
        margin: 0px 5px;
        align-items: center;
        display: flex;
    }
}
.box-border {
    border: 1px solid $color-gray-100;
    padding: 20px;
    border-radius: 4px;
}
.border-bottom-4 {
    border-bottom: 4px solid $color-gray-100;
    position: relative;
}
.text-or {
    display: inline-block;
    bottom: -18px;
    position: relative;
    background: $background-white;
    padding: 5px;
}
.arrow-back-1 {
    background: url(../imgs/page/checkout/arrow-left.svg) no-repeat left top 12px;
    padding: 7px 0px 9px 25px;
    display: inline-block;
    color: $color-brand-1 !important;
    &:hover {
        color: $color-brand-3 !important;
    }
}
.checkboxOffer {
    float: left;
    margin: 3px 10px 0px 0px;
}
.listCheckout {
    .item-wishlist {
        border-radius: 4px;
        border: 1px solid $color-gray-100;
        padding: 15px;
        margin-bottom: 10px;
        .wishlist-product {
            width: 76%;
        }
    }
}