@-webkit-keyframes load7{
	0%,
	80%,
	100%{box-shadow:0 2.5em 0 -1.3em;}
	40%{box-shadow:0 2.5em 0 0;}
}
@keyframes load7{
	0%,
	80%,
	100%{box-shadow:0 2.5em 0 -1.3em;}
	40%{box-shadow:0 2.5em 0 0;}
}

.loader {
	border-radius: 50%;
	width: 2em;
	height: 2em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
	color: $color-brand-3;
	font-size: 10px;
	margin: 80px auto;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	&:before {
		border-radius: 50%;
		width: 2em;
		height: 2em;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: load7 1.8s infinite ease-in-out;
		animation: load7 1.8s infinite ease-in-out;
		content: '';
		position: absolute;
		top: 0;
		left: -3.5em;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}
	&:after {
		border-radius: 50%;
		width: 2em;
		height: 2em;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: load7 1.8s infinite ease-in-out;
		animation: load7 1.8s infinite ease-in-out;
		content: '';
		position: absolute;
		top: 0;
		left: 3.5em;
	}
}
.swiper-pagination
{
	text-align: left;
	.swiper-pagination-customs
	{
		background: $background-bg-12;
		border-radius: 50%;
		&:hover
		{
			background: $color-brand-2;
		}
	}
	.swiper-pagination-customs-active
	{
		background: $color-brand-2;
	}
	&.swiper-pagination-2
	{
		top: auto;
		height: auto;
		z-index: 123;
		line-height: 30px;
		text-align: center;
	}
}
.box-newsletter
{
	padding: 90px 0px;
	background: $color-gray-1000 url(../imgs/template/bg-newsletter.svg) no-repeat right 10px bottom 10px;
	.box-form-newsletter
	{
		position: relative;
		.input-newsletter
		{
			width: 100%;
			background-color: $background-white;
			border-radius: 4px;
			border: 0px;
			height: 50px;
			padding: 5px 20px;
		}
		.btn-brand-2
		{
			position: absolute;
			top: 0px;
			right: 0px;
			height: 50px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}
/*page loading*/
.preloader {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    margin: 0 auto;
    img {
        max-width: 250px;
    }
}


@keyframes rotate {
	0%,49.999%,100% {
		transform: none;
	}
	50%,99.999% {
		transform: rotate(90deg);
	}
}
@keyframes shift-left {
	0%,100% {
		transform: translateX(0%);
	}
	50% {
		transform: scale(0.65) translateX(-75%);
	}
}
@keyframes shift-right {
	0%,100% {
		transform: translateX(0%);
	}
	50% {
		transform: scale(0.65) translateX(75%);
	}
}
.preloader-dots {
	--uib-size: 40px;
	--uib-speed: .9s;
	--uib-color: #425A8B;
	position: relative;
	height: calc(var(--uib-size) / 2);
	width: var(--uib-size);
	filter: url('#uib-jelly-ooze');
	animation: rotate calc(var(--uib-speed) * 2) linear infinite;
	&::before {
		content: '';
		position: absolute;
		top: 0%;
		left: 25%;
		width: 50%;
		height: 100%;
		background: var(--uib-color);
		border-radius: 100%;
		animation: shift-left var(--uib-speed) ease infinite;
	}
	&::after {
		content: '';
		position: absolute;
		top: 0%;
		left: 25%;
		width: 50%;
		height: 100%;
		background: var(--uib-color);
		border-radius: 100%;
		animation: shift-right var(--uib-speed) ease infinite;
	}
}

/*select 2 style*/
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: $color-brand-3;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: $color-brand-3 transparent transparent transparent;
	border-width: 5px 5px 0 5px;
}
.select2-search--dropdown {
	padding: 0px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid $color-gray-200;
	margin-bottom: 15px;
	border-radius: 4px;
	padding: 8px;
}
.select2-container--default .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
	scrollbar-width: thin;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $color-border-1;
	color: $color-brand-3;
}

.modal-header .btn-close {
	margin: -.5rem 0rem -.5rem auto;
}