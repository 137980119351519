.header-brand-2 {
    border-top: 0px;
    .box-header-bottom {
        display: flex;
        width: 100%;
        background-color: $color-brand-2;
        border-radius: 4px;
        border-bottom: 2px solid $color-brand-4;
        padding: 10px 20px 10px 0px;
        .main-menu li a {
            color: $color-white !important;
            &:hover {
                color: $color-blue !important;
            }
        }
        .main-menu li ul li a {
            color: $color-gray-900 !important;
        }
        .header-nav .main-menu li.has-children > a::after {
            background: url(../imgs/page/homepage7/arrow-down.svg) no-repeat 0px 0px;
        }
        .btn-category {
            position: relative;
            &:before {
                content: "";
                height: 20px;
                width: 1px;
                background-color: $color-white;
                position: absolute;
                top: 11px;
                right: -2px;
                opacity: 0.5;
            }
        }
    }
}
.border-none {
    border: 0px !important;
}
.banner-right-7 {
    max-height: 500px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: inline-block;
}
.banner-big-7 {
    max-height: 500px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    img {
        width: 100%;
    }
}
.swiper-home7 {
    .swiper-pagination {
        text-align: left;
        padding-left: 0px;
        padding-right: 30px;
        left: 30px;
        bottom: 20px;
    }
}
.swiper-pagination-3 {
    &.swiper-pagination-custom {
        bottom: 0px;
        padding-left: 0px;
    }

}
.swiper-group-9 {
    .swiper-pagination-3.swiper-pagination .swiper-pagination-customs {
        background-color: $color-brand-3;
        &.swiper-pagination-customs-active {
            background-color: $color-brand-2;
        }
    }
}


.list-brand-2 {
    .box-count .deals-countdown {
        background-color: $color-brand-2;
    }
    .card-grid-style-3 .card-grid-inner .info-right a.btn-cart:hover {
        background-color: $color-brand-2;
        border-color: $color-brand-2;
    }
}
.box-menu-category {
    border-radius: 4px;
    background-color: $color-gray-50;
    padding: 21px 30px 19px 30px;
    .list-nav-arrow {
        li {
            margin-bottom: 13px;
        }
    }
}
.block-sale-1 {
    background-color: $background-bg-9;
    border-radius: 4px;
    padding: 25px 15px 25px 25px;
    h3 {
        font-size: 28px;
        line-height: 30px;
        margin-top: 10px;
        color: $color-gray-1000;
    }
    .col-lg-4 {
        line-height: 130px;
        img {
            max-height: 130px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
.box-ads-1 {
    margin: 0px -10px;
    display: flex;
    flex-wrap: wrap;
    .ads-1 {
        width: 40%;
        padding: 0px 10px;
    }
    .ads-2 {
        width: 40%;
        padding: 0px 10px;
    }
    .ads-3 {
        width: 20%;
        padding: 0px 10px;
    }
}
.box-ads-2 {
    max-width: 1100px;
    position: relative;
    width: 100%;
    padding: 0px 0px;
    text-align: center;
    margin: auto;
    * {
        position: relative;
        z-index: 3;
    }
    img {
        z-index: 1;
        display: block;
    }
    .ads-info {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
        z-index: 4;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: url(../imgs/page/homepage7/bg-trans.png);
    }
}
.home7-style {
    .card-grid-inner {
        .image-box {
            background-color: $color-white;
        }
    }
}
.mw-newsletter {
    max-width: 500px;
    width: 100%;
}
.bg-newsletter-home7 {
    position: relative;
    background-image: none;
    * {
        position: relative;
        z-index: 2;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
        background: url(../imgs/page/homepage7/newsletter-left.png) no-repeat bottom left;
        background-size: auto;
        z-index: 1;
    }
    &::after {
        content: "";
        position: absolute;
        right: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
        background: url(../imgs/page/homepage7/newsletter-right.png) no-repeat bottom right;
        background-size: auto;
        z-index: 1;
    }
}